define("@ember/string/helpers/loc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.loc = loc;
  function loc(params /*, hash*/) {
    return Ember.String.loc(...params);
  }
  var _default = Ember.Helper.helper(loc);
  _exports.default = _default;
});