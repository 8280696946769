define("ember-i18n/utils/add-translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = addTranslations;


  const assign = Ember.assign || Ember.merge;

  function addTranslations(locale, newTranslations, owner) {
    const key = `locale:${locale}/translations`;
    let factory = owner.factoryFor(key);
    let existingTranslations = factory && factory.class;

    if (existingTranslations == null) {
      existingTranslations = {};
      owner.register(key, existingTranslations);
    }

    assign(existingTranslations, newTranslations);
  }
});