define('ember-i18n/config/ar', ['exports', 'ember-i18n/config/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    rtl: true,

    pluralForm(n) {
      const mod100 = n % 100;

      if (n === 0) {
        return _constants.ZERO;
      }
      if (n === 1) {
        return _constants.ONE;
      }
      if (n === 2) {
        return _constants.TWO;
      }
      if (mod100 >= 3 && mod100 <= 10) {
        return _constants.FEW;
      }
      if (mod100 >= 11 && mod100 <= 99) {
        return _constants.MANY;
      }
      return _constants.OTHER;
    }
  };
});