define("ember-truth-helpers/helpers/is-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isArray = isArray;
  function isArray(params) {
    for (let i = 0, len = params.length; i < len; i++) {
      if (Ember.isArray(params[i]) === false) {
        return false;
      }
    }
    return true;
  }
  var _default = Ember.Helper.helper(isArray);
  _exports.default = _default;
});