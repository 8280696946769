define("ember-i18n/legacy/initializer", ["exports", "ember-i18n/legacy/stream", "ember-i18n/legacy/helper"], function (exports, _stream, _helper) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'ember-i18n-legacy-helper',

    initialize(registry) {
      const i18n = registry.lookup('service:i18n');

      i18n.localeStream = new _stream.default(function () {
        return i18n.get('locale');
      });

      Ember.addObserver(i18n, 'locale', i18n, function () {
        this.localeStream.value(); // force the stream to be dirty
        this.localeStream.notify();
      });

      Ember.HTMLBars._registerHelper('t', _helper.default);
    }
  };
});