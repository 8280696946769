define("ember-truth-helpers/helpers/is-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function (_ref) {
    let [obj] = _ref;
    return Ember.isEmpty(obj);
  });
  _exports.default = _default;
});