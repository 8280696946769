define('ember-file-icons/components/file-icon', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const VIEWABLE_IMAGE_TYPES = ['jpg', 'gif', 'png', 'jpeg'];
  const ACCEPTABLE_IMAGE_SIZES = ['small', 'medium', 'large', 'responsive'];

  exports.default = Ember.Component.extend({
    attributeBindings: ['data-file', 'style'],
    classNameBindings: ['getClassNames'],
    url: null,
    size: null,
    iconOnly: false,
    extension: null,
    badExtension: 'NA',
    maxExtensionSize: 4,
    tagName: 'div',
    init(...args) {
      this._super(...args);
      if (Ember.get(this, 'extension')) {
        Ember.set(this, 'iconOnly', true);
      } else {
        Ember.set(this, 'extension', this.getExtensionFromURL(Ember.get(this, 'url'), Ember.get(this, 'badExtension')));
      }
    },
    'data-file': Ember.computed('url', function () {
      const extension = Ember.get(this, 'extension');
      if (!Ember.get(this, 'iconOnly') && VIEWABLE_IMAGE_TYPES.includes(extension)) {
        return null;
      }
      return extension;
    }),
    style: Ember.computed('dataFile', function () {
      if (!Ember.get(this, 'data-file')) {
        return Ember.String.htmlSafe(`background-image:url('${Ember.get(this, 'url')}')`);
      }
      return null;
    }),
    getClassNames: Ember.computed('size', function () {
      if (Ember.get(this, 'data-file')) {
        return `file-icon file-icon--${this.getSize(Ember.get(this, 'size'))}`;
      }
      return `image-icon image-icon--${this.getSize(Ember.get(this, 'size'))}`;
    }),
    getSize(size) {
      if (ACCEPTABLE_IMAGE_SIZES.includes(size)) {
        return size;
      }
      return ACCEPTABLE_IMAGE_SIZES[0];
    },
    getExtensionFromURL(url, extOnError = null) {
      let extension = extOnError;
      try {
        const parcedExtension = url.split(/#|\?/)[0].split('.').pop().trim().replace(/\//g, '');
        if (parcedExtension.length > 0 && parcedExtension.length <= Ember.get(this, 'maxExtensionSize')) {
          extension = parcedExtension;
        }
      } catch (error) {
        // Do Nothing
      }
      return extension;
    }
  });
});