define("ember-intl/-private/formatters/format-message", ["exports", "fast-memoize", "@ember-intl/intl-messageformat", "ember-intl/-private/formatters/-base"], function (_exports, _fastMemoize, _intlMessageformat, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    keys
  } = Object;
  const {
    Handlebars: {
      Utils: {
        escapeExpression
      }
    }
  } = Ember;
  function escape(hash) {
    if (!hash) {
      return;
    }
    return keys(hash).reduce((accum, key) => {
      if (typeof hash[key] === 'string') {
        accum[key] = escapeExpression(hash[key]);
      }
      return accum;
    }, Ember.assign({}, hash));
  }

  /**
   * @private
   * @hide
   */
  class FormatMessage extends _base.default {
    constructor() {
      super();
      this.createNativeFormatter = (0, _fastMemoize.default)((message, locales, formats) => {
        return new _intlMessageformat.default(message, locales, formats);
      });
    }
    format(message, options, _ref) {
      let {
        formats,
        locale
      } = _ref;
      const isHTMLSafe = options && options.htmlSafe;
      const formatter = this.createNativeFormatter(message, locale, formats);
      const escapedOptions = isHTMLSafe ? escape(options) : options;
      const result = formatter.format(escapedOptions);
      return isHTMLSafe ? Ember.String.htmlSafe(result) : result;
    }
  }
  _exports.default = FormatMessage;
});