define('ember-i18n/config/ru', ['exports', 'ember-i18n/config/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    rtl: false,

    pluralForm(n) {
      const mod1 = n % 1;
      const mod10 = n % 10;
      const mod100 = n % 100;

      if (mod10 === 1 && mod100 !== 11) {
        return _constants.ONE;
      }
      if (mod1 === 0 && mod10 >= 2 && mod10 <= 4 && !(mod100 >= 12 && mod100 <= 14)) {
        return _constants.FEW;
      }
      if (mod1 === 0 && (mod10 === 0 || mod10 >= 5 && mod10 <= 9 || mod100 >= 11 && mod100 <= 14)) {
        return _constants.MANY;
      }
      return _constants.OTHER;
    }
  };
});