define('ember-text-highlight/helpers/text-highlight', ['exports', 'lodash/lang', 'lodash/array', 'lodash/object', 'ember-text-highlight/-private/env-detection', 'ember-text-highlight/-private/indices-implementation', 'ember-text-highlight/-private/regex-implementation'], function (exports, _lang2, _array2, _object2, _envDetection, _indicesImplementation, _regexImplementation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.MAX_VALUE_LENGTH_FOR_INDICES_IMPL = undefined;


  // RegEx Implementation exceeds Indices Implementation on large texts independent of environment
  const MAX_VALUE_LENGTH_FOR_INDICES_IMPL = exports.MAX_VALUE_LENGTH_FOR_INDICES_IMPL = 250;

  const DEFAULT_OPTIONS = {
    caseSensitive: false
  };

  /**
   * Text Highlight Helper
   *
   * Mark all occurences of a string in input string with <span class="mark">
   *
   * Usage:
   * {{{text-highlight value query=myQuery}}}
   *
   * Expected Input:
   * `params` = ['valueString']
   * `options` = {query: 'queryString'}
   *
   * Picks the best implementation concerning input and environment.
   */
  exports.default = Ember.Helper.helper(function (params = [], options = DEFAULT_OPTIONS) {
    let value, query;

    // validate and transform input
    const queryIsValid = _lang2.default.isString(query = options.query) && !_lang2.default.isEmpty(query.trim());
    const valueIsValid = _lang2.default.isString(value = findValueAndTransformToStringIfApplicable(params)) && !_lang2.default.isEmpty(value.trim());

    if (!queryIsValid) {
      return Ember.String.htmlSafe(value);
    }

    if (!valueIsValid) {
      return '';
    }

    options = _object2.default.merge(DEFAULT_OPTIONS, _lang2.default.clone(options));

    // as of a certain value length, regular expressions will likely start to exceed the performance of our indices
    // implementation
    if (value.length > MAX_VALUE_LENGTH_FOR_INDICES_IMPL) {
      return (0, _regexImplementation.default)(value, query, options);
    }

    // pick the best implementation for this environment (indices, except Safari)
    if (!window._text_highlight_fastest_impl) {
      if ((0, _envDetection.isSafari)()) {
        window._text_highlight_fastest_impl = _regexImplementation.default;
      } else {
        window._text_highlight_fastest_impl = _indicesImplementation.default;
      }
    }

    return window._text_highlight_fastest_impl(value, query, options);
  });


  function findValueAndTransformToStringIfApplicable(params) {
    const value = _array2.default.nth(params, 0);
    return _lang2.default.isNumber(value) ? value.toString() : value;
  }
});