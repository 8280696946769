define("ember-truth-helpers/helpers/lte", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.lte = lte;
  function lte(_ref, hash) {
    let [left, right] = _ref;
    if (hash.forceNumber) {
      if (typeof left !== 'number') {
        left = Number(left);
      }
      if (typeof right !== 'number') {
        right = Number(right);
      }
    }
    return left <= right;
  }
  var _default = Ember.Helper.helper(lte);
  _exports.default = _default;
});