define('@ember-data/store/-private', ['exports', 'require', '@ember/string', '@ember-data/store/-debug', 'ember-inflector'], function (exports, require$1, string, Debug, emberInflector) { 'use strict';

  require$1 = require$1 && Object.prototype.hasOwnProperty.call(require$1, 'default') ? require$1['default'] : require$1;

  /**
    @module @ember-data/store
  */

  // Used by the store to normalize IDs entering the store.  Despite the fact
  // that developers may provide IDs as numbers (e.g., `store.findRecord('person', 1)`),
  // it is important that internally we use strings, since IDs may be serialized
  // and lose type information.  For example, Ember's router may put a record's
  // ID into the URL, and if we later try to deserialize that URL and find the
  // corresponding record, we will not know if it is a string or a number.

  function coerceId(id) {
    if (id === null || id === undefined || id === '') {
      return null;
    }
    if (typeof id === 'string') {
      return id;
    }
    if (typeof id === 'symbol') {
      return id.toString();
    }
    return '' + id;
  }
  function ensureStringId(id) {
    var normalized = null;
    if (typeof id === 'string') {
      normalized = id.length > 0 ? id : null;
    } else if (typeof id === 'number' && !isNaN(id)) {
      normalized = '' + id;
    }
    if (normalized === null) {
      throw new Error(`Expected id to be a string or number, received ${String(id)}`);
    }
    return normalized;
  }

  /**
    @module @ember-data/store
  */

  // All modelNames are dasherized internally. Changing this function may
  // require changes to other normalization hooks (such as typeForRoot).

  /**
   This method normalizes a modelName into the format Ember Data uses
   internally.

    @function normalizeModelName
    @for @ember-data/store
    @param {String} modelName
    @return {String} normalizedModelName
  */
  function normalizeModelName(modelName) {
    return string.dasherize(modelName);
  }

  /**
    @module @ember-data/store
  */

  /**
   * This symbol provides a Symbol replacement for browsers that do not have it
   * (eg. IE 11).
   *
   * The replacement is different from the native Symbol in some ways. It is a
   * function that produces an output:
   * - iterable;
   * - that is a string, not a symbol.
   *
   * @internal
   */
  var symbol = typeof Symbol !== 'undefined' ? Symbol : key => `__${key}${Math.floor(Math.random() * Date.now())}__`;
  function addSymbol(obj, symbol, value) {
    if (typeof symbol === 'string') {
      Object.defineProperty(obj, symbol, {
        value,
        configurable: false,
        enumerable: false,
        writable: false
      });
    } else {
      // Typescript doesn't allow Symbol as an index type
      obj[symbol] = value;
    }
  }

  /**
    @module @ember-data/store
  */

  // provided for additional debuggability
  var DEBUG_CLIENT_ORIGINATED = symbol('record-originated-on-client');
  var DEBUG_IDENTIFIER_BUCKET = symbol('identifier-bucket');

  /**
   * An Identifier specific to a record which may or may not
   * be present in the cache.
   *
   * The absence of an `id` DOES NOT indicate that this
   * Identifier is for a new client-created record as it
   * may also indicate that it was generated for a secondary
   * index and the primary `id` index is not yet known.
   */

  /**
   * Used when an Identifier is known to be the stable version
   *
   * @internal
   */

  /**
   * Used when a StableRecordIdentifier was not created locally as part
   * of a call to store.createRecord
   *
   * Distinguishing between this Identifier and one for a client created
   * record that was created with an ID is generally speaking not possible
   * at runtime, so anything with an ID typically narrows to this.
   *
   * @internal
   */

  /**
   * Used when a StableRecordIdentifier was created locally
   * (by a call to store.createRecord).
   *
   * It is possible in rare circumstances to have a StableRecordIdentifier
   * that is not for a new record but does not have an ID. This would
   * happen if a user intentionally created one for use with a secondary-index
   * prior to the record having been fully loaded.
   *
   * @internal
   */

  /**
    A method which can expect to receive various data as its first argument
    and the name of a bucket as its second argument. Currently the second
    argument will always be `record` data should conform to a `json-api`
    `Resource` interface, but will be the normalized json data for a single
    resource that has been given to the store.

    The method must return a unique (to at-least the given bucket) string identifier
    for the given data as a string to be used as the `lid` of an `Identifier` token.

    This method will only be called by either `getOrCreateRecordIdentifier` or
    `createIdentifierForNewRecord` when an identifier for the supplied data
    is not already known via `lid` or `type + id` combo and one needs to be
    generated or retrieved from a proprietary cache.

    `data` will be the same data argument provided to `getOrCreateRecordIdentifier`
    and in the `createIdentifierForNewRecord` case will be an object with
    only `type` as a key.
  */

  /*
   A method which can expect to receive an existing `Identifier` alongside
   some new data to consider as a second argument. This is an opportunity
   for secondary lookup tables and caches associated with the identifier
   to be amended.

   This method is called everytime `updateRecordIdentifier` is called and
    with the same arguments. It provides the opportunity to update secondary
    lookup tables for existing identifiers.

   It will always be called after an identifier created with `createIdentifierForNewRecord`
    has been committed, or after an update to the `record` a `RecordIdentifier`
    is assigned to has been committed. Committed here meaning that the server
    has acknowledged the update (for instance after a call to `.save()`)

   If `id` has not previously existed, it will be assigned to the `Identifier`
    prior to this `UpdateMethod` being called; however, calls to the parent method
    `updateRecordIdentifier` that attempt to change the `id` or calling update
    without providing an `id` when one is missing will throw an error.
  */

  /*
  A method which can expect to receive an existing `Identifier` that should be eliminated
   from any secondary lookup tables or caches that the user has populated for it.
  */

  /*
   A method which can expect to be called when the parent application is destroyed.

   If you have properly used a WeakMap to encapsulate the state of your customization
   to the application instance, you may not need to implement the `resetMethod`.
  */

  function isNonEmptyString(str) {
    return typeof str === 'string' && str.length > 0;
  }

  /**
    @module @ember-data/store
  */

  var IDENTIFIERS = new WeakMap();
  function isStableIdentifier(identifier) {
    return IDENTIFIERS.has(identifier);
  }
  function markStableIdentifier(identifier) {
    IDENTIFIERS.set(identifier, 'is-identifier');
  }
  function unmarkStableIdentifier(identifier) {
    IDENTIFIERS.delete(identifier);
  }

  /**
    @module @ember-data/store
  */

  // support IE11

  var CRYPTO = (() => {
    var hasWindow = typeof window !== 'undefined';
    var isFastBoot = typeof FastBoot !== 'undefined';
    if (isFastBoot) {
      return {
        getRandomValues(buffer) {
          try {
            return FastBoot.require('crypto').randomFillSync(buffer);
          } catch (err) {
            throw new Error('Using createRecord in Fastboot requires you to add the "crypto" package to "fastbootDependencies" in your package.json');
          }
        }
      };
    } else if (hasWindow && typeof window.crypto !== 'undefined') {
      return window.crypto;
    } else if (hasWindow && typeof window.msCrypto !== 'undefined' && typeof window.msCrypto.getRandomValues === 'function') {
      return window.msCrypto;
    } else {
      throw new Error('ember-data: Cannot find a valid way to generate local identifiers');
    }
  })();

  // we might be able to optimize this by requesting more bytes than we need at a time
  function rng() {
    // WHATWG crypto RNG - http://wiki.whatwg.org/wiki/Crypto
    var rnds8 = new Uint8Array(16);
    return CRYPTO.getRandomValues(rnds8);
  }

  /**
   * Convert array of 16 byte values to UUID string format of the form:
   * XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  var byteToHex = [];
  for (var i = 0; i < 256; ++i) {
    byteToHex[i] = (i + 0x100).toString(16).substr(1);
  }
  function bytesToUuid(buf) {
    var bth = byteToHex;
    // join used to fix memory issue caused by concatenation: https://bugs.chromium.org/p/v8/issues/detail?id=3175#c4
    return [bth[buf[0]], bth[buf[1]], bth[buf[2]], bth[buf[3]], '-', bth[buf[4]], bth[buf[5]], '-', bth[buf[6]], bth[buf[7]], '-', bth[buf[8]], bth[buf[9]], '-', bth[buf[10]], bth[buf[11]], bth[buf[12]], bth[buf[13]], bth[buf[14]], bth[buf[15]]].join('');
  }
  function uuidv4() {
    var rnds = rng();

    // Per 4.4, set bits for version and `clock_seq_hi_and_reserved`
    rnds[6] = rnds[6] & 0x0f | 0x40;
    rnds[8] = rnds[8] & 0x3f | 0x80;
    return bytesToUuid(rnds);
  }

  function freeze(obj) {
    if (typeof Object.freeze === 'function') {
      return Object.freeze(obj);
    }
    return obj;
  }

  /**
    @module @ember-data/store
  */

  var configuredForgetMethod;
  var configuredGenerationMethod;
  var configuredResetMethod;
  var configuredUpdateMethod;
  function setIdentifierGenerationMethod(method) {
    configuredGenerationMethod = method;
  }
  function setIdentifierUpdateMethod(method) {
    configuredUpdateMethod = method;
  }
  function setIdentifierForgetMethod(method) {
    configuredForgetMethod = method;
  }
  function setIdentifierResetMethod(method) {
    configuredResetMethod = method;
  }
  function defaultGenerationMethod(data, bucket) {
    if (isNonEmptyString(data.lid)) {
      return data.lid;
    }
    var {
      type,
      id
    } = data;
    if (isNonEmptyString(id)) {
      return `@ember-data:lid-${normalizeModelName(type)}-${id}`;
    }
    return uuidv4();
  }
  var IdentifierCaches = new WeakMap();
  function identifierCacheFor(store) {
    var cache = IdentifierCaches.get(store);
    if (cache === undefined) {
      cache = new IdentifierCache();
      IdentifierCaches.set(store, cache);
    }
    return cache;
  }
  function defaultEmptyCallback() {}
  var DEBUG_MAP;
  {
    DEBUG_MAP = new WeakMap();
  }
  class IdentifierCache {
    constructor() {
      // Typescript still leaks private properties in the final
      // compiled class, so we may want to move these from _underscore
      // to a WeakMap to avoid leaking
      // currently we leak this for test purposes
      this._cache = {
        lids: Object.create(null),
        types: Object.create(null)
      };
      this._generate = void 0;
      this._update = void 0;
      this._forget = void 0;
      this._reset = void 0;
      this._merge = void 0;
      // we cache the user configuredGenerationMethod at init because it must
      // be configured prior and is not allowed to be changed
      this._generate = configuredGenerationMethod || defaultGenerationMethod;
      this._update = configuredUpdateMethod || defaultEmptyCallback;
      this._forget = configuredForgetMethod || defaultEmptyCallback;
      this._reset = configuredResetMethod || defaultEmptyCallback;
      this._merge = defaultEmptyCallback;
    }

    /**
     * hook to allow management of merge conflicts with identifiers.
     *
     * we allow late binding of this private internal merge so that `internalModelFactory`
     * can insert itself here to handle elimination of duplicates
     *
     * @internal
     */
    __configureMerge(method) {
      this._merge = method || defaultEmptyCallback;
    }

    /**
     * @internal
     */

    _getRecordIdentifier(resource, shouldGenerate) {
      if (shouldGenerate === void 0) {
        shouldGenerate = false;
      }
      // short circuit if we're already the stable version
      if (isStableIdentifier(resource)) {
        {
          // TODO should we instead just treat this case as a new generation skipping the short circuit?
          if (!(resource.lid in this._cache.lids) || this._cache.lids[resource.lid] !== resource) {
            throw new Error(`The supplied identifier ${resource} does not belong to this store instance`);
          }
        }
        return resource;
      }
      var lid = coerceId(resource.lid);
      var identifier = lid !== null ? this._cache.lids[lid] : undefined;
      if (identifier !== undefined) {
        return identifier;
      }
      var type = normalizeModelName(resource.type);
      var id = coerceId(resource.id);
      if (shouldGenerate === false) {
        if (!type || !id) {
          return;
        }
      }

      // `type` must always be present
      {
        if (!isNonEmptyString(resource.type)) {
          throw new Error('resource.type needs to be a string');
        }
      }
      var keyOptions = getTypeIndex(this._cache.types, type);

      // go straight for the stable RecordIdentifier key'd to `lid`
      if (lid !== null) {
        identifier = keyOptions.lid[lid];
      }

      // we may have not seen this resource before
      // but just in case we check our own secondary lookup (`id`)
      if (identifier === undefined && id !== null) {
        identifier = keyOptions.id[id];
      }
      if (identifier === undefined) {
        // we have definitely not seen this resource before
        // so we allow the user configured `GenerationMethod` to tell us
        var newLid = this._generate(resource, 'record');

        // we do this _even_ when `lid` is present because secondary lookups
        // may need to be populated, but we enforce not giving us something
        // different than expected
        if (lid !== null && newLid !== lid) {
          throw new Error(`You should not change the <lid> of a RecordIdentifier`);
        } else if (lid === null) {
          // allow configuration to tell us that we have
          // seen this `lid` before. E.g. a secondary lookup
          // connects this resource to a previously seen
          // resource.
          identifier = keyOptions.lid[newLid];
        }
        if (shouldGenerate === true) {
          if (identifier === undefined) {
            // if we still don't have an identifier, time to generate one
            identifier = makeStableRecordIdentifier(id, type, newLid, 'record', false);

            // populate our unique table
            {
              // realistically if you hit this it means you changed `type` :/
              // TODO consider how to handle type change assertions more gracefully
              if (identifier.lid in this._cache.lids) {
                throw new Error(`You should not change the <type> of a RecordIdentifier`);
              }
            }
            this._cache.lids[identifier.lid] = identifier;

            // populate our primary lookup table
            // TODO consider having the `lid` cache be
            // one level up
            keyOptions.lid[identifier.lid] = identifier;
            // TODO exists temporarily to support `peekAll`
            // but likely to move
            keyOptions._allIdentifiers.push(identifier);
          }

          // populate our own secondary lookup table
          // even for the "successful" secondary lookup
          // by `_generate()`, since we missed the cache
          // previously
          // we use identifier.id instead of id here
          // because they may not match and we prefer
          // what we've set via resource data
          if (identifier.id !== null) {
            keyOptions.id[identifier.id] = identifier;

            // TODO allow filling out of `id` here
            // for the `username` non-client created
            // case.
          }
        }
      }

      return identifier;
    }

    /**
     * allows us to peek without generating when needed
     * useful for the "create" case when we need to see if
     * we are accidentally overwritting something
     *
     * @internal
     */
    peekRecordIdentifier(resource) {
      return this._getRecordIdentifier(resource, false);
    }

    /*
      Returns the Identifier for the given Resource, creates one if it does not yet exist.
       Specifically this means that we:
       - validate the `id` `type` and `lid` combo against known identifiers
      - return an object with an `lid` that is stable (repeated calls with the same
        `id` + `type` or `lid` will return the same `lid` value)
      - this referential stability of the object itself is guaranteed
    */
    getOrCreateRecordIdentifier(resource) {
      return this._getRecordIdentifier(resource, true);
    }

    /*
     Returns a new Identifier for the supplied data. Call this method to generate
     an identifier when a new resource is being created local to the client and
     potentially does not have an `id`.
      Delegates generation to the user supplied `GenerateMethod` if one has been provided
     with the signature `generateMethod({ type }, 'record')`.
     */
    createIdentifierForNewRecord(data) {
      var newLid = this._generate(data, 'record');
      var identifier = makeStableRecordIdentifier(data.id || null, data.type, newLid, 'record', true);
      var keyOptions = getTypeIndex(this._cache.types, data.type);

      // populate our unique table
      {
        if (identifier.lid in this._cache.lids) {
          throw new Error(`The lid generated for the new record is not unique as it matches an existing identifier`);
        }
      }
      this._cache.lids[identifier.lid] = identifier;

      // populate the type+lid cache
      keyOptions.lid[newLid] = identifier;
      // ensure a peekAll sees our new identifier too
      // TODO move this outta here?
      keyOptions._allIdentifiers.push(identifier);
      return identifier;
    }

    /*
     Provides the opportunity to update secondary lookup tables for existing identifiers
     Called after an identifier created with `createIdentifierForNewRecord` has been
     committed.
      Assigned `id` to an `Identifier` if `id` has not previously existed; however,
     attempting to change the `id` or calling update without providing an `id` when
     one is missing will throw an error.
       - sets `id` (if `id` was previously `null`)
      - `lid` and `type` MUST NOT be altered post creation
       If a merge occurs, it is possible the returned identifier does not match the originally
      provided identifier. In this case the abandoned identifier will go through the usual
      `forgetRecordIdentifier` codepaths.
    */
    updateRecordIdentifier(identifierObject, data) {
      var identifier = this.getOrCreateRecordIdentifier(identifierObject);
      var newId = coerceId(data.id);
      var existingIdentifier = detectMerge(this._cache.types, identifier, data, newId, this._cache.lids);
      if (!existingIdentifier) {
        // If the incoming type does not match the identifier type, we need to create an identifier for the incoming
        // data so we can merge the incoming data with the existing identifier, see #7325 and #7363
        if (data.type && identifier.type !== normalizeModelName(data.type)) {
          var incomingDataResource = Ember.assign({}, data);
          // Need to strip the lid from the incomingData in order force a new identifier creation
          delete incomingDataResource.lid;
          existingIdentifier = this.getOrCreateRecordIdentifier(incomingDataResource);
        }
      }
      if (existingIdentifier) {
        var keyOptions = getTypeIndex(this._cache.types, identifier.type);
        identifier = this._mergeRecordIdentifiers(keyOptions, identifier, existingIdentifier, data, newId);
      }
      var id = identifier.id;
      performRecordIdentifierUpdate(identifier, data, this._update);
      newId = identifier.id;

      // add to our own secondary lookup table
      if (id !== newId && newId !== null) {
        var _keyOptions = getTypeIndex(this._cache.types, identifier.type);
        _keyOptions.id[newId] = identifier;
        if (id !== null) {
          delete _keyOptions.id[id];
        }
      }
      return identifier;
    }
    _mergeRecordIdentifiers(keyOptions, identifier, existingIdentifier, data, newId) {
      // delegate determining which identifier to keep to the configured MergeMethod
      var kept = this._merge(identifier, existingIdentifier, data);
      var abandoned = kept === identifier ? existingIdentifier : identifier;

      // cleanup the identifier we no longer need
      this.forgetRecordIdentifier(abandoned);

      // ensure a secondary cache entry for this id for the identifier we do keep
      keyOptions.id[newId] = kept;
      // ensure a secondary cache entry for this id for the abandoned identifier's type we do keep
      var baseKeyOptions = getTypeIndex(this._cache.types, existingIdentifier.type);
      baseKeyOptions.id[newId] = kept;

      // make sure that the `lid` on the data we are processing matches the lid we kept
      data.lid = kept.lid;
      return kept;
    }

    /*
     Provides the opportunity to eliminate an identifier from secondary lookup tables
     as well as eliminates it from ember-data's own lookup tables and book keeping.
      Useful when a record has been deleted and the deletion has been persisted and
     we do not care about the record anymore. Especially useful when an `id` of a
     deleted record might be reused later for a new record.
    */
    forgetRecordIdentifier(identifierObject) {
      var identifier = this.getOrCreateRecordIdentifier(identifierObject);
      var keyOptions = getTypeIndex(this._cache.types, identifier.type);
      if (identifier.id !== null) {
        delete keyOptions.id[identifier.id];
      }
      delete this._cache.lids[identifier.lid];
      delete keyOptions.lid[identifier.lid];
      var index = keyOptions._allIdentifiers.indexOf(identifier);
      keyOptions._allIdentifiers.splice(index, 1);
      unmarkStableIdentifier(identifierObject);
      this._forget(identifier, 'record');
    }
    destroy() {
      this._reset();
    }
  }
  function getTypeIndex(typeMap, type) {
    var typeIndex = typeMap[type];
    if (typeIndex === undefined) {
      typeIndex = {
        lid: Object.create(null),
        id: Object.create(null),
        _allIdentifiers: []
      };
      typeMap[type] = typeIndex;
    }
    return typeIndex;
  }
  function makeStableRecordIdentifier(id, type, lid, bucket, clientOriginated) {
    if (clientOriginated === void 0) {
      clientOriginated = false;
    }
    var recordIdentifier = {
      lid,
      id,
      type
    };
    markStableIdentifier(recordIdentifier);
    {
      // we enforce immutability in dev
      //  but preserve our ability to do controlled updates to the reference
      var wrapper = {
        get lid() {
          return recordIdentifier.lid;
        },
        get id() {
          return recordIdentifier.id;
        },
        get type() {
          return recordIdentifier.type;
        },
        toString() {
          var {
            type,
            id,
            lid
          } = recordIdentifier;
          return `${clientOriginated ? '[CLIENT_ORIGINATED] ' : ''}${type}:${id} (${lid})`;
        }
      };
      addSymbol(wrapper, DEBUG_CLIENT_ORIGINATED, clientOriginated);
      addSymbol(wrapper, DEBUG_IDENTIFIER_BUCKET, bucket);
      wrapper = freeze(wrapper);
      markStableIdentifier(wrapper);
      DEBUG_MAP.set(wrapper, recordIdentifier);
      return wrapper;
    }
  }
  function performRecordIdentifierUpdate(identifier, data, updateFn) {
    var {
      id,
      lid
    } = data;
    var type = data.type && normalizeModelName(data.type);
    {
      // get the mutable instance behind our proxy wrapper
      var wrapper = identifier;
      identifier = DEBUG_MAP.get(wrapper);
      if (lid !== undefined) {
        var newLid = coerceId(lid);
        if (newLid !== identifier.lid) {
          throw new Error(`The 'lid' for a RecordIdentifier cannot be updated once it has been created. Attempted to set lid for '${wrapper}' to '${lid}'.`);
        }
      }
      if (id !== undefined) {
        var newId = coerceId(id);
        if (identifier.id !== null && identifier.id !== newId) {
          // here we warn and ignore, as this may be a mistake, but we allow the user
          // to have multiple cache-keys pointing at a single lid so we cannot error
          ( Ember.warn(`The 'id' for a RecordIdentifier should not be updated once it has been set. Attempted to set id for '${wrapper}' to '${newId}'.`, false, {
            id: 'ember-data:multiple-ids-for-identifier'
          }));
        }
      }

      // TODO consider just ignoring here to allow flexible polymorphic support
      if (type && type !== identifier.type) {
        throw new Error(`The 'type' for a RecordIdentifier cannot be updated once it has been set. Attempted to set type for '${wrapper}' to '${type}'.`);
      }
      updateFn(wrapper, data, 'record');
    }

    // upgrade the ID, this is a "one time only" ability
    // for the multiple-cache-key scenario we "could"
    // use a heuristic to guess the best id for display
    // (usually when `data.id` is available and `data.attributes` is not)
    if (id !== undefined) {
      identifier.id = coerceId(id);
    }
  }
  function detectMerge(typesCache, identifier, data, newId, lids) {
    var {
      id,
      type,
      lid
    } = identifier;
    if (id !== null && id !== newId && newId !== null) {
      var keyOptions = getTypeIndex(typesCache, identifier.type);
      var existingIdentifier = keyOptions.id[newId];
      return existingIdentifier !== undefined ? existingIdentifier : false;
    } else {
      var newType = data.type && normalizeModelName(data.type);

      // If the ids and type are the same but lid is not the same, we should trigger a merge of the identifiers
      if (id !== null && id === newId && newType === type && data.lid && data.lid !== lid) {
        var _existingIdentifier = lids[data.lid];
        return _existingIdentifier !== undefined ? _existingIdentifier : false;
        // If the lids are the same, and ids are the same, but types are different we should trigger a merge of the identifiers
      } else if (id !== null && id === newId && newType && newType !== type && data.lid && data.lid === lid) {
        var _keyOptions2 = getTypeIndex(typesCache, newType);
        var _existingIdentifier2 = _keyOptions2.id[id];
        return _existingIdentifier2 !== undefined ? _existingIdentifier2 : false;
      }
    }
    return false;
  }

  function constructResource(type, id, lid) {
    var trueId = coerceId(id);
    if (!isNonEmptyString(trueId)) {
      if (isNonEmptyString(lid)) {
        return {
          type,
          id: trueId,
          lid
        };
      }
      throw new Error(`Expected either id or lid to be a valid string`);
    }
    if (isNonEmptyString(lid)) {
      return {
        type,
        id: trueId,
        lid
      };
    }
    return {
      type,
      id: trueId
    };
  }

  /**
    @module @ember-data/store
  */

  /**
    A `PromiseArray` is an object that acts like both an `Ember.Array`
    and a promise. When the promise is resolved the resulting value
    will be set to the `PromiseArray`'s `content` property. This makes
    it easy to create data bindings with the `PromiseArray` that will be
    updated when the promise resolves.

    For more information see the [Ember.PromiseProxyMixin
    documentation](/ember/release/classes/PromiseProxyMixin).

    Example

    ```javascript
    let promiseArray = PromiseArray.create({
      promise: $.getJSON('/some/remote/data.json')
    });

    promiseArray.get('length'); // 0

    promiseArray.then(function() {
      promiseArray.get('length'); // 100
    });
    ```

    @class PromiseArray
    @extends Ember.ArrayProxy
    @uses Ember.PromiseProxyMixin
  */
  var PromiseArray = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin, {
    meta: Ember.computed.reads('content.meta')
  });

  /**
    A `PromiseObject` is an object that acts like both an `EmberObject`
    and a promise. When the promise is resolved, then the resulting value
    will be set to the `PromiseObject`'s `content` property. This makes
    it easy to create data bindings with the `PromiseObject` that will
    be updated when the promise resolves.

    For more information see the [Ember.PromiseProxyMixin
    documentation](/ember/release/classes/PromiseProxyMixin.html).

    Example

    ```javascript
    let promiseObject = PromiseObject.create({
      promise: $.getJSON('/some/remote/data.json')
    });

    promiseObject.get('name'); // null

    promiseObject.then(function() {
      promiseObject.get('name'); // 'Tomster'
    });
    ```

    @class PromiseObject
    @extends Ember.ObjectProxy
    @uses Ember.PromiseProxyMixin
  */
  var PromiseObject = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  function promiseObject(promise, label) {
    return PromiseObject.create({
      promise: Ember.RSVP.Promise.resolve(promise, label)
    });
  }
  function promiseArray(promise, label) {
    return PromiseArray.create({
      promise: Ember.RSVP.Promise.resolve(promise, label)
    });
  }

  /**
    @module @ember-data/store
  */
  /**
   * Get the materialized model from the internalModel/promise
   * that returns an internal model and return it in a promiseObject.
   *
   * Useful for returning from find methods
   *
   * @internal
   */
  function promiseRecord(internalModelPromise, label) {
    var toReturn = internalModelPromise.then(internalModel => internalModel.getRecord());
    return promiseObject(toReturn, label);
  }

  var backburner = new Ember._Backburner(['normalizeRelationships', 'syncRelationships', 'finished']);
  {
    Ember.Test.registerWaiter(() => {
      return !backburner.currentInstance && !backburner.hasTimers();
    });
  }

  /**
    @module @ember-data/store
  */

  var SOURCE_POINTER_REGEXP = /^\/?data\/(attributes|relationships)\/(.*)/;
  var SOURCE_POINTER_PRIMARY_REGEXP = /^\/?data/;
  var PRIMARY_ATTRIBUTE_KEY = 'base';

  /**
    Convert an hash of errors into an array with errors in JSON-API format.
     ```javascript
    import DS from 'ember-data';

     const { errorsHashToArray } = DS;
     
     let errors = {
      base: 'Invalid attributes on saving this record',
      name: 'Must be present',
      age: ['Must be present', 'Must be a number']
    };
     let errorsArray = errorsHashToArray(errors);
    // [
    //   {
    //     title: "Invalid Document",
    //     detail: "Invalid attributes on saving this record",
    //     source: { pointer: "/data" }
    //   },
    //   {
    //     title: "Invalid Attribute",
    //     detail: "Must be present",
    //     source: { pointer: "/data/attributes/name" }
    //   },
    //   {
    //     title: "Invalid Attribute",
    //     detail: "Must be present",
    //     source: { pointer: "/data/attributes/age" }
    //   },
    //   {
    //     title: "Invalid Attribute",
    //     detail: "Must be a number",
    //     source: { pointer: "/data/attributes/age" }
    //   }
    // ]
    ```
    @method errorsHashToArray
    @public
    @param {Object} errors hash with errors as properties
    @return {Array} array of errors in JSON-API format
  */
  function errorsHashToArray(errors) {
    var out = [];
    if (Ember.isPresent(errors)) {
      Object.keys(errors).forEach(key => {
        var messages = Ember.makeArray(errors[key]);
        for (var i = 0; i < messages.length; i++) {
          var title = 'Invalid Attribute';
          var pointer = `/data/attributes/${key}`;
          if (key === PRIMARY_ATTRIBUTE_KEY) {
            title = 'Invalid Document';
            pointer = `/data`;
          }
          out.push({
            title: title,
            detail: messages[i],
            source: {
              pointer: pointer
            }
          });
        }
      });
    }
    return out;
  }

  /**
    Convert an array of errors in JSON-API format into an object.

    ```javascript
    import DS from 'ember-data';

    const { errorsArrayToHash } = DS;

    let errorsArray = [
      {
        title: 'Invalid Attribute',
        detail: 'Must be present',
        source: { pointer: '/data/attributes/name' }
      },
      {
        title: 'Invalid Attribute',
        detail: 'Must be present',
        source: { pointer: '/data/attributes/age' }
      },
      {
        title: 'Invalid Attribute',
        detail: 'Must be a number',
        source: { pointer: '/data/attributes/age' }
      }
    ];

    let errors = errorsArrayToHash(errorsArray);
    // {
    //   "name": ["Must be present"],
    //   "age":  ["Must be present", "must be a number"]
    // }
    ```

    @method errorsArrayToHash
    @public
    @param {Array} errors array of errors in JSON-API format
    @return {Object}
  */
  function errorsArrayToHash(errors) {
    var out = {};
    if (Ember.isPresent(errors)) {
      errors.forEach(error => {
        if (error.source && error.source.pointer) {
          var key = error.source.pointer.match(SOURCE_POINTER_REGEXP);
          if (key) {
            key = key[2];
          } else if (error.source.pointer.search(SOURCE_POINTER_PRIMARY_REGEXP) !== -1) {
            key = PRIMARY_ATTRIBUTE_KEY;
          }
          if (key) {
            out[key] = out[key] || [];
            out[key].push(error.detail || error.title);
          }
        }
      });
    }
    return out;
  }

  /*
   * Returns the RecordData instance associated with a given
   * Model or InternalModel.
   *
   * Intentionally "loose" to allow anything with an _internalModel
   * property until InternalModel is eliminated.
   *
   * Intentionally not typed to `InternalModel` due to circular dependency
   *  which that creates.
   *
   * Overtime, this should shift to a "weakmap" based lookup in the
   *  "Ember.getOwner(obj)" style.
   */

  function recordDataFor(instance) {
    var internalModel = instance._internalModel || instance.internalModel || instance;
    return internalModel._recordData || null;
  }

  /**
    @module @ember-data/store
  */
  function relationshipsFor(instance) {
    var i = instance;
    // TODO this cast is not safe but it is the assumption of the current
    // state of the code. We need to update this class to handle CUSTOM_MODEL_CLASS
    // requirements.
    var recordData = i._internalModel._recordData;
    return recordData._relationships;
  }
  function relationshipStateFor(instance, propertyName) {
    return relationshipsFor(instance).get(propertyName);
  }
  /**
    @class Snapshot
    @private
    @constructor
    @param {Model} internalModel The model to create a snapshot from
  */
  class Snapshot {
    constructor(options, identifier, _store) {
      this.__attributes = null;
      this._belongsToRelationships = Object.create(null);
      this._belongsToIds = Object.create(null);
      this._hasManyRelationships = Object.create(null);
      this._hasManyIds = Object.create(null);
      this._internalModel = void 0;
      this._changedAttributes = void 0;
      this.identifier = void 0;
      this.modelName = void 0;
      this.id = void 0;
      this.include = void 0;
      this.adapterOptions = void 0;
      this._store = _store;
      var internalModel = this._internalModel = _store._internalModelForResource(identifier);
      this.modelName = identifier.type;
      /*
        If the internalModel does not yet have a record, then we are
        likely a snapshot being provided to a find request, so we
        populate __attributes lazily. Else, to preserve the "moment
        in time" in which a snapshot is created, we greedily grab
        the values.
       */
      if (internalModel.hasRecord) {
        this._attributes;
      }

      /**
       The id of the snapshot's underlying record
        Example
        ```javascript
       // store.push('post', { id: 1, author: 'Tomster', title: 'Ember.js rocks' });
       postSnapshot.id; // => '1'
       ```
        @property id
       @type {String}
       */
      this.id = identifier.id;

      /**
       A hash of adapter options
       @property adapterOptions
       @type {Object}
       */
      this.adapterOptions = options.adapterOptions;

      /**
       @property include
       @type {String|Array}
       */
      this.include = options.include;

      /**
       The name of the type of the underlying record for this snapshot, as a string.
        @property modelName
       @type {String}
       */
      this.modelName = internalModel.modelName;
      if (internalModel.hasRecord) {
        this._changedAttributes = recordDataFor(internalModel).changedAttributes();
      }
    }

    /**
     The underlying record for this snapshot. Can be used to access methods and
     properties defined on the record.
      Example
      ```javascript
     let json = snapshot.record.toJSON();
     ```
      @property record
     @type {Model}
     */
    get record() {
      return this._internalModel.getRecord();
    }
    get _attributes() {
      if (this.__attributes !== null) {
        return this.__attributes;
      }
      var record = this.record;
      var attributes = this.__attributes = Object.create(null);
      var attrs;
      {
        attrs = Object.keys(this._store._attributesDefinitionFor(this.modelName));
      }
      {
        // When CUSTOM_MODEL_CLASS is false `record` must be DSModel
        record.eachAttribute(keyName => attributes[keyName] = Ember.get(record, keyName));
      }
      return attributes;
    }

    /**
     The type of the underlying record for this snapshot, as a Model.
      @property type
     @type {Model}
     */
    get type() {
      return this._internalModel.modelClass;
    }
    get isNew() {
      {
        throw new Error('isNew is only available when custom model class ff is on');
      }
    }

    /**
     Returns the value of an attribute.
      Example
      ```javascript
     // store.push('post', { id: 1, author: 'Tomster', title: 'Ember.js rocks' });
     postSnapshot.attr('author'); // => 'Tomster'
     postSnapshot.attr('title'); // => 'Ember.js rocks'
     ```
      Note: Values are loaded eagerly and cached when the snapshot is created.
      @method attr
     @param {String} keyName
     @return {Object} The attribute value or undefined
     */
    attr(keyName) {
      if (keyName in this._attributes) {
        return this._attributes[keyName];
      }
      ( Ember.assert(`Model '${this.identifier}' has no attribute named '${keyName}' defined.`, false));
    }

    /**
     Returns all attributes and their corresponding values.
      Example
      ```javascript
     // store.push('post', { id: 1, author: 'Tomster', title: 'Ember.js rocks' });
     postSnapshot.attributes(); // => { author: 'Tomster', title: 'Ember.js rocks' }
     ```
      @method attributes
     @return {Object} All attributes of the current snapshot
     */
    attributes() {
      return Ember.assign({}, this._attributes);
    }

    /**
     Returns all changed attributes and their old and new values.
      Example
      ```javascript
     // store.push('post', { id: 1, author: 'Tomster', title: 'Ember.js rocks' });
     postModel.set('title', 'Ember.js rocks!');
     postSnapshot.changedAttributes(); // => { title: ['Ember.js rocks', 'Ember.js rocks!'] }
     ```
      @method changedAttributes
     @return {Object} All changed attributes of the current snapshot
     */
    changedAttributes() {
      var changedAttributes = Object.create(null);
      if (!this._changedAttributes) {
        return changedAttributes;
      }
      var changedAttributeKeys = Object.keys(this._changedAttributes);
      for (var i = 0, length = changedAttributeKeys.length; i < length; i++) {
        var _key = changedAttributeKeys[i];
        changedAttributes[_key] = this._changedAttributes[_key].slice();
      }
      return changedAttributes;
    }

    /**
     Returns the current value of a belongsTo relationship.
      `belongsTo` takes an optional hash of options as a second parameter,
     currently supported options are:
      - `id`: set to `true` if you only want the ID of the related record to be
     returned.
      Example
      ```javascript
     // store.push('post', { id: 1, title: 'Hello World' });
     // store.createRecord('comment', { body: 'Lorem ipsum', post: post });
     commentSnapshot.belongsTo('post'); // => Snapshot
     commentSnapshot.belongsTo('post', { id: true }); // => '1'
      // store.push('comment', { id: 1, body: 'Lorem ipsum' });
     commentSnapshot.belongsTo('post'); // => undefined
     ```
      Calling `belongsTo` will return a new Snapshot as long as there's any known
     data for the relationship available, such as an ID. If the relationship is
     known but unset, `belongsTo` will return `null`. If the contents of the
     relationship is unknown `belongsTo` will return `undefined`.
      Note: Relationships are loaded lazily and cached upon first access.
      @method belongsTo
     @param {String} keyName
     @param {Object} [options]
     @return {(Snapshot|String|null|undefined)} A snapshot or ID of a known
     relationship or null if the relationship is known but unset. undefined
     will be returned if the contents of the relationship is unknown.
     */
    belongsTo(keyName, options) {
      var returnModeIsId = !!(options && options.id);
      var relationship;
      var inverseInternalModel;
      var result;
      var store = this._internalModel.store;
      if (returnModeIsId === true && keyName in this._belongsToIds) {
        return this._belongsToIds[keyName];
      }
      if (returnModeIsId === false && keyName in this._belongsToRelationships) {
        return this._belongsToRelationships[keyName];
      }
      var relationshipMeta = store._relationshipMetaFor(this.modelName, null, keyName);
      ( !(relationshipMeta && relationshipMeta.kind === 'belongsTo') && Ember.assert(`Model '${this.identifier}' has no belongsTo relationship named '${keyName}' defined.`, relationshipMeta && relationshipMeta.kind === 'belongsTo')); // TODO @runspired it seems this code branch would not work with CUSTOM_MODEL_CLASSes
      // TODO @runspired instead of casting here either generify relationship state or
      // provide a mechanism on relationship state by which to narrow.
      relationship = relationshipStateFor(this, keyName);
      var value = relationship.getData();
      var data = value && value.data;
      inverseInternalModel = data ? store._internalModelForResource(data) : null;
      if (value && value.data !== undefined) {
        if (inverseInternalModel && !inverseInternalModel.isDeleted()) {
          if (returnModeIsId) {
            result = inverseInternalModel.id;
          } else {
            result = inverseInternalModel.createSnapshot();
          }
        } else {
          result = null;
        }
      }
      if (returnModeIsId) {
        this._belongsToIds[keyName] = result;
      } else {
        this._belongsToRelationships[keyName] = result;
      }
      return result;
    }

    /**
     Returns the current value of a hasMany relationship.
      `hasMany` takes an optional hash of options as a second parameter,
     currently supported options are:
      - `ids`: set to `true` if you only want the IDs of the related records to be
     returned.
      Example
      ```javascript
     // store.push('post', { id: 1, title: 'Hello World', comments: [2, 3] });
     postSnapshot.hasMany('comments'); // => [Snapshot, Snapshot]
     postSnapshot.hasMany('comments', { ids: true }); // => ['2', '3']
      // store.push('post', { id: 1, title: 'Hello World' });
     postSnapshot.hasMany('comments'); // => undefined
     ```
      Note: Relationships are loaded lazily and cached upon first access.
      @method hasMany
     @param {String} keyName
     @param {Object} [options]
     @return {(Array|undefined)} An array of snapshots or IDs of a known
     relationship or an empty array if the relationship is known but unset.
     undefined will be returned if the contents of the relationship is unknown.
     */
    hasMany(keyName, options) {
      var returnModeIsIds = !!(options && options.ids);
      var relationship;
      var results;
      var cachedIds = this._hasManyIds[keyName];
      var cachedSnapshots = this._hasManyRelationships[keyName];
      if (returnModeIsIds === true && keyName in this._hasManyIds) {
        return cachedIds;
      }
      if (returnModeIsIds === false && keyName in this._hasManyRelationships) {
        return cachedSnapshots;
      }
      var store = this._internalModel.store;
      var relationshipMeta = store._relationshipMetaFor(this.modelName, null, keyName);
      ( !(relationshipMeta && relationshipMeta.kind === 'hasMany') && Ember.assert(`Model '${this.identifier}' has no hasMany relationship named '${keyName}' defined.`, relationshipMeta && relationshipMeta.kind === 'hasMany')); // TODO @runspired it seems this code branch would not work with CUSTOM_MODEL_CLASSes
      // TODO @runspired instead of casting here either generify relationship state or
      // provide a mechanism on relationship state by which to narrow.
      relationship = relationshipStateFor(this, keyName);
      var value = relationship.getData();
      if (value.data) {
        results = [];
        value.data.forEach(member => {
          var internalModel = store._internalModelForResource(member);
          if (!internalModel.isDeleted()) {
            if (returnModeIsIds) {
              results.push(member.id);
            } else {
              results.push(internalModel.createSnapshot());
            }
          }
        });
      }

      // we assign even if `undefined` so that we don't reprocess the relationship
      // on next access. This works with the `keyName in` checks above.
      if (returnModeIsIds) {
        this._hasManyIds[keyName] = results;
      } else {
        this._hasManyRelationships[keyName] = results;
      }
      return results;
    }

    /**
      Iterates through all the attributes of the model, calling the passed
      function on each attribute.
       Example
       ```javascript
      snapshot.eachAttribute(function(name, meta) {
        // ...
      });
      ```
       @method eachAttribute
      @param {Function} callback the callback to execute
      @param {Object} [binding] the value to which the callback's `this` should be bound
    */
    eachAttribute(callback, binding) {
      {
        // in the non CUSTOM_MODEL_CLASS world we only have DSModel instances
        this.record.eachAttribute(callback, binding);
      }
    }

    /**
      Iterates through all the relationships of the model, calling the passed
      function on each relationship.
       Example
       ```javascript
      snapshot.eachRelationship(function(name, relationship) {
        // ...
      });
      ```
       @method eachRelationship
      @param {Function} callback the callback to execute
      @param {Object} [binding] the value to which the callback's `this` should be bound
    */
    eachRelationship(callback, binding) {
      {
        // in the non CUSTOM_MODEL_CLASS world we only have DSModel instances
        this.record.eachRelationship(callback, binding);
      }
    }

    /**
      Serializes the snapshot using the serializer for the model.
       Example
       ```app/adapters/application.js
      import Adapter from '@ember-data/adapter';
       export default Adapter.extend({
        createRecord(store, type, snapshot) {
          let data = snapshot.serialize({ includeId: true });
          let url = `/${type.modelName}`;
           return fetch(url, {
            method: 'POST',
            body: data,
          }).then((response) => response.json())
        }
      });
      ```
       @method serialize
      @param {Object} options
      @return {Object} an object whose values are primitive JSON values only
     */
    serialize(options) {
      return this._store.serializerFor(this.modelName).serialize(this, options);
    }
  }

  /**
    @module @ember-data/store
  */

  function _bind(fn) {
    for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }
    return function () {
      return fn.apply(undefined, args);
    };
  }
  function _guard(promise, test) {
    var guarded = promise.finally(() => {
      if (!test()) {
        guarded._subscribers.length = 0;
      }
    });
    return guarded;
  }
  function _objectIsAlive(object) {
    return !(Ember.get(object, 'isDestroyed') || Ember.get(object, 'isDestroying'));
  }
  function guardDestroyedStore(promise, store, label) {
    var token;
    {
      token = store._trackAsyncRequestStart(label);
    }
    var wrapperPromise = Ember.RSVP.resolve(promise, label).then(v => promise);
    return _guard(wrapperPromise, () => {
      {
        store._trackAsyncRequestEnd(token);
      }
      return _objectIsAlive(store);
    });
  }

  /**
    @module @ember-data/store
  */
  /*
    This is a helper method that validates a JSON API top-level document

    The format of a document is described here:
    http://jsonapi.org/format/#document-top-level

    @method validateDocumentStructure
    @param {Object} doc JSON API document
    @return {array} An array of errors found in the document structure
  */
  function validateDocumentStructure(doc) {
    var errors = [];
    if (!doc || typeof doc !== 'object') {
      errors.push('Top level of a JSON API document must be an object');
    } else {
      if (!('data' in doc) && !('errors' in doc) && !('meta' in doc)) {
        errors.push('One or more of the following keys must be present: "data", "errors", "meta".');
      } else {
        if ('data' in doc && 'errors' in doc) {
          errors.push('Top level keys "errors" and "data" cannot both be present in a JSON API document');
        }
      }
      if ('data' in doc) {
        if (!(doc.data === null || Array.isArray(doc.data) || typeof doc.data === 'object')) {
          errors.push('data must be null, an object, or an array');
        }
      }
      if ('meta' in doc) {
        if (typeof doc.meta !== 'object') {
          errors.push('meta must be an object');
        }
      }
      if ('errors' in doc) {
        if (!Array.isArray(doc.errors)) {
          errors.push('errors must be an array');
        }
      }
      if ('links' in doc) {
        if (typeof doc.links !== 'object') {
          errors.push('links must be an object');
        }
      }
      if ('jsonapi' in doc) {
        if (typeof doc.jsonapi !== 'object') {
          errors.push('jsonapi must be an object');
        }
      }
      if ('included' in doc) {
        if (typeof doc.included !== 'object') {
          errors.push('included must be an array');
        }
      }
    }
    return errors;
  }

  /*
    This is a helper method that always returns a JSON-API Document.

    @method normalizeResponseHelper
    @param {Serializer} serializer
    @param {Store} store
    @param {subclass of Model} modelClass
    @param {Object} payload
    @param {String|Number} id
    @param {String} requestType
    @return {Object} JSON-API Document
  */
  function normalizeResponseHelper(serializer, store, modelClass, payload, id, requestType) {
    var normalizedResponse = serializer.normalizeResponse(store, modelClass, payload, id, requestType);
    var validationErrors = [];
    {
      validationErrors = validateDocumentStructure(normalizedResponse);
    }
    ( !(validationErrors.length === 0) && Ember.assert(`normalizeResponse must return a valid JSON API document:\n\t* ${validationErrors.join('\n\t* ')}`, validationErrors.length === 0));
    return normalizedResponse;
  }

  var emberRun = Ember.run.backburner;

  /**
    @module @ember-data/store
  */

  var INSTANCE_DEPRECATIONS;
  var lookupDeprecations;
  var DeprecatedEvented;
  {
    INSTANCE_DEPRECATIONS = new WeakMap();
    lookupDeprecations = function lookupInstanceDrecations(instance) {
      var deprecations = INSTANCE_DEPRECATIONS.get(instance);
      if (!deprecations) {
        deprecations = {};
        INSTANCE_DEPRECATIONS.set(instance, deprecations);
      }
      return deprecations;
    };
    DeprecatedEvented = Ember.Mixin.create(Ember.Evented, {
      /**
       * Provides a way to call Evented without logging deprecation warnings
       * @param {String} name
       */
      _has(name) {
        return Ember.Evented.mixins[0].properties.has.call(this, name);
      },
      _on() {
        return Ember.Evented.mixins[0].properties.on.call(this, ...arguments);
      },
      _deprecateEvented(eventName) {
        var deprecations = lookupDeprecations(this);
        var _deprecationData = this._getDeprecatedEventedInfo ? `on ${this._getDeprecatedEventedInfo()}` : '';
        var deprecationMessage = _deprecationData ? `Called ${eventName} ${_deprecationData}` : eventName;
        ( !(deprecations[eventName]) && Ember.deprecate(deprecationMessage, deprecations[eventName], {
          id: 'ember-data:evented-api-usage',
          until: '4.0',
          url: 'https://deprecations.emberjs.com/ember-data/v3.x/#deprecatingrecordlifecycleeventmethods'
        }));
        deprecations[eventName] = true;
      },
      has(name) {
        this._deprecateEvented(name);
        return this._super(...arguments);
      },
      off(name, target, method) {
        this._deprecateEvented(name);
        return this._super(...arguments);
      },
      on(name, target, method) {
        this._deprecateEvented(name);
        return this._super(...arguments);
      },
      one(name, target, method) {
        this._deprecateEvented(name);
        return this._super(...arguments);
      },
      trigger(name) {
        this._deprecateEvented(name);
        return this._super(...arguments);
      }
    });
  }
  var DeprecatedEvented$1 =   DeprecatedEvented  ;

  /**
    @module @ember-data/store
  */

  /**
    @class SnapshotRecordArray
    @private
    @constructor
    @param {Array} snapshots An array of snapshots
    @param {Object} meta
  */
  class SnapshotRecordArray {
    constructor(recordArray, meta, options) {
      if (options === void 0) {
        options = {};
      }
      this._snapshots = void 0;
      this._recordArray = void 0;
      this._type = void 0;
      this.length = void 0;
      this.meta = void 0;
      this.adapterOptions = void 0;
      this.include = void 0;
      /**
        An array of snapshots
        @private
        @property _snapshots
        @type {Array}
      */
      this._snapshots = null;

      /**
        An array of records
        @private
        @property _recordArray
        @type {Array}
      */
      this._recordArray = recordArray;

      /**
        Number of records in the array
         Example
         ```app/adapters/post.js
        import JSONAPIAdapter from '@ember-data/adapter/json-api';
         export default class PostAdapter extends JSONAPIAdapter {
          shouldReloadAll(store, snapshotRecordArray) {
            return !snapshotRecordArray.length;
          }
        });
        ```
         @property length
        @type {Number}
      */
      this.length = recordArray.get('length');
      this._type = null;

      /**
        Meta objects for the record array.
         Example
         ```app/adapters/post.js
        import JSONAPIAdapter from '@ember-data/adapter/json-api';
         export default class PostAdapter extends JSONAPIAdapter {
          shouldReloadAll(store, snapshotRecordArray) {
            let lastRequestTime = snapshotRecordArray.meta.lastRequestTime;
            let twentyMinutes = 20 * 60 * 1000;
            return Date.now() > lastRequestTime + twentyMinutes;
          }
        });
        ```
         @property meta
        @type {Object}
      */
      this.meta = meta;

      /**
        A hash of adapter options passed into the store method for this request.
         Example
         ```app/adapters/post.js
        import MyCustomAdapter from './custom-adapter';
         export default class PostAdapter extends MyCustomAdapter {
          findAll(store, type, sinceToken, snapshotRecordArray) {
            if (snapshotRecordArray.adapterOptions.subscribe) {
              // ...
            }
            // ...
          }
        }
        ```
         @property adapterOptions
        @type {Object}
      */
      this.adapterOptions = options.adapterOptions;

      /**
        The relationships to include for this request.
         Example
         ```app/adapters/application.js
        import Adapter from '@ember-data/adapter';
         export default class ApplicationAdapter extends Adapter {
          findAll(store, type, snapshotRecordArray) {
            let url = `/${type.modelName}?include=${encodeURIComponent(snapshotRecordArray.include)}`;
             return fetch(url).then((response) => response.json())
          }
        }
        ```
         @property include
        @type {String|Array}
      */
      this.include = options.include;
    }

    /**
      The type of the underlying records for the snapshots in the array, as a Model
      @property type
      @type {Model}
    */
    get type() {
      return this._type || (this._type = this._recordArray.get('type'));
    }
    /**
      The modelName of the underlying records for the snapshots in the array, as a Model
      @property type
      @type {Model}
    */
    get modelName() {
      return this._recordArray.modelName;
    }

    /**
      Get snapshots of the underlying record array
       Example
       ```app/adapters/post.js
      import JSONAPIAdapter from '@ember-data/adapter/json-api';
       export default class PostAdapter extends JSONAPIAdapter {
        shouldReloadAll(store, snapshotArray) {
          let snapshots = snapshotArray.snapshots();
           return snapshots.any(function(ticketSnapshot) {
            let timeDiff = moment().diff(ticketSnapshot.attr('lastAccessedAt'), 'minutes');
            if (timeDiff > 20) {
              return true;
            } else {
              return false;
            }
          });
        }
      }
      ```
       @method snapshots
      @return {Array} Array of snapshots
    */
    snapshots() {
      if (this._snapshots !== null) {
        return this._snapshots;
      }
      this._snapshots = this._recordArray._takeSnapshot();
      return this._snapshots;
    }
  }

  /**
    @module @ember-data/store
  */
  /**
   `InternalModelMap` is a custom storage map for internalModels of a given modelName
   used by `IdentityMap`.

   It was extracted from an implicit pojo based "internalModel map" and preserves
   that interface while we work towards a more official API.

   @class InternalModelMap
   @private
   */
  class InternalModelMap {
    constructor(modelName) {
      this._idToModel = Object.create(null);
      this._models = [];
      this._metadata = null;
      this.modelName = modelName;
    }

    /**
     * @method get
     * @param id {String}
     * @return {InternalModel}
     */
    get(id) {
      return this._idToModel[id] || null;
    }
    has(id) {
      return !!this._idToModel[id];
    }
    get length() {
      return this._models.length;
    }
    get recordIdentifiers() {
      return this._models.map(m => m.identifier);
    }
    set(id, internalModel) {
      ( !(typeof id === 'string' && id.length > 0) && Ember.assert(`You cannot index an internalModel by an empty id'`, typeof id === 'string' && id.length > 0));
      ( !(internalModel instanceof InternalModel) && Ember.assert(`You cannot set an index for an internalModel to something other than an internalModel`, internalModel instanceof InternalModel));
      ( !(this.contains(internalModel)) && Ember.assert(`You cannot set an index for an internalModel that is not in the InternalModelMap`, this.contains(internalModel)));
      ( !(!this.has(id) || this.get(id) === internalModel) && Ember.assert(`You cannot update the id index of an InternalModel once set. Attempted to update ${id}.`, !this.has(id) || this.get(id) === internalModel));
      this._idToModel[id] = internalModel;
    }
    add(internalModel, id) {
      ( !(!this.contains(internalModel)) && Ember.assert(`You cannot re-add an already present InternalModel to the InternalModelMap.`, !this.contains(internalModel)));
      if (id) {
        ( !(!this.has(id) || this.get(id) === internalModel) && Ember.assert(`Duplicate InternalModel for ${this.modelName}:${id} detected.`, !this.has(id) || this.get(id) === internalModel));
        this._idToModel[id] = internalModel;
      }
      this._models.push(internalModel);
    }
    remove(internalModel, id) {
      delete this._idToModel[id];
      var loc = this._models.indexOf(internalModel);
      if (loc !== -1) {
        this._models.splice(loc, 1);
      }
    }
    contains(internalModel) {
      return this._models.indexOf(internalModel) !== -1;
    }

    /**
     An array of all models of this modelName
     @property models
     @type Array
     */
    get models() {
      return this._models;
    }

    /**
     * meta information about internalModels
     * @property metadata
     * @type Object
     */
    get metadata() {
      return this._metadata || (this._metadata = Object.create(null));
    }

    /**
     Destroy all models in the internalModelTest and wipe metadata.
      @method clear
     */
    clear() {
      var internalModels = this._models;
      this._models = [];
      for (var i = 0; i < internalModels.length; i++) {
        var internalModel = internalModels[i];
        internalModel.unloadRecord();
      }
      this._metadata = null;
    }
  }

  /**
    @module @ember-data/store
  */
  /**
   `IdentityMap` is a custom storage map for records by modelName
   used by `Store`.

   @class IdentityMap
   @private
   */
  class IdentityMap {
    constructor() {
      this._map = Object.create(null);
    }
    /**
     Retrieves the `InternalModelMap` for a given modelName,
     creating one if one did not already exist. This is
     similar to `getWithDefault` or `get` on a `MapWithDefault`
      @method retrieve
     @param modelName a previously normalized modelName
     @return {InternalModelMap} the InternalModelMap for the given modelName
     */
    retrieve(modelName) {
      var map = this._map[modelName];
      if (map === undefined) {
        map = this._map[modelName] = new InternalModelMap(modelName);
      }
      return map;
    }

    /**
     Clears the contents of all known `RecordMaps`, but does
     not remove the InternalModelMap instances.
      @method clear
     */
    clear() {
      var map = this._map;
      var keys = Object.keys(map);
      for (var i = 0; i < keys.length; i++) {
        var key = keys[i];
        map[key].clear();
      }
    }
  }

  /**
    @module @ember-data/store
  */

  var FactoryCache = new WeakMap();
  var RecordCache = new WeakMap();
  function peekRecordIdentifier(record) {
    return RecordCache.get(record);
  }
  function recordIdentifierFor(record) {
    var identifier = RecordCache.get(record);
    if ( identifier === undefined) {
      throw new Error(`${record} is not a record instantiated by @ember-data/store`);
    }
    return identifier;
  }
  function setRecordIdentifier(record, identifier) {
    if ( RecordCache.has(record)) {
      throw new Error(`${record} was already assigned an identifier`);
    }

    /*
    It would be nice to do a reverse check here that an identifier has not
    previously been assigned a record; however, unload + rematerialization
    prevents us from having a great way of doing so when CustomRecordClasses
    don't necessarily give us access to a `isDestroyed` for dematerialized
    instance.
    */

    RecordCache.set(record, identifier);
  }
  function internalModelFactoryFor(store) {
    var factory = FactoryCache.get(store);
    if (factory === undefined) {
      factory = new InternalModelFactory(store);
      FactoryCache.set(store, factory);
    }
    return factory;
  }

  /**
   * The InternalModelFactory handles the lifecyle of
   * instantiating, caching, and destroying InternalModel
   * instances.
   *
   * @internal
   */
  class InternalModelFactory {
    constructor(store) {
      this._identityMap = void 0;
      this._newlyCreated = void 0;
      this.identifierCache = void 0;
      this.store = store;
      this.identifierCache = identifierCacheFor(store);
      this.identifierCache.__configureMerge((identifier, matchedIdentifier, resourceData) => {
        var intendedIdentifier = identifier;
        if (identifier.id !== matchedIdentifier.id) {
          intendedIdentifier = identifier.id === resourceData.id ? identifier : matchedIdentifier;
        } else if (identifier.type !== matchedIdentifier.type) {
          intendedIdentifier = identifier.type === resourceData.type ? identifier : matchedIdentifier;
        }
        var altIdentifier = identifier === intendedIdentifier ? matchedIdentifier : identifier;

        // check for duplicate InternalModel's
        var map = this.modelMapFor(identifier.type);
        var im = map.get(intendedIdentifier.lid);
        var otherIm = map.get(altIdentifier.lid);

        // we cannot merge internalModels when both have records
        // (this may not be strictly true, we could probably swap the internalModel the record points at)
        if (im && otherIm && im.hasRecord && otherIm.hasRecord) {
          throw new Error(`Failed to update the 'id' for the RecordIdentifier '${identifier.type}:${identifier.id} (${identifier.lid})' to '${resourceData.id}', because that id is already in use by '${matchedIdentifier.type}:${matchedIdentifier.id} (${matchedIdentifier.lid})'`);
        }

        // remove otherIm from cache
        if (otherIm) {
          map.remove(otherIm, altIdentifier.lid);
        }
        if (im === null && otherIm === null) {
          // nothing more to do
          return intendedIdentifier;

          // only the other has an InternalModel
          // OR only the other has a Record
        } else if (im === null && otherIm !== null || im && !im.hasRecord && otherIm && otherIm.hasRecord) {
          if (im) {
            // TODO check if we are retained in any async relationships
            map.remove(im, intendedIdentifier.lid);
            // im.destroy();
          }

          im = otherIm;
          // TODO do we need to notify the id change?
          im._id = intendedIdentifier.id;
          map.add(im, intendedIdentifier.lid);

          // just use im
        }
        return intendedIdentifier;
      });
      this._identityMap = new IdentityMap();
    }

    /**
     * Retrieve the InternalModel for a given { type, id, lid }.
     *
     * If an InternalModel does not exist, it instantiates one.
     *
     * If an InternalModel does exist bus has a scheduled destroy,
     *   the scheduled destroy will be cancelled.
     *
     * @internal
     */
    lookup(resource, data) {
      if (data !== undefined) {
        // if we've been given data associated with this lookup
        // we must first give secondary-caches for LIDs the
        // opportunity to populate based on it
        this.identifierCache.getOrCreateRecordIdentifier(data);
      }
      var identifier = this.identifierCache.getOrCreateRecordIdentifier(resource);
      var internalModel = this.peek(identifier);
      if (internalModel) {
        // unloadRecord is async, if one attempts to unload + then sync push,
        //   we must ensure the unload is canceled before continuing
        //   The createRecord path will take _existingInternalModelForId()
        //   which will call `destroySync` instead for this unload + then
        //   sync createRecord scenario. Once we have true client-side
        //   delete signaling, we should never call destroySync
        if (internalModel.hasScheduledDestroy()) {
          internalModel.cancelDestroy();
        }
        return internalModel;
      }
      return this._build(identifier, false);
    }

    /**
     * Peek the InternalModel for a given { type, id, lid }.
     *
     * If an InternalModel does not exist, return `null`.
     *
     * @internal
     */
    peek(identifier) {
      return this.modelMapFor(identifier.type).get(identifier.lid);
    }
    getByResource(resource) {
      var normalizedResource = constructResource(resource.type, resource.id, resource.lid);
      return this.lookup(normalizedResource);
    }
    setRecordId(type, id, lid) {
      var resource = {
        type,
        id: null,
        lid
      };
      var identifier = this.identifierCache.getOrCreateRecordIdentifier(resource);
      var internalModel = this.peek(identifier);
      if (internalModel === null) {
        throw new Error(`Cannot set the id ${id} on the record ${type}:${lid} as there is no such record in the cache.`);
      }
      var oldId = internalModel.id;
      var modelName = internalModel.modelName;

      // ID absolutely can't be missing if the oldID is empty (missing Id in response for a new record)
      ( !(!(id === null && oldId === null)) && Ember.assert(`'${modelName}' was saved to the server, but the response does not have an id and your record does not either.`, !(id === null && oldId === null))); // ID absolutely can't be different than oldID if oldID is not null
      // TODO this assertion and restriction may not strictly be needed in the identifiers world
      ( !(!(oldId !== null && id !== oldId)) && Ember.assert(`Cannot update the id for '${modelName}:${lid}' from '${oldId}' to '${id}'.`, !(oldId !== null && id !== oldId))); // ID can be null if oldID is not null (altered ID in response for a record)
      // however, this is more than likely a developer error.
      if (oldId !== null && id === null) {
        ( Ember.warn(`Your ${modelName} record was saved to the server, but the response does not have an id.`, !(oldId !== null && id === null)));
        return;
      }
      var existingInternalModel = this.peekById(modelName, id);
      ( !(Ember.isNone(existingInternalModel) || existingInternalModel === internalModel) && Ember.assert(`'${modelName}' was saved to the server, but the response returned the new id '${id}', which has already been used with another record.'`, Ember.isNone(existingInternalModel) || existingInternalModel === internalModel));
      if (identifier.id === null) {
        this.identifierCache.updateRecordIdentifier(identifier, {
          type,
          id
        });
      }
      internalModel.setId(id);
    }
    peekById(type, id) {
      var identifier = this.identifierCache.peekRecordIdentifier({
        type,
        id
      });
      var internalModel = identifier ? this.modelMapFor(type).get(identifier.lid) : null;
      if (internalModel && internalModel.hasScheduledDestroy()) {
        // unloadRecord is async, if one attempts to unload + then sync create,
        //   we must ensure the unload is complete before starting the create
        //   The push path will take this.lookup()
        //   which will call `cancelDestroy` instead for this unload + then
        //   sync push scenario. Once we have true client-side
        //   delete signaling, we should never call destroySync
        internalModel.destroySync();
        internalModel = null;
      }
      return internalModel;
    }
    build(newResourceInfo) {
      return this._build(newResourceInfo, true);
    }
    _build(resource, isCreate) {
      if (isCreate === void 0) {
        isCreate = false;
      }
      if (isCreate === true && resource.id) {
        var existingInternalModel = this.peekById(resource.type, resource.id);
        ( !(!existingInternalModel) && Ember.assert(`The id ${resource.id} has already been used with another '${resource.type}' record.`, !existingInternalModel));
      }
      var {
        identifierCache
      } = this;
      var identifier;
      if (isCreate === true) {
        identifier = identifierCache.createIdentifierForNewRecord(resource);
      } else {
        identifier = resource;
      }

      // lookupFactory should really return an object that creates
      // instances with the injections applied
      var internalModel = new InternalModel(this.store, identifier);
      this.modelMapFor(resource.type).add(internalModel, identifier.lid);
      return internalModel;
    }
    remove(internalModel) {
      var recordMap = this.modelMapFor(internalModel.modelName);
      var clientId = internalModel.identifier.lid;
      recordMap.remove(internalModel, clientId);
      var {
        identifier
      } = internalModel;
      this.identifierCache.forgetRecordIdentifier(identifier);
    }
    modelMapFor(type) {
      return this._identityMap.retrieve(type);
    }
    _newlyCreatedModelsFor(type) {
      return this._newlyCreated.retrieve(type);
    }
    clear(type) {
      if (type === undefined) {
        this._identityMap.clear();
      } else {
        this.modelMapFor(type).clear();
      }
    }
  }

  function recordForIdentifier(store, identifier) {
    return internalModelFactoryFor(store).lookup(identifier).getRecord();
  }

  /**
    A record array is an array that contains records of a certain modelName. The record
    array materializes records as needed when they are retrieved for the first
    time. You should not create record arrays yourself. Instead, an instance of
    `RecordArray` or its subclasses will be returned by your application's store
    in response to queries.

    @class RecordArray
    @extends ArrayProxy
    @uses Ember.Evented
  */

  var RecordArray = Ember.ArrayProxy.extend(DeprecatedEvented$1, {
    init(args) {
      this._super(args);
      {
        this._getDeprecatedEventedInfo = () => `RecordArray containing ${this.modelName}`;
      }

      /**
        The array of client ids backing the record array. When a
        record is requested from the record array, the record
        for the client id at the same index is materialized, if
        necessary, by the store.
         @property content
        @private
        @type Ember.Array
        */
      this.set('content', this.content || null);

      /**
      The flag to signal a `RecordArray` is finished loading data.
       Example
       ```javascript
      let people = store.peekAll('person');
      people.get('isLoaded'); // true
      ```
       @property isLoaded
      @type Boolean
      */
      this.isLoaded = this.isLoaded || false;
      /**
      The flag to signal a `RecordArray` is currently loading data.
       Example
       ```javascript
      let people = store.peekAll('person');
      people.get('isUpdating'); // false
      people.update();
      people.get('isUpdating'); // true
      ```
       @property isUpdating
      @type Boolean
      */
      this.isUpdating = false;

      /**
      The store that created this record array.
       @property store
      @private
      @type Store
      */
      this.store = this.store || null;
      this._updatingPromise = null;
    },
    replace() {
      throw new Error(`The result of a server query (for all ${this.modelName} types) is immutable. To modify contents, use toArray()`);
    },
    /**
     The modelClass represented by this record array.
      @property type
     @type Model
     */
    type: Ember.computed('modelName', function () {
      if (!this.modelName) {
        return null;
      }
      return this.store.modelFor(this.modelName);
    }).readOnly(),
    /**
      Retrieves an object from the content by index.
       @method objectAtContent
      @private
      @param {Number} index
      @return {Model} record
    */
    objectAtContent(index) {
      var identifier = Ember.get(this, 'content').objectAt(index);
      return identifier ? recordForIdentifier(this.store, identifier) : undefined;
    },
    /**
      Used to get the latest version of all of the records in this array
      from the adapter.
       Example
       ```javascript
      let people = store.peekAll('person');
      people.get('isUpdating'); // false
       people.update().then(function() {
        people.get('isUpdating'); // false
      });
       people.get('isUpdating'); // true
      ```
       @method update
    */
    update() {
      if (Ember.get(this, 'isUpdating')) {
        return this._updatingPromise;
      }
      this.set('isUpdating', true);
      var updatingPromise = this._update().finally(() => {
        this._updatingPromise = null;
        if (this.get('isDestroying') || this.get('isDestroyed')) {
          return;
        }
        this.set('isUpdating', false);
      });
      this._updatingPromise = updatingPromise;
      return updatingPromise;
    },
    /*
      Update this RecordArray and return a promise which resolves once the update
      is finished.
     */
    _update() {
      return this.store.findAll(this.modelName, {
        reload: true
      });
    },
    /**
      Saves all of the records in the `RecordArray`.
       Example
       ```javascript
      let messages = store.peekAll('message');
      messages.forEach(function(message) {
        message.set('hasBeenSeen', true);
      });
      messages.save();
      ```
       @method save
      @return {PromiseArray} promise
    */
    save() {
      var promiseLabel = `DS: RecordArray#save ${this.modelName}`;
      var promise = Ember.RSVP.Promise.all(this.invoke('save'), promiseLabel).then(() => this, null, 'DS: RecordArray#save return RecordArray');
      return PromiseArray.create({
        promise
      });
    },
    /**
      @method _unregisterFromManager
      @private
    */
    _unregisterFromManager() {
      this.manager.unregisterRecordArray(this);
    },
    willDestroy() {
      this._unregisterFromManager();
      this._dissociateFromOwnRecords();
      // TODO: we should not do work during destroy:
      //   * when objects are destroyed, they should simply be left to do
      //   * if logic errors do to this, that logic needs to be more careful during
      //    teardown (ember provides isDestroying/isDestroyed) for this reason
      //   * the exception being: if an dominator has a reference to this object,
      //     and must be informed to release e.g. e.g. removing itself from th
      //     recordArrayMananger
      Ember.set(this, 'content', null);
      Ember.set(this, 'length', 0);
      this._super(...arguments);
    },
    /*
      @method _createSnapshot
      @private
    */
    _createSnapshot(options) {
      // this is private for users, but public for ember-data internals
      return new SnapshotRecordArray(this, this.get('meta'), options);
    },
    /**
      @method _dissociateFromOwnRecords
      @internal
    */
    _dissociateFromOwnRecords() {
      this.get('content').forEach(identifier => {
        var recordArrays = this.manager.getRecordArraysForIdentifier(identifier);
        if (recordArrays) {
          recordArrays.delete(this);
        }
      });
    },
    /**
      Adds identifiers to the `RecordArray` without duplicates
       @method _pushIdentifiers
      @internal
      @param {StableRecordIdentifier[]} identifiers
    */
    _pushIdentifiers(identifiers) {
      Ember.get(this, 'content').pushObjects(identifiers);
    },
    /**
      Removes identifiers from the `RecordArray`.
       @method _removeIdentifiers
      @internal
      @param {StableRecordIdentifier[]} identifiers
    */
    _removeIdentifiers(identifiers) {
      Ember.get(this, 'content').removeObjects(identifiers);
    },
    /**
      @method _takeSnapshot
      @internal
    */
    _takeSnapshot() {
      return Ember.get(this, 'content').map(identifier => internalModelFactoryFor(this.store).lookup(identifier).createSnapshot());
    }
  });

  /**
    @module @ember-data/store
  */

  /**
    Represents an ordered list of records whose order and membership is
    determined by the adapter. For example, a query sent to the adapter
    may trigger a search on the server, whose results would be loaded
    into an instance of the `AdapterPopulatedRecordArray`.

    ---

    If you want to update the array and get the latest records from the
    adapter, you can invoke [`update()`](AdapterPopulatedRecordArray/methods/update?anchor=update):

    Example

    ```javascript
    // GET /users?isAdmin=true
    store.query('user', { isAdmin: true }).then(function(admins) {

      admins.then(function() {
        console.log(admins.get("length")); // 42
      });

      // somewhere later in the app code, when new admins have been created
      // in the meantime
      //
      // GET /users?isAdmin=true
      admins.update().then(function() {
        admins.get('isUpdating'); // false
        console.log(admins.get("length")); // 123
      });

      admins.get('isUpdating'); // true
    }
    ```

    @class AdapterPopulatedRecordArray
    @extends RecordArray
  */
  var AdapterPopulatedRecordArray = RecordArray.extend({
    init() {
      this.set('content', this.get('content') || Ember.A());
      this._super(...arguments);
      this.query = this.query || null;
      this.links = this.links || null;
      {
        this._getDeprecatedEventedInfo = () => `AdapterPopulatedRecordArray containing ${this.modelName} for query: ${this.query}`;
      }
    },
    replace() {
      throw new Error(`The result of a server query (on ${this.modelName}) is immutable.`);
    },
    _update() {
      var store = Ember.get(this, 'store');
      var query = Ember.get(this, 'query');
      return store._query(this.modelName, query, this);
    },
    _setObjects(identifiersOrInternalModels, payload) {
      // TODO: initial load should not cause change events at all, only
      // subsequent. This requires changing the public api of adapter.query, but
      // hopefully we can do that soon.
      this.get('content').setObjects(identifiersOrInternalModels);
      this.setProperties({
        isLoaded: true,
        isUpdating: false,
        meta: Ember.assign({}, payload.meta),
        links: Ember.assign({}, payload.links)
      });
      this.manager._associateWithRecordArray(identifiersOrInternalModels, this);
      {
        var _hasDidLoad =  this._has('didLoad') ;
        if (_hasDidLoad) {
          // TODO: should triggering didLoad event be the last action of the runLoop?
          Ember.run.once(this, 'trigger', 'didLoad');
        }
      }
    },
    /**
      @method _setIdentifiers
      @param {StableRecordIdentifier[]} identifiers
      @param {Object} payload normalized payload
      @internal
    */
    _setIdentifiers(identifiers, payload) {
      this._setObjects(identifiers, payload);
    }
  });

  var RecordArraysCache = new WeakMap();
  var emberRun$1 = Ember.run.backburner;
  function recordArraysForIdentifier(identifierOrInternalModel) {
    if (RecordArraysCache.has(identifierOrInternalModel)) {
      // return existing Set if exists
      return RecordArraysCache.get(identifierOrInternalModel);
    }

    // returns workable Set instance
    RecordArraysCache.set(identifierOrInternalModel, new Set());
    return RecordArraysCache.get(identifierOrInternalModel);
  }
  var pendingForIdentifier = new Set([]);
  var IMDematerializing = new WeakMap();
  var getIdentifier = function getIdentifier(identifierOrInternalModel) {
    var i = identifierOrInternalModel;
    if ( !isStableIdentifier(identifierOrInternalModel)) {
      // identifier may actually be an internalModel
      // but during materialization we will get an identifier that
      // has already been removed from the identifiers cache yet
      // so it will not behave as if stable. This is a bug we should fix.
      i = identifierOrInternalModel.identifier || i;
    }
    return i;
  };

  // REMOVE_RECORD_ARRAY_MANAGER_LEGACY_COMPAT only
  var peekIMCache = function peekIMCache(cache, identifier) {
    {
      var im = IMDematerializing.get(identifier);
      if (im === undefined) {
        // if not im._isDematerializing
        im = cache.peek(identifier);
      }
      return im;
    }
  };
  var shouldIncludeInRecordArrays = function shouldIncludeInRecordArrays(store, identifier) {
    var cache = internalModelFactoryFor(store);
    var internalModel = cache.peek(identifier);
    if (internalModel === null) {
      return false;
    }
    return !internalModel.isHiddenFromRecordArrays();
  };

  /**
    @class RecordArrayManager
    @private
  */
  class RecordArrayManager {
    constructor(options) {
      this.store = options.store;
      this.isDestroying = false;
      this.isDestroyed = false;
      this._liveRecordArrays = Object.create(null);
      this._pendingIdentifiers = Object.create(null);
      this._adapterPopulatedRecordArrays = [];
    }

    /**
     * @method getRecordArraysForIdentifier
     * @public
     * @param {StableIdentifier} param
     * @return {RecordArray} array
     */
    getRecordArraysForIdentifier(identifier) {
      return recordArraysForIdentifier(identifier);
    }
    _flushPendingIdentifiersForModelName(modelName, identifiers) {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      var modelsToRemove = [];
      for (var j = 0; j < identifiers.length; j++) {
        var i = identifiers[j];
        // mark identifiers, so they can once again be processed by the
        // recordArrayManager
        pendingForIdentifier.delete(i);
        // build up a set of models to ensure we have purged correctly;
        var isIncluded = shouldIncludeInRecordArrays(this.store, i);
        if (!isIncluded) {
          modelsToRemove.push(i);
        }
      }
      var array = this._liveRecordArrays[modelName];
      if (array) {
        // TODO: skip if it only changed
        // process liveRecordArrays
        updateLiveRecordArray(this.store, array, identifiers);
      }

      // process adapterPopulatedRecordArrays
      if (modelsToRemove.length > 0) {
        removeFromAdapterPopulatedRecordArrays(this.store, modelsToRemove);
      }
    }
    _flush() {
      var pending = this._pendingIdentifiers;
      this._pendingIdentifiers = Object.create(null);
      for (var modelName in pending) {
        this._flushPendingIdentifiersForModelName(modelName, pending[modelName]);
      }
    }
    _syncLiveRecordArray(array, modelName) {
      ( !(typeof modelName === 'string') && Ember.assert(`recordArrayManger.syncLiveRecordArray expects modelName not modelClass as the second param`, typeof modelName === 'string'));
      var pending = this._pendingIdentifiers[modelName];
      var hasPendingChanges = Array.isArray(pending);
      var hasNoPotentialDeletions = !hasPendingChanges || pending.length === 0;
      var map = internalModelFactoryFor(this.store).modelMapFor(modelName);
      var hasNoInsertionsOrRemovals = Ember.get(map, 'length') === Ember.get(array, 'length');

      /*
        Ideally the recordArrayManager has knowledge of the changes to be applied to
        liveRecordArrays, and is capable of strategically flushing those changes and applying
        small diffs if desired.  However, until we've refactored recordArrayManager, this dirty
        check prevents us from unnecessarily wiping out live record arrays returned by peekAll.
        */
      if (hasNoPotentialDeletions && hasNoInsertionsOrRemovals) {
        return;
      }
      if (hasPendingChanges) {
        this._flushPendingIdentifiersForModelName(modelName, pending);
        delete this._pendingIdentifiers[modelName];
      }
      var identifiers = this._visibleIdentifiersByType(modelName);
      var modelsToAdd = [];
      for (var i = 0; i < identifiers.length; i++) {
        var identifier = identifiers[i];
        var recordArrays = recordArraysForIdentifier(identifier);
        if (recordArrays.has(array) === false) {
          recordArrays.add(array);
          modelsToAdd.push(identifier);
        }
      }
      if (modelsToAdd.length) {
        array._pushIdentifiers(modelsToAdd);
      }
    }
    _didUpdateAll(modelName) {
      var recordArray = this._liveRecordArrays[modelName];
      if (recordArray) {
        Ember.set(recordArray, 'isUpdating', false);
      }
    }

    /**
      Get the `RecordArray` for a modelName, which contains all loaded records of
      given modelName.
       @method liveRecordArrayFor
      @param {String} modelName
      @return {RecordArray}
    */
    liveRecordArrayFor(modelName) {
      ( !(typeof modelName === 'string') && Ember.assert(`recordArrayManger.liveRecordArrayFor expects modelName not modelClass as the param`, typeof modelName === 'string'));
      var array = this._liveRecordArrays[modelName];
      if (array) {
        // if the array already exists, synchronize
        this._syncLiveRecordArray(array, modelName);
      } else {
        // if the array is being newly created merely create it with its initial
        // content already set. This prevents unneeded change events.
        var identifiers = this._visibleIdentifiersByType(modelName);
        array = this.createRecordArray(modelName, identifiers);
        this._liveRecordArrays[modelName] = array;
      }
      return array;
    }
    _visibleIdentifiersByType(modelName) {
      var all = internalModelFactoryFor(this.store).modelMapFor(modelName).recordIdentifiers;
      var visible = [];
      for (var i = 0; i < all.length; i++) {
        var identifier = all[i];
        var shouldInclude = shouldIncludeInRecordArrays(this.store, identifier);
        if (shouldInclude) {
          visible.push(identifier);
        }
      }
      return visible;
    }

    /**
      Create a `RecordArray` for a modelName.
       @method createRecordArray
      @param {String} modelName
      @param {Array} [identifiers]
      @return {RecordArray}
    */
    createRecordArray(modelName, identifiers) {
      ( !(typeof modelName === 'string') && Ember.assert(`recordArrayManger.createRecordArray expects modelName not modelClass as the param`, typeof modelName === 'string'));
      var array = RecordArray.create({
        modelName,
        content: Ember.A(identifiers || []),
        store: this.store,
        isLoaded: true,
        manager: this
      });
      if (Array.isArray(identifiers)) {
        this._associateWithRecordArray(identifiers, array);
      }
      return array;
    }

    /**
      Create a `AdapterPopulatedRecordArray` for a modelName with given query.
       @method createAdapterPopulatedRecordArray
      @param {String} modelName
      @param {Object} query
      @return {AdapterPopulatedRecordArray}
    */
    createAdapterPopulatedRecordArray(modelName, query, identifiers, payload) {
      ( !(typeof modelName === 'string') && Ember.assert(`recordArrayManger.createAdapterPopulatedRecordArray expects modelName not modelClass as the first param, received ${modelName}`, typeof modelName === 'string'));
      var array;
      if (Array.isArray(identifiers)) {
        array = AdapterPopulatedRecordArray.create({
          modelName,
          query: query,
          content: Ember.A(identifiers),
          store: this.store,
          manager: this,
          isLoaded: true,
          isUpdating: false,
          meta: Ember.assign({}, payload.meta),
          links: Ember.assign({}, payload.links)
        });
        this._associateWithRecordArray(identifiers, array);
      } else {
        array = AdapterPopulatedRecordArray.create({
          modelName,
          query: query,
          content: Ember.A(),
          store: this.store,
          manager: this
        });
      }
      this._adapterPopulatedRecordArrays.push(array);
      return array;
    }

    /**
      Unregister a RecordArray.
      So manager will not update this array.
       @method unregisterRecordArray
      @param {RecordArray} array
    */
    unregisterRecordArray(array) {
      var modelName = array.modelName;

      // remove from adapter populated record array
      var removedFromAdapterPopulated = removeFromArray(this._adapterPopulatedRecordArrays, array);
      if (!removedFromAdapterPopulated) {
        var liveRecordArrayForType = this._liveRecordArrays[modelName];
        // unregister live record array
        if (liveRecordArrayForType) {
          if (array === liveRecordArrayForType) {
            delete this._liveRecordArrays[modelName];
          }
        }
      }
    }

    /**
     * @method _associateWithRecordArray
     * @private
     * @param {StableIdentifier} identifiers
     * @param {RecordArray} array
     */
    _associateWithRecordArray(identifiers, array) {
      for (var i = 0, l = identifiers.length; i < l; i++) {
        var identifier = identifiers[i];
        identifier = getIdentifier(identifier);
        var recordArrays = this.getRecordArraysForIdentifier(identifier);
        recordArrays.add(array);
      }
    }

    /**
      @method recordDidChange
      @internal
    */
    recordDidChange(identifier) {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      var modelName = identifier.type;
      identifier = getIdentifier(identifier);
      {
        var cache = internalModelFactoryFor(this.store);
        var im = peekIMCache(cache, identifier);
        if (im && im._isDematerializing) {
          IMDematerializing.set(identifier, im);
        }
      }
      if (pendingForIdentifier.has(identifier)) {
        return;
      }
      pendingForIdentifier.add(identifier);
      var pending = this._pendingIdentifiers;
      var models = pending[modelName] = pending[modelName] || [];
      if (models.push(identifier) !== 1) {
        return;
      }
      emberRun$1.schedule('actions', this, this._flush);
    }
    willDestroy() {
      Object.keys(this._liveRecordArrays).forEach(modelName => this._liveRecordArrays[modelName].destroy());
      this._adapterPopulatedRecordArrays.forEach(entry => entry.destroy());
      this.isDestroyed = true;
    }
    destroy() {
      this.isDestroying = true;
      emberRun$1.schedule('actions', this, this.willDestroy);
    }
  }
  var removeFromArray = function removeFromArray(array, item) {
    var index = array.indexOf(item);
    if (index !== -1) {
      array.splice(index, 1);
      return true;
    }
    return false;
  };
  var updateLiveRecordArray = function updateLiveRecordArray(store, recordArray, identifiers) {
    var identifiersToAdd = [];
    var identifiersToRemove = [];
    for (var i = 0; i < identifiers.length; i++) {
      var identifier = identifiers[i];
      var shouldInclude = shouldIncludeInRecordArrays(store, identifier);
      var recordArrays = recordArraysForIdentifier(identifier);
      if (shouldInclude) {
        if (!recordArrays.has(recordArray)) {
          identifiersToAdd.push(identifier);
          recordArrays.add(recordArray);
        }
      }
      if (!shouldInclude) {
        identifiersToRemove.push(identifier);
        recordArrays.delete(recordArray);
      }
    }
    if (identifiersToAdd.length > 0) {
      pushIdentifiers(recordArray, identifiersToAdd, internalModelFactoryFor(store));
    }
    if (identifiersToRemove.length > 0) {
      removeIdentifiers(recordArray, identifiersToRemove, internalModelFactoryFor(store));
    }
  };
  var pushIdentifiers = function pushIdentifiers(recordArray, identifiers, cache) {
    if ( !recordArray._pushIdentifiers) {
      // deprecate('not allowed to use this intimate api any more');
      recordArray._pushInternalModels(identifiers.map(i => peekIMCache(cache, i)));
    } else {
      recordArray._pushIdentifiers(identifiers);
    }
  };
  var removeIdentifiers = function removeIdentifiers(recordArray, identifiers, cache) {
    if ( !recordArray._removeIdentifiers) {
      // deprecate('not allowed to use this intimate api any more');
      recordArray._removeInternalModels(identifiers.map(i => peekIMCache(cache, i)));
    } else {
      recordArray._removeIdentifiers(identifiers);
    }
  };
  var removeFromAdapterPopulatedRecordArrays = function removeFromAdapterPopulatedRecordArrays(store, identifiers) {
    for (var i = 0; i < identifiers.length; i++) {
      removeFromAll(store, identifiers[i]);
    }
  };
  var removeFromAll = function removeFromAll(store, identifier) {
    identifier = getIdentifier(identifier);
    var recordArrays = recordArraysForIdentifier(identifier);
    var cache = internalModelFactoryFor(store);
    recordArrays.forEach(function (recordArray) {
      removeIdentifiers(recordArray, [identifier], cache);
    });
    recordArrays.clear();
  };

  /**
    @module @ember-data/store
  */

  function isResourceIdentiferWithRelatedLinks(value) {
    return value && value.links && value.links.related;
  }
  var REFERENCE_CACHE = new WeakMap();
  function internalModelForReference(reference) {
    return internalModelFactoryFor(reference.store).peek(REFERENCE_CACHE.get(reference));
  }

  /**
    This is the baseClass for the different References
    like RecordReference/HasManyReference/BelongsToReference

   @class Reference
   */

  class Reference {
    constructor(store, identifier) {
      this.store = store;
      REFERENCE_CACHE.set(this, identifier);
    }
    get recordData() {
      return this.store.recordDataFor(REFERENCE_CACHE.get(this), false);
    }
    _resource() {}

    /**
     This returns a string that represents how the reference will be
     looked up when it is loaded. If the relationship has a link it will
     use the "link" otherwise it defaults to "id".
      Example
      ```app/models/post.js
     import Model, { hasMany } from '@ember-data/model';
      export default Model.extend({
       comments: hasMany({ async: true })
     });
     ```
      ```javascript
     let post = store.push({
       data: {
         type: 'post',
         id: 1,
         relationships: {
           comments: {
             data: [{ type: 'comment', id: 1 }]
           }
         }
       }
     });
      let commentsRef = post.hasMany('comments');
      // get the identifier of the reference
     if (commentsRef.remoteType() === "ids") {
       let ids = commentsRef.ids();
     } else if (commentsRef.remoteType() === "link") {
       let link = commentsRef.link();
     }
     ```
      @method remoteType
     @return {String} The name of the remote type. This should either be "link" or "ids"
     */
    remoteType() {
      var value = this._resource();
      if (isResourceIdentiferWithRelatedLinks(value)) {
        return 'link';
      }
      return 'id';
    }

    /**
     The link Ember Data will use to fetch or reload this belongs-to
     relationship.
      Example
      ```javascript
     // models/blog.js
     import Model, { belongsTo } from '@ember-data/model';
     export default Model.extend({
        user: belongsTo({ async: true })
      });
      let blog = store.push({
        data: {
          type: 'blog',
          id: 1,
          relationships: {
            user: {
              links: {
                related: '/articles/1/author'
              }
            }
          }
        }
      });
     let userRef = blog.belongsTo('user');
      // get the identifier of the reference
     if (userRef.remoteType() === "link") {
        let link = userRef.link();
      }
     ```
      @method link
     @return {String} The link Ember Data will use to fetch or reload this belongs-to relationship.
     */
    link() {
      var link;
      var resource = this._resource();
      if (isResourceIdentiferWithRelatedLinks(resource)) {
        if (resource.links) {
          link = resource.links.related;
          link = !link || typeof link === 'string' ? link : link.href;
        }
      }
      return link || null;
    }
    links() {
      var resource = this._resource();
      return resource && resource.links ? resource.links : null;
    }

    /**
     The meta data for the belongs-to relationship.
      Example
      ```javascript
     // models/blog.js
     import Model, { belongsTo } from '@ember-data/model';
     export default Model.extend({
        user: belongsTo({ async: true })
      });
      let blog = store.push({
        data: {
          type: 'blog',
          id: 1,
          relationships: {
            user: {
              links: {
                related: {
                  href: '/articles/1/author'
                },
                meta: {
                  lastUpdated: 1458014400000
                }
              }
            }
          }
        }
      });
      let userRef = blog.belongsTo('user');
      userRef.meta() // { lastUpdated: 1458014400000 }
     ```
      @method meta
     @return {Object} The meta information for the belongs-to relationship.
     */
    meta() {
      var meta = null;
      var resource = this._resource();
      if (resource && resource.meta && typeof resource.meta === 'object') {
        meta = resource.meta;
      }
      return meta;
    }
  }
  {
    Object.defineProperty(Reference.prototype, 'internalModel', {
      get() {
        ( Ember.deprecate('Accessing the internalModel property of Reference is deprecated', false, {
          id: 'ember-data:reference-internal-model',
          until: '3.21'
        }));
        return REFERENCE_CACHE.get(this);
      }
    });
  }

  /**
    @module @ember-data/store
  */

  /**
   A `BelongsToReference` is a low-level API that allows users and
   addon authors to perform meta-operations on a belongs-to
   relationship.

   @class BelongsToReference
   @extends Reference
   */
  class BelongsToReference extends Reference {
    constructor(store, parentIMOrIdentifier, belongsToRelationship, key) {
      super(store, parentIMOrIdentifier);
      this.key = key;
      this.belongsToRelationship = belongsToRelationship;
      this.type = belongsToRelationship.relationshipMeta.type;
      this.parent = internalModelFactoryFor(store).peek(parentIMOrIdentifier).recordReference;
      this.parentIdentifier = parentIMOrIdentifier;

      // TODO inverse
    }

    /**
     The `id` of the record that this reference refers to. Together, the
     `type()` and `id()` methods form a composite key for the identity
     map. This can be used to access the id of an async relationship
     without triggering a fetch that would normally happen if you
     attempted to use `record.get('relationship.id')`.
      Example
      ```javascript
     // models/blog.js
     import Model, { belongsTo } from '@ember-data/model';
      export default class BlogModel extends Model {
      @belongsTo({ async: true }) user;
     }
      let blog = store.push({
        data: {
          type: 'blog',
          id: 1,
          relationships: {
            user: {
              data: { type: 'user', id: 1 }
            }
          }
        }
      });
     let userRef = blog.belongsTo('user');
      // get the identifier of the reference
     if (userRef.remoteType() === "id") {
        let id = userRef.id();
      }
     ```
      @method id
     @return {String} The id of the record in this belongsTo relationship.
     */
    id() {
      var id = null;
      var resource = this._resource();
      if (resource && resource.data) {
        id = resource.data.id;
      }
      return id;
    }
    _resource() {
      return this.recordData.getBelongsTo(this.key);
    }

    /**
     `push` can be used to update the data in the relationship and Ember
     Data will treat the new data as the canonical value of this
     relationship on the backend.
      Example
      ```app/models/blog.js
     import Model, { belongsTo } from '@ember-data/model';
      export default class BlogModel extends Model {
        @belongsTo({ async: true }) user;
      }
      let blog = store.push({
        data: {
          type: 'blog',
          id: 1,
          relationships: {
            user: {
              data: { type: 'user', id: 1 }
            }
          }
        }
      });
     let userRef = blog.belongsTo('user');
      // provide data for reference
     userRef.push({
        data: {
          type: 'user',
          id: 1,
          attributes: {
            username: "@user"
          }
        }
      }).then(function(user) {
        userRef.value() === user;
      });
     ```
      @method push
     @param {Object|Promise} objectOrPromise a promise that resolves to a JSONAPI document object describing the new value of this relationship.
     @return {Promise<record>} A promise that resolves with the new value in this belongs-to relationship.
     */
    push(objectOrPromise) {
      // TODO deprecate thenable support
      return Ember.RSVP.resolve(objectOrPromise).then(data => {
        var record;
        if ( peekRecordIdentifier(data)) {
          ( Ember.deprecate('Pushing a record into a BelongsToReference is deprecated', false, {
            id: 'ember-data:belongs-to-reference-push-record',
            until: '4.0'
          }));
          record = data;
        } else {
          record = this.store.push(data);
        }
        Debug.assertPolymorphicType(internalModelForReference(this), this.belongsToRelationship.relationshipMeta, record._internalModel, this.store);

        //TODO Igor cleanup, maybe move to relationship push
        this.belongsToRelationship.setCanonicalRecordData(recordDataFor(record));
        return record;
      });
    }

    /**
     `value()` synchronously returns the current value of the belongs-to
     relationship. Unlike `record.get('relationshipName')`, calling
     `value()` on a reference does not trigger a fetch if the async
     relationship is not yet loaded. If the relationship is not loaded
     it will always return `null`.
      Example
      ```javascript
     // models/blog.js
     import Model, { belongsTo } from '@ember-data/model';
      export default class BlogModel extends Model {
       @belongsTo({ async: true }) user;
     }
      let blog = store.push({
        data: {
          type: 'blog',
          id: 1,
          relationships: {
            user: {
              data: { type: 'user', id: 1 }
            }
          }
        }
      });
     let userRef = blog.belongsTo('user');
      userRef.value(); // null
      // provide data for reference
     userRef.push({
        data: {
          type: 'user',
          id: 1,
          attributes: {
            username: "@user"
          }
        }
      }).then(function(user) {
        userRef.value(); // user
      });
     ```
      @method value
     @return {Model} the record in this relationship
     */
    value() {
      var resource = this._resource();
      if (resource && resource.data) {
        var inverseInternalModel = this.store._internalModelForResource(resource.data);
        if (inverseInternalModel && inverseInternalModel.isLoaded()) {
          return inverseInternalModel.getRecord();
        }
      }
      return null;
    }

    /**
     Loads a record in a belongs-to relationship if it is not already
     loaded. If the relationship is already loaded this method does not
     trigger a new load.
      Example
      ```javascript
     // models/blog.js
     import Model, { belongsTo } from '@ember-data/model';
      export default class BlogModel extends Model {
       @belongsTo({ async: true }) user;
     }
      let blog = store.push({
        data: {
          type: 'blog',
          id: 1,
          relationships: {
            user: {
              data: { type: 'user', id: 1 }
            }
          }
        }
      });
     let userRef = blog.belongsTo('user');
      userRef.value(); // null
      userRef.load().then(function(user) {
        userRef.value() === user
      });
     ```
      You may also pass in an options object whose properties will be
     fed forward. This enables you to pass `adapterOptions` into the
     request given to the adapter via the reference.
      Example
      ```javascript
     userRef.load({ adapterOptions: { isPrivate: true } }).then(function(user) {
       userRef.value() === user;
     });
     ```
     ```app/adapters/user.js
     import Adapter from '@ember-data/adapter';
      export default class UserAdapter extends Adapter {
       findRecord(store, type, id, snapshot) {
         // In the adapter you will have access to adapterOptions.
         let adapterOptions = snapshot.adapterOptions;
       }
     });
     ```
      @method load
     @param {Object} options the options to pass in.
     @return {Promise} a promise that resolves with the record in this belongs-to relationship.
     */
    load(options) {
      var parentInternalModel = internalModelFactoryFor(this.store).peek(this.parentIdentifier);
      return parentInternalModel.getBelongsTo(this.key, options);
    }

    /**
     Triggers a reload of the value in this relationship. If the
     remoteType is `"link"` Ember Data will use the relationship link to
     reload the relationship. Otherwise it will reload the record by its
     id.
      Example
      ```javascript
     // models/blog.js
     import Model, { belongsTo } from '@ember-data/model';
      export default class BlogModel extends Model {
       @belongsTo({ async: true }) user;
     }
      let blog = store.push({
        data: {
          type: 'blog',
          id: 1,
          relationships: {
            user: {
              data: { type: 'user', id: 1 }
            }
          }
        }
      });
     let userRef = blog.belongsTo('user');
      userRef.reload().then(function(user) {
        userRef.value() === user
      });
     ```
      You may also pass in an options object whose properties will be
     fed forward. This enables you to pass `adapterOptions` into the
     request given to the adapter via the reference. A full example
     can be found in the `load` method.
      Example
      ```javascript
     userRef.reload({ adapterOptions: { isPrivate: true } })
     ```
      @method reload
     @param {Object} options the options to pass in.
     @return {Promise} a promise that resolves with the record in this belongs-to relationship after the reload has completed.
     */
    reload(options) {
      var parentInternalModel = internalModelFactoryFor(this.store).peek(this.parentIdentifier);
      return parentInternalModel.reloadBelongsTo(this.key, options).then(internalModel => {
        return this.value();
      });
    }
  }

  /**
    @module @ember-data/store
  */

  /**
   A `HasManyReference` is a low-level API that allows users and addon
   authors to perform meta-operations on a has-many relationship.

   @class HasManyReference
   @extends Reference
   */
  class HasManyReference extends Reference {
    constructor(store, parentIMOrIdentifier, hasManyRelationship, key) {
      super(store, parentIMOrIdentifier);
      this.key = key;
      this.hasManyRelationship = hasManyRelationship;
      this.type = hasManyRelationship.relationshipMeta.type;
      this.parent = internalModelFactoryFor(store).peek(parentIMOrIdentifier).recordReference;

      // TODO inverse
    }

    _resource() {
      return this.recordData.getHasMany(this.key);
    }

    /**
     This returns a string that represents how the reference will be
     looked up when it is loaded. If the relationship has a link it will
     use the "link" otherwise it defaults to "id".
      Example
      ```app/models/post.js
     import Model, { hasMany } from '@ember-data/model';
      export default class PostModel extends Model {
       @hasMany({ async: true }) comments;
     }
     ```
      ```javascript
     let post = store.push({
       data: {
         type: 'post',
         id: 1,
         relationships: {
           comments: {
             data: [{ type: 'comment', id: 1 }]
           }
         }
       }
     });
      let commentsRef = post.hasMany('comments');
      // get the identifier of the reference
     if (commentsRef.remoteType() === "ids") {
       let ids = commentsRef.ids();
     } else if (commentsRef.remoteType() === "link") {
       let link = commentsRef.link();
     }
     ```
      @method remoteType
     @return {String} The name of the remote type. This should either be `link` or `ids`
     */
    remoteType() {
      var value = this._resource();
      if (value && value.links && value.links.related) {
        return 'link';
      }
      return 'ids';
    }

    /**
     `ids()` returns an array of the record IDs in this relationship.
      Example
      ```app/models/post.js
     import Model, { hasMany } from '@ember-data/model';
      export default class PostModel extends Model {
       @hasMany({ async: true }) comments;
     }
     ```
      ```javascript
     let post = store.push({
       data: {
         type: 'post',
         id: 1,
         relationships: {
           comments: {
             data: [{ type: 'comment', id: 1 }]
           }
         }
       }
     });
      let commentsRef = post.hasMany('comments');
      commentsRef.ids(); // ['1']
     ```
      @method ids
     @return {Array} The ids in this has-many relationship
     */
    ids() {
      var resource = this._resource();
      var ids = [];
      if (resource.data) {
        ids = resource.data.map(data => data.id);
      }
      return ids;
    }

    /**
     `push` can be used to update the data in the relationship and Ember
     Data will treat the new data as the canonical value of this
     relationship on the backend.
      Example
      ```app/models/post.js
     import Model, { hasMany } from '@ember-data/model';
      export default class PostModel extends Model {
       @hasMany({ async: true }) comments;
     }
     ```
      ```
     let post = store.push({
       data: {
         type: 'post',
         id: 1,
         relationships: {
           comments: {
             data: [{ type: 'comment', id: 1 }]
           }
         }
       }
     });
      let commentsRef = post.hasMany('comments');
      commentsRef.ids(); // ['1']
      commentsRef.push([
     [{ type: 'comment', id: 2 }],
     [{ type: 'comment', id: 3 }],
     ])
      commentsRef.ids(); // ['2', '3']
     ```
      @method push
     @param {Array|Promise} objectOrPromise a promise that resolves to a JSONAPI document object describing the new value of this relationship.
     @return {ManyArray}
     */
    push(objectOrPromise) {
      return Ember.RSVP.resolve(objectOrPromise).then(payload => {
        var array = payload;
        if (typeof payload === 'object' && payload.data) {
          array = payload.data;
        }
        var internalModel = internalModelForReference(this);
        var internalModels = array.map(obj => {
          var record = this.store.push(obj);
          {
            var relationshipMeta = this.hasManyRelationship.relationshipMeta;
            Debug.assertPolymorphicType(internalModel, relationshipMeta, record._internalModel, this.store);
          }
          return recordDataFor(record);
        });
        this.hasManyRelationship.computeChanges(internalModels);
        return internalModel.getHasMany(this.hasManyRelationship.key);
        // TODO IGOR it seems wrong that we were returning the many array here
        //return this.hasManyRelationship.manyArray;
      });
    }

    _isLoaded() {
      var hasRelationshipDataProperty = this.hasManyRelationship.hasAnyRelationshipData;
      if (!hasRelationshipDataProperty) {
        return false;
      }
      var members = this.hasManyRelationship.members.toArray();

      //TODO Igor cleanup
      return members.every(recordData => {
        var internalModel = this.store._internalModelForResource(recordData.getResourceIdentifier());
        return internalModel.isLoaded() === true;
      });
    }

    /**
     `value()` synchronously returns the current value of the has-many
     relationship. Unlike `record.get('relationshipName')`, calling
     `value()` on a reference does not trigger a fetch if the async
     relationship is not yet loaded. If the relationship is not loaded
     it will always return `null`.
      Example
      ```app/models/post.js
     import Model, { hasMany } from '@ember-data/model';
      export default class PostModel extends Model {
       @hasMany({ async: true }) comments;
     }
     ```
      ```javascript
     let post = store.push({
       data: {
         type: 'post',
         id: 1,
         relationships: {
           comments: {
             data: [{ type: 'comment', id: 1 }]
           }
         }
       }
     });
      let commentsRef = post.hasMany('comments');
      post.get('comments').then(function(comments) {
       commentsRef.value() === comments
     })
     ```
      @method value
     @return {ManyArray}
     */
    value() {
      var internalModel = internalModelForReference(this);
      if (this._isLoaded()) {
        return internalModel.getManyArray(this.key);
      }
      return null;
    }

    /**
     Loads the relationship if it is not already loaded.  If the
     relationship is already loaded this method does not trigger a new
     load. This causes a request to the specified
     relationship link or reloads all items currently in the relationship.
      Example
      ```app/models/post.js
     import Model, { hasMany } from '@ember-data/model';
      export default class PostModel extends Model {
       @hasMany({ async: true }) comments;
     }
     ```
      ```javascript
     let post = store.push({
       data: {
         type: 'post',
         id: 1,
         relationships: {
           comments: {
             data: [{ type: 'comment', id: 1 }]
           }
         }
       }
     });
      let commentsRef = post.hasMany('comments');
      commentsRef.load().then(function(comments) {
       //...
     });
     ```
      You may also pass in an options object whose properties will be
     fed forward. This enables you to pass `adapterOptions` into the
     request given to the adapter via the reference.
      Example
      ```javascript
     commentsRef.load({ adapterOptions: { isPrivate: true } })
       .then(function(comments) {
         //...
       });
     ```
      ```app/adapters/comment.js
     export default ApplicationAdapter.extend({
       findMany(store, type, id, snapshots) {
         // In the adapter you will have access to adapterOptions.
         let adapterOptions = snapshots[0].adapterOptions;
       }
     });
     ```
      @method load
     @param {Object} options the options to pass in.
     @return {Promise} a promise that resolves with the ManyArray in
     this has-many relationship.
     */
    load(options) {
      var internalModel = internalModelForReference(this);
      return internalModel.getHasMany(this.key, options);
    }

    /**
     Reloads this has-many relationship. This causes a request to the specified
     relationship link or reloads all items currently in the relationship.
      Example
      ```app/models/post.js
     import Model, { hasMany } from '@ember-data/model';
      export default class PostModel extends Model {
       @hasMany({ async: true }) comments;
     }
     ```
      ```javascript
     let post = store.push({
       data: {
         type: 'post',
         id: 1,
         relationships: {
           comments: {
             data: [{ type: 'comment', id: 1 }]
           }
         }
       }
     });
      let commentsRef = post.hasMany('comments');
      commentsRef.reload().then(function(comments) {
       //...
     });
     ```
      You may also pass in an options object whose properties will be
     fed forward. This enables you to pass `adapterOptions` into the
     request given to the adapter via the reference. A full example
     can be found in the `load` method.
      Example
      ```javascript
     commentsRef.reload({ adapterOptions: { isPrivate: true } })
     ```
      @method reload
     @param {Object} options the options to pass in.
     @return {Promise} a promise that resolves with the ManyArray in this has-many relationship.
     */
    reload(options) {
      var internalModel = internalModelForReference(this);
      return internalModel.reloadHasMany(this.key, options);
    }
  }

  /**
    @module @ember-data/store
  */
  /**
     A `RecordReference` is a low-level API that allows users and
     addon authors to perform meta-operations on a record.

     @class RecordReference
     @extends Reference
  */
  class RecordReference extends Reference {
    get type() {
      return this.identifier().type;
    }
    get _id() {
      var identifier = this.identifier();
      if (identifier) {
        return identifier.id;
      }
      return null;
    }

    /**
       The `id` of the record that this reference refers to.
        Together, the `type` and `id` properties form a composite key for
       the identity map.
        Example
        ```javascript
       let userRef = store.getReference('user', 1);
        userRef.id(); // '1'
       ```
        @method id
       @return {String} The id of the record.
    */
    id() {
      return this._id;
    }

    /**
       The `identifier` of the record that this reference refers to.
        Together, the `type` and `id` properties form a composite key for
       the identity map.
        Example
        ```javascript
       let userRef = store.getReference('user', 1);
        userRef.identifier(); // '1'
       ```
        @method identifier
       @return {String} The identifier of the record.
    */
    identifier() {
      return REFERENCE_CACHE.get(this);
    }

    /**
       How the reference will be looked up when it is loaded. Currently
       this always returns `identity` to signify that a record will be
       loaded by its `type` and `id`.
        Example
        ```javascript
       const userRef = store.getReference('user', 1);
        userRef.remoteType(); // 'identity'
       ```
        @method remoteType
       @return {String} 'identity'
    */
    remoteType() {
      return 'identity';
    }

    /**
      This API allows you to provide a reference with new data. The
      simplest usage of this API is similar to `store.push`: you provide a
      normalized hash of data and the object represented by the reference
      will update.
       If you pass a promise to `push`, Ember Data will not ask the adapter
      for the data if another attempt to fetch it is made in the
      interim. When the promise resolves, the underlying object is updated
      with the new data, and the promise returned by *this function* is resolved
      with that object.
       For example, `recordReference.push(promise)` will be resolved with a
      record.
        Example
        ```javascript
       let userRef = store.getReference('user', 1);
        // provide data for reference
       userRef.push({
         data: {
           id: "1",
           type: "user",
           attributes: {
             username: "@user"
           }
         }
       }).then(function(user) {
         userRef.value() === user;
       });
       ```
       @method push
      @param objectOrPromise a JSON:API ResourceDocument or a promise resolving to one
      @return a promise for the value (record or relationship)
    */
    push(objectOrPromise) {
      return Ember.RSVP.resolve(objectOrPromise).then(data => {
        return this.store.push(data);
      });
    }

    /**
      If the entity referred to by the reference is already loaded, it is
      present as `reference.value`. Otherwise the value returned by this function
      is `null`.
        Example
        ```javascript
       let userRef = store.getReference('user', 1);
        userRef.value(); // user
       ```
        @method value
       @return {Model} the record for this RecordReference
    */
    value() {
      if (this._id !== null) {
        var internalModel = internalModelForReference(this);
        if (internalModel && internalModel.isLoaded()) {
          return internalModel.getRecord();
        }
      }
      return null;
    }

    /**
       Triggers a fetch for the backing entity based on its `remoteType`
       (see `remoteType` definitions per reference type).
        Example
        ```javascript
       let userRef = store.getReference('user', 1);
        // load user (via store.find)
       userRef.load().then(...)
       ```
        @method load
       @return {Promise<record>} the record for this RecordReference
    */
    load() {
      if (this._id !== null) {
        return this.store.findRecord(this.type, this._id);
      }
      throw new Error(`Unable to fetch record of type ${this.type} without an id`);
    }

    /**
       Reloads the record if it is already loaded. If the record is not
       loaded it will load the record via `store.findRecord`
        Example
        ```javascript
       let userRef = store.getReference('user', 1);
        // or trigger a reload
       userRef.reload().then(...)
       ```
        @method reload
       @return {Promise<record>} the record for this RecordReference
    */
    reload() {
      if (this._id !== null) {
        return this.store.findRecord(this.type, this._id, {
          reload: true
        });
      }
      throw new Error(`Unable to fetch record of type ${this.type} without an id`);
    }
  }

  /**
    @module @ember-data/store
  */

  /*
    This file encapsulates the various states that a record can transition
    through during its lifecycle.
  */
  /**
    ### State

    Each record has a `currentState` property that explicitly tracks what
    state a record is in at any given time. For instance, if a record is
    newly created and has not yet been sent to the adapter to be saved,
    it would be in the `root.loaded.created.uncommitted` state.  If a
    record has had local modifications made to it that are in the
    process of being saved, the record would be in the
    `root.loaded.updated.inFlight` state. (This state path will be
    explained in more detail below.)

    Events are sent by the record or its store to the record's
    `currentState` property. How the state reacts to these events is
    dependent on which state it is in. In some states, certain events
    will be invalid and will cause an exception to be raised.

    States are hierarchical and every state is a sub-state of the
    `RootState`. For example, a record can be in the
    `root.deleted.uncommitted` state then transitions into the
    `root.deleted.inFlight` state. If a child state does not implement
    an event handler, the state manager will attempt to invoke the event
    on all parent states until the root state is reached. The state
    hierarchy of a record is described in terms of a path string. You
    can determine a record's current state by getting the state's
    `stateName` property:

    ```javascript
    record.get('currentState.stateName');
    //=> "root.created.uncommitted"
     ```

    The hierarchy of valid states that ship with ember data looks like
    this:

    ```text
    * root
      * deleted
        * saved
        * uncommitted
        * inFlight
      * empty
      * loaded
        * created
          * uncommitted
          * inFlight
        * saved
        * updated
          * uncommitted
          * inFlight
      * loading
    ```

    The `Model` states are themselves stateless. What that means is
    that, the hierarchical states that each of *those* points to is a
    shared data structure. For performance reasons, instead of each
    record getting its own copy of the hierarchy of states, each record
    points to this global, immutable shared instance. How does a state
    know which record it should be acting on? We pass the record
    instance into the state's event handlers as the first argument.

    The record passed as the first parameter is where you should stash
    state about the record if needed; you should never store data on the state
    object itself.

    ### Events and Flags

    A state may implement zero or more events and flags.

    #### Events

    Events are named functions that are invoked when sent to a record. The
    record will first look for a method with the given name on the
    current state. If no method is found, it will search the current
    state's parent, and then its grandparent, and so on until reaching
    the top of the hierarchy. If the root is reached without an event
    handler being found, an exception will be raised. This can be very
    helpful when debugging new features.

    Here's an example implementation of a state with a `myEvent` event handler:

    ```javascript
    aState: State.create({
      myEvent: function(manager, param) {
        console.log("Received myEvent with", param);
      }
    })
    ```

    To trigger this event:

    ```javascript
    record.send('myEvent', 'foo');
    //=> "Received myEvent with foo"
    ```

    Note that an optional parameter can be sent to a record's `send()` method,
    which will be passed as the second parameter to the event handler.

    Events should transition to a different state if appropriate. This can be
    done by calling the record's `transitionTo()` method with a path to the
    desired state. The state manager will attempt to resolve the state path
    relative to the current state. If no state is found at that path, it will
    attempt to resolve it relative to the current state's parent, and then its
    parent, and so on until the root is reached. For example, imagine a hierarchy
    like this:

        * created
          * uncommitted <-- currentState
          * inFlight
        * updated
          * inFlight

    If we are currently in the `uncommitted` state, calling
    `transitionTo('inFlight')` would transition to the `created.inFlight` state,
    while calling `transitionTo('updated.inFlight')` would transition to
    the `updated.inFlight` state.

    Remember that *only events* should ever cause a state transition. You should
    never call `transitionTo()` from outside a state's event handler. If you are
    tempted to do so, create a new event and send that to the state manager.

    #### Flags

    Flags are Boolean values that can be used to introspect a record's current
    state in a more user-friendly way than examining its state path. For example,
    instead of doing this:

    ```javascript
    var statePath = record.get('stateManager.currentPath');
    if (statePath === 'created.inFlight') {
      doSomething();
    }
    ```

    You can say:

    ```javascript
    if (record.get('isNew') && record.get('isSaving')) {
      doSomething();
    }
    ```

    If your state does not set a value for a given flag, the value will
    be inherited from its parent (or the first place in the state hierarchy
    where it is defined).

    The current set of flags are defined below. If you want to add a new flag,
    in addition to the area below, you will also need to declare it in the
    `Model` class.


     * [isEmpty](Model/properties/isEmpty?anchor=isEmpty)
     * [isLoading](Model/properties/isLoading?anchor=isLoading)
     * [isLoaded](Model/properties/isLoaded?anchor=isLoaded)
     * [hasDirtyAttributes](Model/properties/hasDirtyAttributes?anchor=hasDirtyAttributes)
     * [isSaving](Model/properties/isSaving?anchor=isSaving)
     * [isDeleted](Model/properties/isDeleted?anchor=isDeleted)
     * [isNew](Model/properties/isNew?anchor=isNew)
     * [isValid](Model/properties/isValid?anchor=isValid)

    @class RootState
  */
  function didSetProperty(internalModel, context) {
    if (context.isDirty) {
      internalModel.send('becomeDirty');
    } else {
      internalModel.send('propertyWasReset');
    }
  }

  // Implementation notes:
  //
  // Each state has a boolean value for all of the following flags:
  //
  // * isLoaded: The record has a populated `data` property. When a
  //   record is loaded via `store.find`, `isLoaded` is false
  //   until the adapter sets it. When a record is created locally,
  //   its `isLoaded` property is always true.
  // * isDirty: The record has local changes that have not yet been
  //   saved by the adapter. This includes records that have been
  //   created (but not yet saved) or deleted.
  // * isSaving: The record has been committed, but
  //   the adapter has not yet acknowledged that the changes have
  //   been persisted to the backend.
  // * isDeleted: The record was marked for deletion. When `isDeleted`
  //   is true and `isDirty` is true, the record is deleted locally
  //   but the deletion was not yet persisted. When `isSaving` is
  //   true, the change is in-flight. When both `isDirty` and
  //   `isSaving` are false, the change has persisted.
  // * isNew: The record was created on the client and the adapter
  //   did not yet report that it was successfully saved.
  // * isValid: The adapter did not report any server-side validation
  //   failures.

  // The dirty state is a abstract state whose functionality is
  // shared between the `created` and `updated` states.
  //
  // The deleted state shares the `isDirty` flag with the
  // subclasses of `DirtyState`, but with a very different
  // implementation.
  //
  // Dirty states have three child states:
  //
  // `uncommitted`: the store has not yet handed off the record
  //   to be saved.
  // `inFlight`: the store has handed off the record to be saved,
  //   but the adapter has not yet acknowledged success.
  // `invalid`: the record has invalid information and cannot be
  //   sent to the adapter yet.
  var DirtyState = {
    initialState: 'uncommitted',
    // FLAGS
    isDirty: true,
    // SUBSTATES

    // When a record first becomes dirty, it is `uncommitted`.
    // This means that there are local pending changes, but they
    // have not yet begun to be saved, and are not invalid.
    uncommitted: {
      // EVENTS
      didSetProperty,
      //TODO(Igor) reloading now triggers a
      //loadingData event, though it seems fine?
      loadingData() {},
      propertyWasReset(internalModel, name) {
        if (!internalModel.hasChangedAttributes()) {
          internalModel.send('rolledBack');
        }
      },
      pushedData(internalModel) {
        if (!internalModel.hasChangedAttributes()) {
          internalModel.transitionTo('loaded.saved');
        }
      },
      becomeDirty() {},
      willCommit(internalModel) {
        internalModel.transitionTo('inFlight');
      },
      reloadRecord(internalModel, _ref) {
        var {
          resolve,
          options
        } = _ref;
        resolve(internalModel.store._reloadRecord(internalModel, options));
      },
      rolledBack(internalModel) {
        internalModel.transitionTo('loaded.saved');
        internalModel.triggerLater('rolledBack');
      },
      becameInvalid(internalModel) {
        internalModel.transitionTo('invalid');
      },
      rollback(internalModel) {
        internalModel.rollbackAttributes();
        internalModel.triggerLater('ready');
      }
    },
    // Once a record has been handed off to the adapter to be
    // saved, it is in the 'in flight' state. Changes to the
    // record cannot be made during this window.
    inFlight: {
      // FLAGS
      isSaving: true,
      // EVENTS
      didSetProperty,
      becomeDirty() {},
      pushedData() {},
      unloadRecord: assertAgainstUnloadRecord,
      // TODO: More robust semantics around save-while-in-flight
      willCommit() {},
      didCommit(internalModel) {
        internalModel.transitionTo('saved');
        internalModel.send('invokeLifecycleCallbacks', this.dirtyType);
      },
      rolledBack(internalModel) {
        internalModel.triggerLater('rolledBack');
      },
      becameInvalid(internalModel) {
        internalModel.transitionTo('invalid');
        internalModel.send('invokeLifecycleCallbacks');
      },
      becameError(internalModel) {
        internalModel.transitionTo('uncommitted');
        internalModel.triggerLater('becameError', internalModel);
      }
    },
    // A record is in the `invalid` if the adapter has indicated
    // the the record failed server-side invalidations.
    invalid: {
      // FLAGS
      isValid: false,
      // EVENTS
      deleteRecord(internalModel) {
        internalModel.transitionTo('deleted.uncommitted');
      },
      didSetProperty(internalModel, context) {
        internalModel.removeErrorMessageFromAttribute(context.name);
        didSetProperty(internalModel, context);
        if (!internalModel.hasErrors()) {
          this.becameValid(internalModel);
        }
      },
      becameInvalid() {},
      becomeDirty() {},
      pushedData() {},
      willCommit(internalModel) {
        internalModel.clearErrorMessages();
        internalModel.transitionTo('inFlight');
      },
      rolledBack(internalModel) {
        internalModel.clearErrorMessages();
        internalModel.transitionTo('loaded.saved');
        internalModel.triggerLater('ready');
      },
      becameValid(internalModel) {
        internalModel.transitionTo('uncommitted');
      },
      invokeLifecycleCallbacks(internalModel) {
        internalModel.triggerLater('becameInvalid', internalModel);
      }
    }
  };

  // The created and updated states are created outside the state
  // chart so we can reopen their substates and add mixins as
  // necessary.

  function deepClone(object) {
    var clone = {};
    var value;
    for (var prop in object) {
      value = object[prop];
      if (value && typeof value === 'object') {
        clone[prop] = deepClone(value);
      } else {
        clone[prop] = value;
      }
    }
    return clone;
  }
  function mixin(original, hash) {
    for (var prop in hash) {
      original[prop] = hash[prop];
    }
    return original;
  }
  function dirtyState(options) {
    var newState = deepClone(DirtyState);
    return mixin(newState, options);
  }
  var createdState = dirtyState({
    dirtyType: 'created',
    // FLAGS
    isNew: true,
    setup(internalModel) {
      internalModel.updateRecordArrays();
    }
  });
  createdState.invalid.rolledBack = function (internalModel) {
    internalModel.transitionTo('deleted.saved');
    internalModel.triggerLater('rolledBack');
  };
  createdState.uncommitted.rolledBack = function (internalModel) {
    internalModel.transitionTo('deleted.saved');
    internalModel.triggerLater('rolledBack');
  };
  var updatedState = dirtyState({
    dirtyType: 'updated'
  });
  function createdStateDeleteRecord(internalModel) {
    internalModel.transitionTo('deleted.saved');
    internalModel.send('invokeLifecycleCallbacks');
  }
  createdState.uncommitted.deleteRecord = createdStateDeleteRecord;
  createdState.invalid.deleteRecord = createdStateDeleteRecord;
  createdState.uncommitted.rollback = function (internalModel) {
    DirtyState.uncommitted.rollback.apply(this, arguments);
    internalModel.transitionTo('deleted.saved');
  };
  createdState.uncommitted.pushedData = function (internalModel) {
    internalModel.transitionTo('loaded.updated.uncommitted');
    internalModel.triggerLater('didLoad');
  };
  createdState.uncommitted.propertyWasReset = function () {};
  function assertAgainstUnloadRecord(internalModel) {
    ( Ember.assert('You can only unload a record which is not inFlight. `' + internalModel + '`', false));
  }
  updatedState.invalid.becameValid = function (internalModel) {
    // we're eagerly transition into the loaded.saved state, even though we could
    // be still dirty; but the setup hook of the loaded.saved state checks for
    // dirty attributes and transitions into the corresponding dirty state
    internalModel.transitionTo('loaded.saved');
  };
  updatedState.inFlight.unloadRecord = assertAgainstUnloadRecord;
  updatedState.uncommitted.deleteRecord = function (internalModel) {
    internalModel.transitionTo('deleted.uncommitted');
  };
  updatedState.invalid.rolledBack = function (internalModel) {
    internalModel.clearErrorMessages();
    internalModel.transitionTo('loaded.saved');
    internalModel.triggerLater('rolledBack');
  };
  var RootState = {
    // FLAGS
    isEmpty: false,
    isLoading: false,
    isLoaded: false,
    isDirty: false,
    isSaving: false,
    isDeleted: false,
    isNew: false,
    isValid: true,
    // DEFAULT EVENTS

    // Trying to roll back if you're not in the dirty state
    // doesn't change your state. For example, if you're in the
    // in-flight state, rolling back the record doesn't move
    // you out of the in-flight state.
    rolledBack() {},
    unloadRecord(internalModel) {},
    propertyWasReset() {},
    // SUBSTATES

    // A record begins its lifecycle in the `empty` state.
    // If its data will come from the adapter, it will
    // transition into the `loading` state. Otherwise, if
    // the record is being created on the client, it will
    // transition into the `created` state.
    empty: {
      isEmpty: true,
      // EVENTS
      loadingData(internalModel, promise) {
        {
          internalModel._promiseProxy = promise;
        }
        internalModel.transitionTo('loading');
      },
      loadedData(internalModel) {
        internalModel.transitionTo('loaded.created.uncommitted');
        internalModel.triggerLater('ready');
      },
      pushedData(internalModel) {
        internalModel.transitionTo('loaded.saved');
        internalModel.triggerLater('didLoad');
        internalModel.triggerLater('ready');
      },
      // Record is already in an empty state, triggering transition to empty here
      // produce an error.
      notFound() {}
    },
    // A record enters this state when the store asks
    // the adapter for its data. It remains in this state
    // until the adapter provides the requested data.
    //
    // Usually, this process is asynchronous, using an
    // XHR to retrieve the data.
    loading: {
      // FLAGS
      isLoading: true,
      exit(internalModel) {
        internalModel._promiseProxy = null;
      },
      loadingData() {},
      // EVENTS
      pushedData(internalModel) {
        internalModel.transitionTo('loaded.saved');
        internalModel.triggerLater('didLoad');
        internalModel.triggerLater('ready');
        //TODO this seems out of place here
        internalModel.didCleanError();
      },
      becameError(internalModel) {
        internalModel.triggerLater('becameError', internalModel);
      },
      notFound(internalModel) {
        internalModel.transitionTo('empty');
      }
    },
    // A record enters this state when its data is populated.
    // Most of a record's lifecycle is spent inside substates
    // of the `loaded` state.
    loaded: {
      initialState: 'saved',
      // FLAGS
      isLoaded: true,
      //TODO(Igor) Reloading now triggers a loadingData event,
      //but it should be ok?
      loadingData() {},
      // SUBSTATES

      // If there are no local changes to a record, it remains
      // in the `saved` state.
      saved: {
        setup(internalModel) {
          if (internalModel.hasChangedAttributes()) {
            internalModel.adapterDidDirty();
          }
        },
        // EVENTS
        didSetProperty,
        pushedData() {},
        becomeDirty(internalModel) {
          internalModel.transitionTo('updated.uncommitted');
        },
        willCommit(internalModel) {
          internalModel.transitionTo('updated.inFlight');
        },
        reloadRecord(internalModel, _ref2) {
          var {
            resolve,
            options
          } = _ref2;
          {
            resolve(internalModel.store._reloadRecord(internalModel, options));
          }
        },
        deleteRecord(internalModel) {
          internalModel.transitionTo('deleted.uncommitted');
        },
        unloadRecord(internalModel) {},
        didCommit() {},
        // loaded.saved.notFound would be triggered by a failed
        // `reload()` on an unchanged record
        notFound() {}
      },
      // A record is in this state after it has been locally
      // created but before the adapter has indicated that
      // it has been saved.
      created: createdState,
      // A record is in this state if it has already been
      // saved to the server, but there are new local changes
      // that have not yet been saved.
      updated: updatedState
    },
    // A record is in this state if it was deleted from the store.
    deleted: {
      initialState: 'uncommitted',
      dirtyType: 'deleted',
      // FLAGS
      isDeleted: true,
      isLoaded: true,
      isDirty: true,
      // TRANSITIONS
      setup(internalModel) {
        internalModel.updateRecordArrays();
      },
      // SUBSTATES

      // When a record is deleted, it enters the `start`
      // state. It will exit this state when the record
      // starts to commit.
      uncommitted: {
        // EVENTS

        willCommit(internalModel) {
          internalModel.transitionTo('inFlight');
        },
        rollback(internalModel) {
          internalModel.rollbackAttributes();
          internalModel.triggerLater('ready');
        },
        pushedData() {},
        becomeDirty() {},
        deleteRecord() {},
        rolledBack(internalModel) {
          internalModel.transitionTo('loaded.saved');
          internalModel.triggerLater('ready');
          internalModel.triggerLater('rolledBack');
        }
      },
      // After a record starts committing, but
      // before the adapter indicates that the deletion
      // has saved to the server, a record is in the
      // `inFlight` substate of `deleted`.
      inFlight: {
        // FLAGS
        isSaving: true,
        // EVENTS

        unloadRecord: assertAgainstUnloadRecord,
        // TODO: More robust semantics around save-while-in-flight
        willCommit() {},
        didCommit(internalModel) {
          internalModel.transitionTo('saved');
          internalModel.send('invokeLifecycleCallbacks');
        },
        becameError(internalModel) {
          internalModel.transitionTo('uncommitted');
          internalModel.triggerLater('becameError', internalModel);
        },
        becameInvalid(internalModel) {
          internalModel.transitionTo('invalid');
          internalModel.triggerLater('becameInvalid', internalModel);
        }
      },
      // Once the adapter indicates that the deletion has
      // been saved, the record enters the `saved` substate
      // of `deleted`.
      saved: {
        // FLAGS
        isDirty: false,
        setup(internalModel) {
          internalModel.removeFromInverseRelationships();
        },
        invokeLifecycleCallbacks(internalModel) {
          internalModel.triggerLater('didDelete', internalModel);
          internalModel.triggerLater('didCommit', internalModel);
        },
        willCommit() {},
        didCommit() {},
        pushedData() {}
      },
      invalid: {
        isValid: false,
        didSetProperty(internalModel, context) {
          internalModel.removeErrorMessageFromAttribute(context.name);
          didSetProperty(internalModel, context);
          if (!internalModel.hasErrors()) {
            this.becameValid(internalModel);
          }
        },
        becameInvalid() {},
        becomeDirty() {},
        deleteRecord() {},
        willCommit() {},
        rolledBack(internalModel) {
          internalModel.clearErrorMessages();
          internalModel.transitionTo('loaded.saved');
          internalModel.triggerLater('ready');
        },
        becameValid(internalModel) {
          internalModel.transitionTo('uncommitted');
        }
      }
    },
    invokeLifecycleCallbacks(internalModel, dirtyType) {
      if (dirtyType === 'created') {
        internalModel.triggerLater('didCreate', internalModel);
      } else {
        internalModel.triggerLater('didUpdate', internalModel);
      }
      internalModel.triggerLater('didCommit', internalModel);
    }
  };
  function wireState(object, parent, name) {
    // TODO: Use Object.create and copy instead
    object = mixin(parent ? Object.create(parent) : {}, object);
    object.parentState = parent;
    object.stateName = name;
    for (var prop in object) {
      if (!Object.prototype.hasOwnProperty.call(object, prop) || prop === 'parentState' || prop === 'stateName') {
        continue;
      }
      if (typeof object[prop] === 'object') {
        object[prop] = wireState(object[prop], object, name + '.' + prop);
      }
    }
    return object;
  }
  var RootState$1 = wireState(RootState, null, 'root');

  // move to TS hacks module that we can delete when this is no longer a necessary recast

  /**
    @module @ember-data/store
  */

  // once the presentation logic is moved into the Model package we can make
  // eliminate these lossy and redundant helpers
  function relationshipsFor$1(instance) {
    var recordData = recordDataFor(instance);
    return recordData._relationships;
  }
  function relationshipStateFor$1(instance, propertyName) {
    return relationshipsFor$1(instance).get(propertyName);
  }
  var {
    hasOwnProperty
  } = Object.prototype;
  var ManyArray;
  var PromiseBelongsTo;
  var PromiseManyArray;
  var _found = false;
  var _getModelPackage;
  {
    _getModelPackage = function () {
      if (!_found) {
        var modelPackage = require('@ember-data/model/-private');
        ({
          ManyArray,
          PromiseBelongsTo,
          PromiseManyArray
        } = modelPackage);
        if (ManyArray && PromiseBelongsTo && PromiseManyArray) {
          _found = true;
        }
      }
      return _found;
    };
  }
  /*
    The TransitionChainMap caches the `state.enters`, `state.setups`, and final state reached
    when transitioning from one state to another, so that future transitions can replay the
    transition without needing to walk the state tree, collect these hook calls and determine
     the state to transition into.

     A future optimization would be to build a single chained method out of the collected enters
     and setups. It may also be faster to do a two level cache (from: { to }) instead of caching based
     on a key that adds the two together.
   */
  var TransitionChainMap = Object.create(null);
  var _extractPivotNameCache = Object.create(null);
  var _splitOnDotCache = Object.create(null);
  function splitOnDot(name) {
    return _splitOnDotCache[name] || (_splitOnDotCache[name] = name.split('.'));
  }
  function extractPivotName(name) {
    return _extractPivotNameCache[name] || (_extractPivotNameCache[name] = splitOnDot(name)[0]);
  }

  /*
    `InternalModel` is the Model class that we use internally inside Ember Data to represent models.
    Internal ED methods should only deal with `InternalModel` objects. It is a fast, plain Javascript class.

    We expose `Model` to application code, by materializing a `Model` from `InternalModel` lazily, as
    a performance optimization.

    `InternalModel` should never be exposed to application code. At the boundaries of the system, in places
    like `find`, `push`, etc. we convert between Models and InternalModels.

    We need to make sure that the properties from `InternalModel` are correctly exposed/proxied on `Model`
    if they are needed.

    @private
    @class InternalModel
  */
  class InternalModel {
    constructor(store, identifier) {
      this._id = void 0;
      this._tag = 0;
      this.modelName = void 0;
      this.clientId = void 0;
      this.__recordData = void 0;
      this._isDestroyed = void 0;
      this.isError = void 0;
      this._pendingRecordArrayManagerFlush = void 0;
      this._isDematerializing = void 0;
      this.isReloading = void 0;
      this._doNotDestroy = void 0;
      this.isDestroying = void 0;
      // Not typed yet
      this._promiseProxy = void 0;
      this._record = void 0;
      this._scheduledDestroy = void 0;
      this._modelClass = void 0;
      this.__deferredTriggers = void 0;
      this.__recordArrays = void 0;
      this._references = void 0;
      this._recordReference = void 0;
      this._manyArrayCache = Object.create(null);
      // The previous ManyArrays for this relationship which will be destroyed when
      // we create a new ManyArray, but in the interim the retained version will be
      // updated if inverse internal models are unloaded.
      this._retainedManyArrayCache = Object.create(null);
      this._relationshipPromisesCache = Object.create(null);
      this._relationshipProxyCache = Object.create(null);
      this.currentState = void 0;
      this.error = void 0;
      this.store = store;
      this.identifier = identifier;
      {
        _getModelPackage();
      }
      this._id = identifier.id;
      this.modelName = identifier.type;
      this.clientId = identifier.lid;
      this.__recordData = null;

      // this ensure ordered set can quickly identify this as unique
      this[Ember.GUID_KEY] = identifier.lid;
      this._promiseProxy = null;
      this._record = null;
      this._isDestroyed = false;
      this.isError = false;
      this._pendingRecordArrayManagerFlush = false; // used by the recordArrayManager

      // During dematerialization we don't want to rematerialize the record.  The
      // reason this might happen is that dematerialization removes records from
      // record arrays,  and Ember arrays will always `objectAt(0)` and
      // `objectAt(len - 1)` to test whether or not `firstObject` or `lastObject`
      // have changed.
      this._isDematerializing = false;
      this._scheduledDestroy = null;
      this.resetRecord();

      // caches for lazy getters
      this._modelClass = null;
      this.__deferredTriggers = null;
      this.__recordArrays = null;
      this._references = null;
      this._recordReference = null;
    }
    get id() {
      return this.identifier.id;
    }
    set id(value) {
      if (value !== this._id) {
        var newIdentifier = {
          type: this.identifier.type,
          lid: this.identifier.lid,
          id: value
        };
        identifierCacheFor(this.store).updateRecordIdentifier(this.identifier, newIdentifier);
        Ember.set(this, '_tag', this._tag + 1);
        // TODO Show deprecation for private api
      }
    }

    get modelClass() {
      if (this.store.modelFor) {
        return this._modelClass || (this._modelClass = this.store.modelFor(this.modelName));
      }
    }
    get type() {
      return this.modelClass;
    }
    get recordReference() {
      if (this._recordReference === null) {
        this._recordReference = new RecordReference(this.store, this.identifier);
      }
      return this._recordReference;
    }
    get _recordData() {
      if (this.__recordData === null) {
        var recordData = this.store._createRecordData(this.identifier);
        this._recordData = recordData;
        return recordData;
      }
      return this.__recordData;
    }
    set _recordData(newValue) {
      this.__recordData = newValue;
    }
    get references() {
      if (this._references === null) {
        this._references = Object.create(null);
      }
      return this._references;
    }
    get _deferredTriggers() {
      if (this.__deferredTriggers === null) {
        this.__deferredTriggers = [];
      }
      return this.__deferredTriggers;
    }
    isHiddenFromRecordArrays() {
      // During dematerialization we don't want to rematerialize the record.
      // recordWasDeleted can cause other records to rematerialize because it
      // removes the internal model from the array and Ember arrays will always
      // `objectAt(0)` and `objectAt(len -1)` to check whether `firstObject` or
      // `lastObject` have changed.  When this happens we don't want those
      // models to rematerialize their records.

      // eager checks to avoid instantiating record data if we are empty or loading
      if (this.isEmpty()) {
        return true;
      }
      var isRecordFullyDeleted;
      {
        isRecordFullyDeleted = this.currentState.stateName === 'root.deleted.saved';
      }
      return this._isDematerializing || this.hasScheduledDestroy() || this.isDestroyed || isRecordFullyDeleted;
    }
    _isRecordFullyDeleted() {
      {
        // assert here
        return false;
      }
    }
    isRecordInUse() {
      var record = this._record;
      return record && !(record.get('isDestroyed') || record.get('isDestroying'));
    }
    isEmpty() {
      return this.currentState.isEmpty;
    }
    isLoading() {
      return this.currentState.isLoading;
    }
    isLoaded() {
      return this.currentState.isLoaded;
    }
    hasDirtyAttributes() {
      return this.currentState.hasDirtyAttributes;
    }
    isSaving() {
      return this.currentState.isSaving;
    }
    isDeleted() {
      {
        return this.currentState.isDeleted;
      }
    }
    isNew() {
      {
        return this.currentState.isNew;
      }
    }
    isValid() {
      {
        return this.currentState.isValid;
      }
    }
    dirtyType() {
      return this.currentState.dirtyType;
    }
    getRecord(properties) {
      if (!this._record && !this._isDematerializing) {
        var {
          store: _store
        } = this;
        {
          {
            // lookupFactory should really return an object that creates
            // instances with the injections applied
            var createOptions = {
              store: _store,
              _internalModel: this,
              currentState: this.currentState
            };
            {
              createOptions.isError = this.isError;
              createOptions.adapterError = this.error;
            }
            if (properties !== undefined) {
              ( !(typeof properties === 'object' && properties !== null) && Ember.assert(`You passed '${properties}' as properties for record creation instead of an object.`, typeof properties === 'object' && properties !== null));
              if ('id' in properties) {
                var id = coerceId(properties.id);
                if (id !== null) {
                  this.setId(id);
                }
              }

              // convert relationship Records to RecordDatas before passing to RecordData
              var defs = _store._relationshipsDefinitionFor(this.modelName);
              if (defs !== null) {
                var keys = Object.keys(properties);
                var relationshipValue;
                for (var i = 0; i < keys.length; i++) {
                  var prop = keys[i];
                  var def = defs[prop];
                  if (def !== undefined) {
                    if (def.kind === 'hasMany') {
                      {
                        assertRecordsPassedToHasMany(properties[prop]);
                      }
                      relationshipValue = extractRecordDatasFromRecords(properties[prop]);
                    } else {
                      relationshipValue = extractRecordDataFromRecord(properties[prop]);
                    }
                    properties[prop] = relationshipValue;
                  }
                }
              }
            }
            var additionalCreateOptions = this._recordData._initRecordCreateOptions(properties);
            Ember.assign(createOptions, additionalCreateOptions);

            // ensure that `getOwner(this)` works inside a model instance
            Ember.setOwner(createOptions, Ember.getOwner(_store));
            this._record = _store._modelFactoryFor(this.modelName).create(createOptions);
            setRecordIdentifier(this._record, this.identifier);
          }
        }
        this._triggerDeferredTriggers();
      }
      return this._record;
    }
    resetRecord() {
      this._record = null;
      this.isReloading = false;
      this.error = null;
      this.currentState = RootState$1.empty;
    }
    dematerializeRecord() {
      this._isDematerializing = true;

      // TODO IGOR add a test that fails when this is missing, something that involves canceling a destroy
      // and the destroy not happening, and then later on trying to destroy
      this._doNotDestroy = false;
      if (this._record) {
        {
          this._record.destroy();
        }
        Object.keys(this._relationshipProxyCache).forEach(key => {
          if (this._relationshipProxyCache[key].destroy) {
            this._relationshipProxyCache[key].destroy();
          }
          delete this._relationshipProxyCache[key];
        });
        Object.keys(this._manyArrayCache).forEach(key => {
          var manyArray = this._retainedManyArrayCache[key] = this._manyArrayCache[key];
          delete this._manyArrayCache[key];
          if (manyArray && !manyArray._inverseIsAsync) {
            /*
              If the manyArray is for a sync relationship, we should clear it
                to preserve the semantics of client-side delete.
               It is likely in this case instead of retaining we should destroy
                - @runspired
            */
            manyArray.clear();
          }
        });
      }

      // move to an empty never-loaded state
      this.updateRecordArrays();
      this._recordData.unloadRecord();
      this.resetRecord();
    }
    deleteRecord() {
      this.send('deleteRecord');
    }
    save(options) {
      var promiseLabel = 'DS: Model#save ' + this;
      var resolver = Ember.RSVP.defer(promiseLabel);
      {
        this.store.scheduleSave(this, resolver, options);
        return resolver.promise;
      }
    }
    startedReloading() {
      this.isReloading = true;
      if (this.hasRecord) {
        Ember.set(this._record, 'isReloading', true);
      }
    }
    finishedReloading() {
      this.isReloading = false;
      if (this.hasRecord) {
        Ember.set(this._record, 'isReloading', false);
      }
    }
    reload(options) {
      {
        this.startedReloading();
        var _internalModel = this;
        var promiseLabel = 'DS: Model#reload of ' + this;
        return new Ember.RSVP.Promise(function (resolve) {
          _internalModel.send('reloadRecord', {
            resolve,
            options
          });
        }, promiseLabel).then(function () {
          _internalModel.didCleanError();
          return _internalModel;
        }, function (error) {
          _internalModel.didError(error);
          throw error;
        }, 'DS: Model#reload complete, update flags').finally(function () {
          _internalModel.finishedReloading();
        });
      }
    }

    /*
      Unload the record for this internal model. This will cause the record to be
      destroyed and freed up for garbage collection. It will also do a check
      for cleaning up internal models.
       This check is performed by first computing the set of related internal
      models. If all records in this set are unloaded, then the entire set is
      destroyed. Otherwise, nothing in the set is destroyed.
       This means that this internal model will be freed up for garbage collection
      once all models that refer to it via some relationship are also unloaded.
    */
    unloadRecord() {
      if (this.isDestroyed) {
        return;
      }
      this.send('unloadRecord');
      this.dematerializeRecord();
      if (this._scheduledDestroy === null) {
        this._scheduledDestroy = Ember.run.backburner.schedule('destroy', this, '_checkForOrphanedInternalModels');
      }
    }
    hasScheduledDestroy() {
      return !!this._scheduledDestroy;
    }
    cancelDestroy() {
      ( !(!this.isDestroyed) && Ember.assert(`You cannot cancel the destruction of an InternalModel once it has already been destroyed`, !this.isDestroyed));
      this._doNotDestroy = true;
      this._isDematerializing = false;
      Ember.run.cancel(this._scheduledDestroy);
      this._scheduledDestroy = null;
    }

    // typically, we prefer to async destroy this lets us batch cleanup work.
    // Unfortunately, some scenarios where that is not possible. Such as:
    //
    // ```js
    // const record = store.find(‘record’, 1);
    // record.unloadRecord();
    // store.createRecord(‘record’, 1);
    // ```
    //
    // In those scenarios, we make that model's cleanup work, sync.
    //
    destroySync() {
      if (this._isDematerializing) {
        this.cancelDestroy();
      }
      this._checkForOrphanedInternalModels();
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      // just in-case we are not one of the orphaned, we should still
      // still destroy ourselves
      this.destroy();
    }
    _checkForOrphanedInternalModels() {
      this._isDematerializing = false;
      this._scheduledDestroy = null;
      if (this.isDestroyed) {
        return;
      }
    }
    eachRelationship(callback, binding) {
      return this.modelClass.eachRelationship(callback, binding);
    }
    _findBelongsTo(key, resource, relationshipMeta, options) {
      // TODO @runspired follow up if parent isNew then we should not be attempting load here
      return this.store._findBelongsToByJsonApiResource(resource, this, relationshipMeta, options).then(internalModel => handleCompletedRelationshipRequest(this, key, resource._relationship, internalModel, null), e => handleCompletedRelationshipRequest(this, key, resource._relationship, null, e));
    }
    getBelongsTo(key, options) {
      var resource = this._recordData.getBelongsTo(key);
      var identifier = resource && resource.data ? identifierCacheFor(this.store).getOrCreateRecordIdentifier(resource.data) : null;
      var relationshipMeta = this.store._relationshipMetaFor(this.modelName, null, key);
      var store = this.store;
      var parentInternalModel = this;
      var async = relationshipMeta.options.async;
      var isAsync = typeof async === 'undefined' ? true : async;
      var _belongsToState = {
        key,
        store,
        originatingInternalModel: this,
        modelName: relationshipMeta.type
      };
      if (isAsync) {
        var internalModel = identifier !== null ? store._internalModelForResource(identifier) : null;
        if (resource._relationship.hasFailedLoadAttempt) {
          return this._relationshipProxyCache[key];
        }
        var promise = this._findBelongsTo(key, resource, relationshipMeta, options);
        return this._updatePromiseProxyFor('belongsTo', key, {
          promise,
          content: internalModel ? internalModel.getRecord() : null,
          _belongsToState
        });
      } else {
        if (identifier === null) {
          return null;
        } else {
          var _internalModel2 = store._internalModelForResource(identifier);
          var toReturn = _internalModel2.getRecord();
          ( !(toReturn === null || !toReturn.get('isEmpty')) && Ember.assert("You looked up the '" + key + "' relationship on a '" + parentInternalModel.modelName + "' with id " + parentInternalModel.id + ' but some of the associated records were not loaded. Either make sure they are all loaded together with the parent record, or specify that the relationship is async (`belongsTo({ async: true })`)', toReturn === null || !toReturn.get('isEmpty')));
          return toReturn;
        }
      }
    }

    // TODO Igor consider getting rid of initial state
    getManyArray(key, isAsync) {
      if (isAsync === void 0) {
        isAsync = false;
      }
      var relationshipMeta = this.store._relationshipMetaFor(this.modelName, null, key);
      var jsonApi = this._recordData.getHasMany(key);
      var manyArray = this._manyArrayCache[key];
      ( !(!manyArray || !this._retainedManyArrayCache[key]) && Ember.assert(`Error: relationship ${this.modelName}:${key} has both many array and retained many array`, !manyArray || !this._retainedManyArrayCache[key]));
      if (!manyArray) {
        var initialState = this.store._getHasManyByJsonApiResource(jsonApi);
        // TODO move this to a public api
        var inverseIsAsync = jsonApi._relationship ? jsonApi._relationship._inverseIsAsync() : false;
        manyArray = ManyArray.create({
          store: this.store,
          type: this.store.modelFor(relationshipMeta.type),
          recordData: this._recordData,
          meta: jsonApi.meta,
          links: jsonApi.links,
          key,
          isPolymorphic: relationshipMeta.options.polymorphic,
          initialState: initialState.slice(),
          _inverseIsAsync: inverseIsAsync,
          internalModel: this,
          isLoaded: !isAsync
        });
        this._manyArrayCache[key] = manyArray;
      }
      if (this._retainedManyArrayCache[key]) {
        this._retainedManyArrayCache[key].destroy();
        delete this._retainedManyArrayCache[key];
      }
      return manyArray;
    }
    fetchAsyncHasMany(key, relationshipMeta, jsonApi, manyArray, options) {
      // TODO @runspired follow up if parent isNew then we should not be attempting load here
      var loadingPromise = this._relationshipPromisesCache[key];
      if (loadingPromise) {
        return loadingPromise;
      }
      loadingPromise = this.store._findHasManyByJsonApiResource(jsonApi, this, relationshipMeta, options).then(() => {
        // TODO why don't we do this in the store method
        manyArray.retrieveLatest();
        manyArray.set('isLoaded', true);
        return manyArray;
      }).then(manyArray => handleCompletedRelationshipRequest(this, key, jsonApi._relationship, manyArray, null), e => handleCompletedRelationshipRequest(this, key, jsonApi._relationship, null, e));
      this._relationshipPromisesCache[key] = loadingPromise;
      return loadingPromise;
    }
    getHasMany(key, options) {
      var jsonApi = this._recordData.getHasMany(key);
      var relationshipMeta = this.store._relationshipMetaFor(this.modelName, null, key);
      var async = relationshipMeta.options.async;
      var isAsync = typeof async === 'undefined' ? true : async;
      var manyArray = this.getManyArray(key, isAsync);
      if (isAsync) {
        if (jsonApi._relationship.hasFailedLoadAttempt) {
          return this._relationshipProxyCache[key];
        }
        var promise = this.fetchAsyncHasMany(key, relationshipMeta, jsonApi, manyArray, options);
        return this._updatePromiseProxyFor('hasMany', key, {
          promise,
          content: manyArray
        });
      } else {
        ( !(!manyArray.anyUnloaded()) && Ember.assert(`You looked up the '${key}' relationship on a '${this.type.modelName}' with id ${this.id} but some of the associated records were not loaded. Either make sure they are all loaded together with the parent record, or specify that the relationship is async ('hasMany({ async: true })')`, !manyArray.anyUnloaded()));
        return manyArray;
      }
    }
    _updatePromiseProxyFor(kind, key, args) {
      var promiseProxy = this._relationshipProxyCache[key];
      if (promiseProxy) {
        if (args.content !== undefined) {
          // this usage of `any` can be removed when `@types/ember_object` proxy allows `null` for content
          promiseProxy.set('content', args.content);
        }
        promiseProxy.set('promise', args.promise);
      } else {
        var klass = kind === 'hasMany' ? PromiseManyArray : PromiseBelongsTo;
        // this usage of `any` can be removed when `@types/ember_object` proxy allows `null` for content
        this._relationshipProxyCache[key] = klass.create(args);
      }
      return this._relationshipProxyCache[key];
    }
    reloadHasMany(key, options) {
      var loadingPromise = this._relationshipPromisesCache[key];
      if (loadingPromise) {
        return loadingPromise;
      }
      var jsonApi = this._recordData.getHasMany(key);
      // TODO move this to a public api
      if (jsonApi._relationship) {
        jsonApi._relationship.setHasFailedLoadAttempt(false);
        jsonApi._relationship.setShouldForceReload(true);
      }
      var relationshipMeta = this.store._relationshipMetaFor(this.modelName, null, key);
      var manyArray = this.getManyArray(key);
      var promise = this.fetchAsyncHasMany(key, relationshipMeta, jsonApi, manyArray, options);
      if (this._relationshipProxyCache[key]) {
        return this._updatePromiseProxyFor('hasMany', key, {
          promise
        });
      }
      return promise;
    }
    reloadBelongsTo(key, options) {
      var loadingPromise = this._relationshipPromisesCache[key];
      if (loadingPromise) {
        return loadingPromise;
      }
      var resource = this._recordData.getBelongsTo(key);
      // TODO move this to a public api
      if (resource._relationship) {
        resource._relationship.setHasFailedLoadAttempt(false);
        resource._relationship.setShouldForceReload(true);
      }
      var relationshipMeta = this.store._relationshipMetaFor(this.modelName, null, key);
      var promise = this._findBelongsTo(key, resource, relationshipMeta, options);
      if (this._relationshipProxyCache[key]) {
        return this._updatePromiseProxyFor('belongsTo', key, {
          promise
        });
      }
      return promise;
    }
    destroyFromRecordData() {
      if (this._doNotDestroy) {
        this._doNotDestroy = false;
        return;
      }
      this.destroy();
    }
    destroy() {
      ( !(!this._record || this._record.get('isDestroyed') || this._record.get('isDestroying')) && Ember.assert('Cannot destroy an internalModel while its record is materialized', !this._record || this._record.get('isDestroyed') || this._record.get('isDestroying')));
      this.isDestroying = true;
      Object.keys(this._retainedManyArrayCache).forEach(key => {
        this._retainedManyArrayCache[key].destroy();
        delete this._retainedManyArrayCache[key];
      });
      internalModelFactoryFor(this.store).remove(this);
      this._isDestroyed = true;
    }
    eachAttribute(callback, binding) {
      return this.modelClass.eachAttribute(callback, binding);
    }
    inverseFor(key) {
      return this.modelClass.inverseFor(key);
    }
    setupData(data) {
      var changedKeys = this._recordData.pushData(data, this.hasRecord);
      if (this.hasRecord) {
        this._record._notifyProperties(changedKeys);
      }
      this.pushedData();
    }
    getAttributeValue(key) {
      return this._recordData.getAttr(key);
    }
    setDirtyHasMany(key, records) {
      assertRecordsPassedToHasMany(records);
      return this._recordData.setDirtyHasMany(key, extractRecordDatasFromRecords(records));
    }
    setDirtyBelongsTo(key, value) {
      return this._recordData.setDirtyBelongsTo(key, extractRecordDataFromRecord(value));
    }
    setDirtyAttribute(key, value) {
      if (this.isDeleted()) {
        {
          throw new Ember.Error(`Attempted to set '${key}' to '${value}' on the deleted record ${this}`);
        }
      }
      var currentValue = this.getAttributeValue(key);
      if (currentValue !== value) {
        this._recordData.setDirtyAttribute(key, value);
        var isDirty = this._recordData.isAttrDirty(key);
        this.send('didSetProperty', {
          name: key,
          isDirty: isDirty
        });
      }
      return value;
    }
    get isDestroyed() {
      return this._isDestroyed;
    }
    get hasRecord() {
      return !!this._record;
    }

    /*
      @method createSnapshot
      @private
    */
    createSnapshot(options) {
      return new Snapshot(options || {}, this.identifier, this.store);
    }

    /*
      @method loadingData
      @private
      @param {Promise} promise
    */
    loadingData(promise) {
      {
        this.send('loadingData', promise);
      }
    }

    /*
      @method loadedData
      @private
    */
    loadedData() {
      this.send('loadedData');
    }

    /*
      @method notFound
      @private
    */
    notFound() {
      this.send('notFound');
    }

    /*
      @method pushedData
      @private
    */
    pushedData() {
      this.send('pushedData');
    }
    hasChangedAttributes() {
      {
        if (this.isLoading() && !this.isReloading) {
          // no need to calculate changed attributes when calling `findRecord`
          return false;
        }
      }
      return this._recordData.hasChangedAttributes();
    }

    /*
      Returns an object, whose keys are changed properties, and value is an
      [oldProp, newProp] array.
       @method changedAttributes
      @private
    */
    changedAttributes() {
      {
        if (this.isLoading() && !this.isReloading) {
          // no need to calculate changed attributes when calling `findRecord`
          return {};
        }
      }
      return this._recordData.changedAttributes();
    }

    /*
      @method adapterWillCommit
      @private
    */
    adapterWillCommit() {
      this._recordData.willCommit();
      this.send('willCommit');
    }

    /*
      @method adapterDidDirty
      @private
    */
    adapterDidDirty() {
      this.send('becomeDirty');
    }

    /*
      @method send
      @private
      @param {String} name
      @param {Object} context
    */
    send(name, context) {
      var currentState = this.currentState;
      if (!currentState[name]) {
        this._unhandledEvent(currentState, name, context);
      }
      return currentState[name](this, context);
    }
    manyArrayRecordAdded(key) {
      if (this.hasRecord) {
        {
          this._record.notifyHasManyAdded(key);
        }
      }
    }
    notifyHasManyChange(key) {
      if (this.hasRecord) {
        {
          var manyArray = this._manyArrayCache[key];
          if (manyArray) {
            // TODO: this will "resurrect" previously unloaded records
            // see test '1:many async unload many side'
            //  in `tests/integration/records/unload-test.js`
            //  probably we don't want to retrieve latest eagerly when notifyhasmany changed
            //  but rather lazily when someone actually asks for a manyarray
            //
            //  that said, also not clear why we haven't moved this to retainedmanyarray so maybe that's the bit that's just not working
            manyArray.retrieveLatest();
          }
        }
      }
    }
    notifyBelongsToChange(key) {
      if (this.hasRecord) {
        {
          this._record.notifyBelongsToChange(key, this._record);
        }
      }
    }
    hasManyRemovalCheck(key) {
      var manyArray = this._manyArrayCache[key] || this._retainedManyArrayCache[key];
      var didRemoveUnloadedModel = false;
      if (manyArray) {
        didRemoveUnloadedModel = manyArray.removeUnloadedInternalModel();
        if (this._manyArrayCache[key] && didRemoveUnloadedModel) {
          this._retainedManyArrayCache[key] = this._manyArrayCache[key];
          delete this._manyArrayCache[key];
        }
      }
      return didRemoveUnloadedModel;
    }
    notifyPropertyChange(key) {
      if (this.hasRecord) {
        {
          this._record.notifyPropertyChange(key);
        }
      }
      {
        var manyArray = this._manyArrayCache[key] || this._retainedManyArrayCache[key];
        if (manyArray) {
          var didRemoveUnloadedModel = manyArray.removeUnloadedInternalModel();
          if (this._manyArrayCache[key] && didRemoveUnloadedModel) {
            this._retainedManyArrayCache[key] = this._manyArrayCache[key];
            delete this._manyArrayCache[key];
          }
        }
      }
    }
    notifyStateChange(key) {
      ( Ember.assert('Cannot notify state change if Record Data State flag is not on', !!false));
      if (this.hasRecord) {
        {
          if (!key || key === 'isNew') {
            this.getRecord().notifyPropertyChange('isNew');
          }
          if (!key || key === 'isDeleted') {
            this.getRecord().notifyPropertyChange('isDeleted');
          }
        }
      }
      if (!key || key === 'isDeletionCommitted') {
        this.updateRecordArrays();
      }
    }
    didCreateRecord() {
      this._recordData.clientDidCreate();
    }
    rollbackAttributes() {
      var dirtyKeys = this._recordData.rollbackAttributes();
      if (Ember.get(this, 'isError')) {
        this.didCleanError();
      }
      this.send('rolledBack');
      if (this._record && dirtyKeys && dirtyKeys.length > 0) {
        this._record._notifyProperties(dirtyKeys);
      }
    }

    /*
      @method transitionTo
      @private
      @param {String} name
    */
    transitionTo(name) {
      // POSSIBLE TODO: Remove this code and replace with
      // always having direct reference to state objects

      var pivotName = extractPivotName(name);
      var state = this.currentState;
      var transitionMapId = `${state.stateName}->${name}`;
      do {
        if (state.exit) {
          state.exit(this);
        }
        state = state.parentState;
      } while (!state[pivotName]);
      var setups;
      var enters;
      var i;
      var l;
      var map = TransitionChainMap[transitionMapId];
      if (map) {
        setups = map.setups;
        enters = map.enters;
        state = map.state;
      } else {
        setups = [];
        enters = [];
        var path = splitOnDot(name);
        for (i = 0, l = path.length; i < l; i++) {
          state = state[path[i]];
          if (state.enter) {
            enters.push(state);
          }
          if (state.setup) {
            setups.push(state);
          }
        }
        TransitionChainMap[transitionMapId] = {
          setups,
          enters,
          state
        };
      }
      for (i = 0, l = enters.length; i < l; i++) {
        enters[i].enter(this);
      }
      this.currentState = state;
      if (this.hasRecord) {
        Ember.set(this._record, 'currentState', state);
      }
      for (i = 0, l = setups.length; i < l; i++) {
        setups[i].setup(this);
      }
    }
    _unhandledEvent(state, name, context) {
      var errorMessage = 'Attempted to handle event `' + name + '` ';
      errorMessage += 'on ' + String(this) + ' while in state ';
      errorMessage += state.stateName + '. ';
      if (context !== undefined) {
        errorMessage += 'Called with ' + Ember.inspect(context) + '.';
      }
      throw new Ember.Error(errorMessage);
    }
    triggerLater() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      if (this._deferredTriggers.push(args) !== 1) {
        return;
      }
      this.store._updateInternalModel(this);
    }
    _triggerDeferredTriggers() {
      //TODO: Before 1.0 we want to remove all the events that happen on the pre materialized record,
      //but for now, we queue up all the events triggered before the record was materialized, and flush
      //them once we have the record
      if (!this.hasRecord) {
        return;
      }
      var triggers = this._deferredTriggers;
      var record = this._record;
      var trigger = record.trigger;
      // TODO Igor make nicer check
      if (trigger && typeof trigger === 'function') {
        for (var i = 0, l = triggers.length; i < l; i++) {
          var eventName = triggers[i];
          trigger.apply(record, eventName);
        }
      }
      triggers.length = 0;
    }
    removeFromInverseRelationships(isNew) {
      if (isNew === void 0) {
        isNew = false;
      }
      this._recordData.removeFromInverseRelationships(isNew);
    }

    /*
      When a find request is triggered on the store, the user can optionally pass in
      attributes and relationships to be preloaded. These are meant to behave as if they
      came back from the server, except the user obtained them out of band and is informing
      the store of their existence. The most common use case is for supporting client side
      nested URLs, such as `/posts/1/comments/2` so the user can do
      `store.findRecord('comment', 2, { preload: { post: 1 } })` without having to fetch the post.
       Preloaded data can be attributes and relationships passed in either as IDs or as actual
      models.
       @method preloadData
      @private
      @param {Object} preload
    */
    preloadData(preload) {
      var jsonPayload = {};
      //TODO(Igor) consider the polymorphic case
      Object.keys(preload).forEach(key => {
        var preloadValue = Ember.get(preload, key);
        var relationshipMeta = this.modelClass.metaForProperty(key);
        if (relationshipMeta.isRelationship) {
          if (!jsonPayload.relationships) {
            jsonPayload.relationships = {};
          }
          jsonPayload.relationships[key] = this._preloadRelationship(key, preloadValue);
        } else {
          if (!jsonPayload.attributes) {
            jsonPayload.attributes = {};
          }
          jsonPayload.attributes[key] = preloadValue;
        }
      });
      this._recordData.pushData(jsonPayload);
    }
    _preloadRelationship(key, preloadValue) {
      var relationshipMeta = this.modelClass.metaForProperty(key);
      var modelClass = relationshipMeta.type;
      var data;
      if (relationshipMeta.kind === 'hasMany') {
        ( !(Array.isArray(preloadValue)) && Ember.assert('You need to pass in an array to set a hasMany property on a record', Array.isArray(preloadValue)));
        data = preloadValue.map(value => this._convertPreloadRelationshipToJSON(value, modelClass));
      } else {
        data = this._convertPreloadRelationshipToJSON(preloadValue, modelClass);
      }
      return {
        data
      };
    }
    _convertPreloadRelationshipToJSON(value, modelClass) {
      if (typeof value === 'string' || typeof value === 'number') {
        return {
          type: modelClass,
          id: value
        };
      }
      var internalModel;
      if (value._internalModel) {
        internalModel = value._internalModel;
      } else {
        internalModel = value;
      }
      // TODO IGOR DAVID assert if no id is present
      return {
        type: internalModel.modelName,
        id: internalModel.id
      };
    }

    /*
      Used to notify the store to update FilteredRecordArray membership.
       @method updateRecordArrays
      @private
    */
    updateRecordArrays() {
      this.store.recordArrayManager.recordDidChange(this.identifier);
    }
    setId(id) {
      var didChange = id !== this._id;
      this._id = id;
      Ember.set(this, '_tag', this._tag + 1);
      if (didChange && id !== null) {
        this.store.setRecordId(this.modelName, id, this.clientId);
        // internal set of ID to get it to RecordData from DS.Model
        if (this._recordData.__setId) {
          this._recordData.__setId(id);
        }
      }
      if (didChange && this.hasRecord) {
        {
          this.notifyPropertyChange('id');
        }
      }
    }
    didError(error) {
      {
        this.error = error;
        this.isError = true;
        if (this.hasRecord) {
          this._record.setProperties({
            isError: true,
            adapterError: error
          });
        }
      }
    }
    didCleanError() {
      {
        this.error = null;
        this.isError = false;
        if (this.hasRecord) {
          this._record.setProperties({
            isError: false,
            adapterError: null
          });
        }
      }
    }

    /*
      If the adapter did not return a hash in response to a commit,
      merge the changed attributes and relationships into the existing
      saved data.
       @method adapterDidCommit
    */
    adapterDidCommit(data) {
      this.didCleanError();
      var changedKeys = this._recordData.didCommit(data);
      this.send('didCommit');
      this.updateRecordArrays();
      if (!data) {
        return;
      }
      {
        this._record._notifyProperties(changedKeys);
      }
    }
    addErrorMessageToAttribute(attribute, message) {
      Ember.get(this.getRecord(), 'errors')._add(attribute, message);
    }
    removeErrorMessageFromAttribute(attribute) {
      Ember.get(this.getRecord(), 'errors')._remove(attribute);
    }
    clearErrorMessages() {
      Ember.get(this.getRecord(), 'errors')._clear();
    }
    hasErrors() {
      {
        var _errors = Ember.get(this.getRecord(), 'errors');
        return _errors.get('length') > 0;
      }
    }

    // FOR USE DURING COMMIT PROCESS

    /*
      @method adapterDidInvalidate
      @private
    */
    adapterDidInvalidate(parsedErrors, error) {
      {
        var _attribute;
        for (_attribute in parsedErrors) {
          if (hasOwnProperty.call(parsedErrors, _attribute)) {
            this.addErrorMessageToAttribute(_attribute, parsedErrors[_attribute]);
          }
        }
        this.send('becameInvalid');
        this._recordData.commitWasRejected();
      }
    }
    notifyErrorsChange() {
      var invalidErrors;
      if (this._recordData.getErrors) {
        invalidErrors = this._recordData.getErrors(this.identifier) || [];
      } else {
        return;
      }
      this.notifyInvalidErrorsChange(invalidErrors);
    }
    notifyInvalidErrorsChange(jsonApiErrors) {
      {
        this.getRecord().invalidErrorsChanged(jsonApiErrors);
      }
    }

    /*
      @method adapterDidError
      @private
    */
    adapterDidError(error) {
      this.send('becameError');
      this.didError(error);
      this._recordData.commitWasRejected();
    }
    toString() {
      return `<${this.modelName}:${this.id}>`;
    }
    referenceFor(kind, name) {
      var reference = this.references[name];
      if (!reference) {
        // TODO IGOR AND DAVID REFACTOR
        var relationship = relationshipStateFor$1(this, name);
        if ( kind) {
          var modelName = this.modelName;
          ( !(!!relationship) && Ember.assert(`There is no ${kind} relationship named '${name}' on a model of modelClass '${modelName}'`, !!relationship));
          var actualRelationshipKind = relationship.relationshipMeta.kind;
          ( !(actualRelationshipKind === kind) && Ember.assert(`You tried to get the '${name}' relationship on a '${modelName}' via record.${kind}('${name}'), but the relationship is of kind '${actualRelationshipKind}'. Use record.${actualRelationshipKind}('${name}') instead.`, actualRelationshipKind === kind));
        }
        var relationshipKind = relationship.relationshipMeta.kind;
        var identifierOrInternalModel = this.identifier;
        if (relationshipKind === 'belongsTo') {
          reference = new BelongsToReference(this.store, identifierOrInternalModel, relationship, name);
        } else if (relationshipKind === 'hasMany') {
          reference = new HasManyReference(this.store, identifierOrInternalModel, relationship, name);
        }
        this.references[name] = reference;
      }
      return reference;
    }
  }

  // in production code, this is only accesssed in `record-array-manager`
  // if REMOVE_RECORD_ARRAY_MANAGER_LEGACY_COMPAT is also false
  {
    Object.defineProperty(InternalModel.prototype, '_recordArrays', {
      get() {
        return recordArraysForIdentifier(this.identifier);
      }
    });
  }
  function handleCompletedRelationshipRequest(internalModel, key, relationship, value, error) {
    delete internalModel._relationshipPromisesCache[key];
    relationship.setShouldForceReload(false);
    if (error) {
      relationship.setHasFailedLoadAttempt(true);
      var proxy = internalModel._relationshipProxyCache[key];
      // belongsTo relationships are sometimes unloaded
      // when a load fails, in this case we need
      // to make sure that we aren't proxying
      // to destroyed content
      // for the sync belongsTo reload case there will be no proxy
      // for the async reload case there will be no proxy if the ui
      // has never been accessed
      if (proxy && relationship.kind === 'belongsTo') {
        if (proxy.content && proxy.content.isDestroying) {
          proxy.set('content', null);
        }
      }
      throw error;
    }
    relationship.setHasFailedLoadAttempt(false);
    // only set to not stale if no error is thrown
    relationship.setRelationshipIsStale(false);
    return value;
  }
  function assertRecordsPassedToHasMany(records) {
    // TODO only allow native arrays
    ( !(Array.isArray(records) || Ember.Array.detect(records)) && Ember.assert(`You must pass an array of records to set a hasMany relationship`, Array.isArray(records) || Ember.Array.detect(records)));
    ( !(function () {
      return Ember.A(records).every(record => hasOwnProperty.call(record, '_internalModel') === true);
    }()) && Ember.assert(`All elements of a hasMany relationship must be instances of Model, you passed ${Ember.inspect(records)}`, function () {
      return Ember.A(records).every(record => hasOwnProperty.call(record, '_internalModel') === true);
    }()));
  }
  function extractRecordDatasFromRecords(records) {
    return records.map(extractRecordDataFromRecord);
  }
  function extractRecordDataFromRecord(recordOrPromiseRecord) {
    if (!recordOrPromiseRecord) {
      return null;
    }
    if (recordOrPromiseRecord.then) {
      var content = recordOrPromiseRecord.get && recordOrPromiseRecord.get('content');
      ( !(content !== undefined) && Ember.assert('You passed in a promise that did not originate from an EmberData relationship. You can only pass promises that come from a belongsTo or hasMany relationship to the get call.', content !== undefined));
      return content ? recordDataFor(content) : null;
    }
    return recordDataFor(recordOrPromiseRecord);
  }

  var AvailableShims = new WeakMap();
  function getShimClass(store, modelName) {
    var shims = AvailableShims.get(store);
    if (shims === undefined) {
      shims = Object.create(null);
      AvailableShims.set(store, shims);
    }
    var shim = shims[modelName];
    if (shim === undefined) {
      shim = shims[modelName] = new ShimModelClass(store, modelName);
    }
    return shim;
  }

  // Mimics the static apis of DSModel
  class ShimModelClass {
    // TODO Maybe expose the class here?
    constructor(__store, modelName) {
      this.__store = __store;
      this.modelName = modelName;
    }
    get fields() {
      var attrs = this.__store._attributesDefinitionFor(this.modelName);
      var relationships = this.__store._relationshipsDefinitionFor(this.modelName);
      var fields = new Map();
      Object.keys(attrs).forEach(key => fields.set(key, 'attribute'));
      Object.keys(relationships).forEach(key => fields.set(key, relationships[key].kind));
      return fields;
    }
    get attributes() {
      var attrs = this.__store._attributesDefinitionFor(this.modelName);
      return new Map(Object.entries(attrs));
    }
    get relationshipsByName() {
      var relationships = this.__store._relationshipsDefinitionFor(this.modelName);
      return new Map(Object.entries(relationships));
    }
    eachAttribute(callback, binding) {
      var attrDefs = this.__store._attributesDefinitionFor(this.modelName);
      Object.keys(attrDefs).forEach(key => {
        callback.call(binding, key, attrDefs[key]);
      });
    }
    eachRelationship(callback, binding) {
      var relationshipDefs = this.__store._relationshipsDefinitionFor(this.modelName);
      Object.keys(relationshipDefs).forEach(key => {
        callback.call(binding, key, relationshipDefs[key]);
      });
    }
    eachTransformedAttribute(callback, binding) {
      var relationshipDefs = this.__store._relationshipsDefinitionFor(this.modelName);
      Object.keys(relationshipDefs).forEach(key => {
        if (relationshipDefs[key].type) {
          callback.call(binding, key, relationshipDefs[key]);
        }
      });
    }
  }

  /**
    @module @ember-data/store
  */

  function payloadIsNotBlank(adapterPayload) {
    if (Array.isArray(adapterPayload)) {
      return true;
    } else {
      return Object.keys(adapterPayload || {}).length;
    }
  }
  function _find(adapter, store, modelClass, id, internalModel, options) {
    var snapshot = internalModel.createSnapshot(options);
    var {
      modelName
    } = internalModel;
    var promise = Ember.RSVP.Promise.resolve().then(() => {
      return adapter.findRecord(store, modelClass, id, snapshot);
    });
    var label = `DS: Handle Adapter#findRecord of '${modelName}' with id: '${id}'`;
    var {
      identifier
    } = internalModel;
    promise = guardDestroyedStore(promise, store, label);
    return promise.then(adapterPayload => {
      ( !(payloadIsNotBlank(adapterPayload)) && Ember.assert(`You made a 'findRecord' request for a '${modelName}' with id '${id}', but the adapter's response did not have any data`, payloadIsNotBlank(adapterPayload)));
      var serializer = store.serializerFor(modelName);
      var payload = normalizeResponseHelper(serializer, store, modelClass, adapterPayload, id, 'findRecord');
      ( !(!Array.isArray(payload.data)) && Ember.assert(`Ember Data expected the primary data returned from a 'findRecord' response to be an object but instead it found an array.`, !Array.isArray(payload.data)));
      ( Ember.warn(`You requested a record of type '${modelName}' with id '${id}' but the adapter returned a payload with primary data having an id of '${payload.data.id}'. Use 'store.findRecord()' when the requested id is the same as the one returned by the adapter. In other cases use 'store.queryRecord()' instead.`, coerceId(payload.data.id) === coerceId(id), {
        id: 'ds.store.findRecord.id-mismatch'
      })); // ensure that regardless of id returned we assign to the correct record
      payload.data.lid = identifier.lid;
      return store._push(payload);
    }, error => {
      internalModel.notFound();
      if (internalModel.isEmpty()) {
        internalModel.unloadRecord();
      }
      throw error;
    }, `DS: Extract payload of '${modelName}'`);
  }
  function _findMany(adapter, store, modelName, ids, internalModels, optionsMap) {
    var snapshots = Ember.A(internalModels.map(internalModel => internalModel.createSnapshot(optionsMap.get(internalModel))));
    var modelClass = store.modelFor(modelName); // `adapter.findMany` gets the modelClass still
    var promise = adapter.findMany(store, modelClass, ids, snapshots);
    var label = `DS: Handle Adapter#findMany of '${modelName}'`;
    if (promise === undefined) {
      throw new Error('adapter.findMany returned undefined, this was very likely a mistake');
    }
    promise = guardDestroyedStore(promise, store, label);
    return promise.then(adapterPayload => {
      ( !(payloadIsNotBlank(adapterPayload)) && Ember.assert(`You made a 'findMany' request for '${modelName}' records with ids '[${ids}]', but the adapter's response did not have any data`, payloadIsNotBlank(adapterPayload)));
      var serializer = store.serializerFor(modelName);
      var payload = normalizeResponseHelper(serializer, store, modelClass, adapterPayload, null, 'findMany');
      return store._push(payload);
    }, null, `DS: Extract payload of ${modelName}`);
  }
  function iterateData(data, fn) {
    if (Array.isArray(data)) {
      return data.map(fn);
    } else {
      return fn(data);
    }
  }

  // sync
  // iterate over records in payload.data
  // for each record
  //   assert that record.relationships[inverse] is either undefined (so we can fix it)
  //     or provide a data: {id, type} that matches the record that requested it
  //   return the relationship data for the parent
  function syncRelationshipDataFromLink(store, payload, parentInternalModel, relationship) {
    // ensure the right hand side (incoming payload) points to the parent record that
    // requested this relationship
    var relationshipData = iterateData(payload.data, (data, index) => {
      var {
        id,
        type
      } = data;
      ensureRelationshipIsSetToParent(data, parentInternalModel, store, relationship, index);
      return {
        id,
        type
      };
    });

    // now, push the left hand side (the parent record) to ensure things are in sync, since
    // the payload will be pushed with store._push
    var parentPayload = {
      id: parentInternalModel.id,
      type: parentInternalModel.modelName,
      relationships: {
        [relationship.key]: {
          meta: payload.meta,
          links: payload.links,
          data: relationshipData
        }
      }
    };
    if (!Array.isArray(payload.included)) {
      payload.included = [];
    }
    payload.included.push(parentPayload);
    return payload;
  }
  function ensureRelationshipIsSetToParent(payload, parentInternalModel, store, parentRelationship, index) {
    var {
      id,
      type
    } = payload;
    if (!payload.relationships) {
      payload.relationships = {};
    }
    var {
      relationships
    } = payload;
    var inverse = getInverse(store, parentInternalModel, parentRelationship, type);
    if (inverse) {
      var {
        inverseKey,
        kind
      } = inverse;
      var relationshipData = relationships[inverseKey] && relationships[inverseKey].data;
      if ( typeof relationshipData !== 'undefined' && !relationshipDataPointsToParent(relationshipData, parentInternalModel)) {
        var inspect = function inspect(thing) {
          return `'${JSON.stringify(thing)}'`;
        };
        var quotedType = inspect(type);
        var quotedInverse = inspect(inverseKey);
        var expected = inspect({
          id: parentInternalModel.id,
          type: parentInternalModel.modelName
        });
        var expectedModel = `${parentInternalModel.modelName}:${parentInternalModel.id}`;
        var got = inspect(relationshipData);
        var prefix = typeof index === 'number' ? `data[${index}]` : `data`;
        var path = `${prefix}.relationships.${inverseKey}.data`;
        var other = relationshipData ? `<${relationshipData.type}:${relationshipData.id}>` : null;
        var relationshipFetched = `${expectedModel}.${parentRelationship.kind}("${parentRelationship.name}")`;
        var includedRecord = `<${type}:${id}>`;
        var message = [`Encountered mismatched relationship: Ember Data expected ${path} in the payload from ${relationshipFetched} to include ${expected} but got ${got} instead.\n`, `The ${includedRecord} record loaded at ${prefix} in the payload specified ${other} as its ${quotedInverse}, but should have specified ${expectedModel} (the record the relationship is being loaded from) as its ${quotedInverse} instead.`, `This could mean that the response for ${relationshipFetched} may have accidentally returned ${quotedType} records that aren't related to ${expectedModel} and could be related to a different ${parentInternalModel.modelName} record instead.`, `Ember Data has corrected the ${includedRecord} record's ${quotedInverse} relationship to ${expectedModel} so that ${relationshipFetched} will include ${includedRecord}.`, `Please update the response from the server or change your serializer to either ensure that the response for only includes ${quotedType} records that specify ${expectedModel} as their ${quotedInverse}, or omit the ${quotedInverse} relationship from the response.`].join('\n');

        // this should eventually throw instead of deprecating.
        ( Ember.deprecate(message + '\n', false, {
          id: 'mismatched-inverse-relationship-data-from-payload',
          until: '3.8'
        }));
      }
      if (kind !== 'hasMany' || typeof relationshipData !== 'undefined') {
        relationships[inverseKey] = relationships[inverseKey] || {};
        relationships[inverseKey].data = fixRelationshipData(relationshipData, kind, parentInternalModel);
      }
    }
  }
  function getInverse(store, parentInternalModel, parentRelationship, type) {
    return recordDataFindInverseRelationshipInfo(store, parentInternalModel, parentRelationship, type);
  }
  function recordDataFindInverseRelationshipInfo(_ref, parentInternalModel, parentRelationship, type) {
    var {
      _storeWrapper
    } = _ref;
    var {
      name: lhs_relationshipName
    } = parentRelationship;
    var {
      modelName
    } = parentInternalModel;
    var inverseKey = _storeWrapper.inverseForRelationship(modelName, lhs_relationshipName);
    if (inverseKey) {
      var {
        meta: {
          kind
        }
      } = _storeWrapper.relationshipsDefinitionFor(type)[inverseKey];
      return {
        inverseKey,
        kind
      };
    }
  }
  function relationshipDataPointsToParent(relationshipData, internalModel) {
    if (relationshipData === null) {
      return false;
    }
    if (Array.isArray(relationshipData)) {
      if (relationshipData.length === 0) {
        return false;
      }
      for (var i = 0; i < relationshipData.length; i++) {
        var entry = relationshipData[i];
        if (validateRelationshipEntry(entry, internalModel)) {
          return true;
        }
      }
    } else {
      return validateRelationshipEntry(relationshipData, internalModel);
    }
    return false;
  }
  function fixRelationshipData(relationshipData, relationshipKind, _ref2) {
    var {
      id,
      modelName
    } = _ref2;
    var parentRelationshipData = {
      id,
      type: modelName
    };
    var payload;
    if (relationshipKind === 'hasMany') {
      payload = relationshipData || [];
      payload.push(parentRelationshipData);
    } else {
      payload = relationshipData || {};
      Ember.assign(payload, parentRelationshipData);
    }
    return payload;
  }
  function validateRelationshipEntry(_ref3, _ref4) {
    var {
      id
    } = _ref3;
    var {
      id: parentModelID
    } = _ref4;
    return id && id.toString() === parentModelID;
  }
  function _findHasMany(adapter, store, internalModel, link, relationship, options) {
    var snapshot = internalModel.createSnapshot(options);
    var modelClass = store.modelFor(relationship.type);
    var useLink = !link || typeof link === 'string';
    var relatedLink = useLink ? link : link.href;
    var promise = adapter.findHasMany(store, snapshot, relatedLink, relationship);
    var label = `DS: Handle Adapter#findHasMany of '${internalModel.modelName}' : '${relationship.type}'`;
    promise = guardDestroyedStore(promise, store, label);
    promise = _guard(promise, _bind(_objectIsAlive, internalModel));
    return promise.then(adapterPayload => {
      ( !(payloadIsNotBlank(adapterPayload)) && Ember.assert(`You made a 'findHasMany' request for a ${internalModel.modelName}'s '${relationship.key}' relationship, using link '${link}' , but the adapter's response did not have any data`, payloadIsNotBlank(adapterPayload)));
      var serializer = store.serializerFor(relationship.type);
      var payload = normalizeResponseHelper(serializer, store, modelClass, adapterPayload, null, 'findHasMany');
      payload = syncRelationshipDataFromLink(store, payload, internalModel, relationship);
      var internalModelArray = store._push(payload);
      return internalModelArray;
    }, null, `DS: Extract payload of '${internalModel.modelName}' : hasMany '${relationship.type}'`);
  }
  function _findBelongsTo(adapter, store, internalModel, link, relationship, options) {
    var snapshot = internalModel.createSnapshot(options);
    var modelClass = store.modelFor(relationship.type);
    var useLink = !link || typeof link === 'string';
    var relatedLink = useLink ? link : link.href;
    var promise = adapter.findBelongsTo(store, snapshot, relatedLink, relationship);
    var label = `DS: Handle Adapter#findBelongsTo of ${internalModel.modelName} : ${relationship.type}`;
    promise = guardDestroyedStore(promise, store, label);
    promise = _guard(promise, _bind(_objectIsAlive, internalModel));
    return promise.then(adapterPayload => {
      var serializer = store.serializerFor(relationship.type);
      var payload = normalizeResponseHelper(serializer, store, modelClass, adapterPayload, null, 'findBelongsTo');
      if (!payload.data) {
        return null;
      }
      payload = syncRelationshipDataFromLink(store, payload, internalModel, relationship);
      return store._push(payload);
    }, null, `DS: Extract payload of ${internalModel.modelName} : ${relationship.type}`);
  }
  function _findAll(adapter, store, modelName, options) {
    var modelClass = store.modelFor(modelName); // adapter.findAll depends on the class
    var recordArray = store.peekAll(modelName);
    var snapshotArray = recordArray._createSnapshot(options);
    var promise = Ember.RSVP.Promise.resolve().then(() => adapter.findAll(store, modelClass, null, snapshotArray));
    var label = 'DS: Handle Adapter#findAll of ' + modelClass;
    promise = guardDestroyedStore(promise, store, label);
    return promise.then(adapterPayload => {
      ( !(payloadIsNotBlank(adapterPayload)) && Ember.assert(`You made a 'findAll' request for '${modelName}' records, but the adapter's response did not have any data`, payloadIsNotBlank(adapterPayload)));
      var serializer = store.serializerFor(modelName);
      var payload = normalizeResponseHelper(serializer, store, modelClass, adapterPayload, null, 'findAll');
      store._push(payload);
      store._didUpdateAll(modelName);
      return recordArray;
    }, null, 'DS: Extract payload of findAll ${modelName}');
  }
  function _query(adapter, store, modelName, query, recordArray, options) {
    var modelClass = store.modelFor(modelName); // adapter.query needs the class

    recordArray = recordArray || store.recordArrayManager.createAdapterPopulatedRecordArray(modelName, query);
    var promise = Ember.RSVP.Promise.resolve().then(() => adapter.query(store, modelClass, query, recordArray, options));
    var label = `DS: Handle Adapter#query of ${modelName}`;
    promise = guardDestroyedStore(promise, store, label);
    return promise.then(adapterPayload => {
      var serializer = store.serializerFor(modelName);
      var payload = normalizeResponseHelper(serializer, store, modelClass, adapterPayload, null, 'query');
      var internalModels = store._push(payload);
      ( !(Array.isArray(internalModels)) && Ember.assert('The response to store.query is expected to be an array but it was a single record. Please wrap your response in an array or use `store.queryRecord` to query for a single record.', Array.isArray(internalModels)));
      var identifiers = internalModels.map(im => im.identifier);
      if (recordArray) {
        recordArray._setIdentifiers(identifiers, payload);
      } else {
        recordArray = store.recordArrayManager.createAdapterPopulatedRecordArray(modelName, query, identifiers, payload);
      }
      return recordArray;
    }, null, `DS: Extract payload of query ${modelName}`);
  }
  function _queryRecord(adapter, store, modelName, query, options) {
    var modelClass = store.modelFor(modelName); // adapter.queryRecord needs the class
    var promise = Ember.RSVP.Promise.resolve().then(() => adapter.queryRecord(store, modelClass, query, options));
    var label = `DS: Handle Adapter#queryRecord of ${modelName}`;
    promise = guardDestroyedStore(promise, store, label);
    return promise.then(adapterPayload => {
      var serializer = store.serializerFor(modelName);
      var payload = normalizeResponseHelper(serializer, store, modelClass, adapterPayload, null, 'queryRecord');
      ( !(!Array.isArray(payload.data)) && Ember.assert(`Expected the primary data returned by the serializer for a 'queryRecord' response to be a single object or null but instead it was an array.`, !Array.isArray(payload.data), {
        id: 'ds.store.queryRecord-array-response'
      }));
      return store._push(payload);
    }, null, `DS: Extract payload of queryRecord ${modelName}`);
  }

  /**
    @module @ember-data/store
  */

  /**
   * Use this brand to assign a string key to an interface
   * for mapping the interface to a tightly coupled internal
   * class implementation.
   *
   * This allows us to expose the interface publicly but
   * seamlessly upgrade these interfaces for our own use
   * internally when internal methods and properties are
   * needed.
   *
   * @internal
   */

  var BRAND_SYMBOL = symbol('DEBUG-ts-brand');

  /**
    @module @ember-data/store
  */

  /**
   * Maps public interfaces to internal class implementations
   *
   * @internal
   */

  /**
   * Casts a public interface to the matching internal class implementation
   *
   * @internal
   */
  function upgradeForInternal(external) {
    return external;
  }

  /**
    @module @ember-data/store
  */

  class RecordDataStoreWrapper {
    constructor(_store) {
      this[BRAND_SYMBOL] = void 0;
      this._willUpdateManyArrays = void 0;
      this._pendingManyArrayUpdates = void 0;
      this._store = _store;
      this._willUpdateManyArrays = false;
      this._pendingManyArrayUpdates = [];
    }
    get identifierCache() {
      return identifierCacheFor(this._store);
    }

    /**
     * Exists so that DefaultRecordData can check for model types
     * in DEBUG for relationships. Should be refactored away.
     *
     * @internal
     */
    _hasModelFor(type) {
      return this._store._hasModelFor(type);
    }

    /**
     * @internal
     */
    _scheduleManyArrayUpdate(identifier, key) {
      var pending = this._pendingManyArrayUpdates = this._pendingManyArrayUpdates || [];
      pending.push(identifier, key);
      if (this._willUpdateManyArrays === true) {
        return;
      }
      this._willUpdateManyArrays = true;
      var backburner = this._store._backburner;
      backburner.join(() => {
        backburner.schedule('syncRelationships', this, this._flushPendingManyArrayUpdates);
      });
    }
    notifyErrorsChange(type, id, lid) {
      var resource = constructResource(type, id, lid);
      var identifier = identifierCacheFor(this._store).getOrCreateRecordIdentifier(resource);
      var internalModel = internalModelFactoryFor(this._store).peek(identifier);
      if (internalModel) {
        internalModel.notifyErrorsChange();
      }
    }
    _flushPendingManyArrayUpdates() {
      if (this._willUpdateManyArrays === false) {
        return;
      }
      var pending = this._pendingManyArrayUpdates;
      this._pendingManyArrayUpdates = [];
      this._willUpdateManyArrays = false;
      var factory = internalModelFactoryFor(this._store);
      for (var i = 0; i < pending.length; i += 2) {
        var identifier = pending[i];
        var _key = pending[i + 1];
        var internalModel = factory.peek(identifier);
        if (internalModel) {
          internalModel.notifyHasManyChange(_key);
        }
      }
    }
    attributesDefinitionFor(type) {
      return this._store._attributesDefinitionFor(type);
    }
    relationshipsDefinitionFor(type) {
      return this._store._relationshipsDefinitionFor(type);
    }
    inverseForRelationship(type, key) {
      var modelClass = this._store.modelFor(type);
      var definition = upgradeForInternal(this.relationshipsDefinitionFor(type)[key]);
      {
        return definition._inverseKey(this._store, modelClass);
      }
    }
    inverseIsAsyncForRelationship(type, key) {
      var modelClass = this._store.modelFor(type);
      var definition = upgradeForInternal(this.relationshipsDefinitionFor(type)[key]);
      {
        return definition._inverseIsAsync(this._store, modelClass);
      }
    }
    notifyPropertyChange(type, id, lid, key) {
      var resource = constructResource(type, id, lid);
      var identifier = identifierCacheFor(this._store).getOrCreateRecordIdentifier(resource);
      var internalModel = internalModelFactoryFor(this._store).peek(identifier);
      if (internalModel) {
        internalModel.notifyPropertyChange(key);
      }
    }
    notifyHasManyChange(type, id, lid, key) {
      var resource = constructResource(type, id, lid);
      var identifier = identifierCacheFor(this._store).getOrCreateRecordIdentifier(resource);
      this._scheduleManyArrayUpdate(identifier, key);
    }
    notifyBelongsToChange(type, id, lid, key) {
      var resource = constructResource(type, id, lid);
      var identifier = identifierCacheFor(this._store).getOrCreateRecordIdentifier(resource);
      var internalModel = internalModelFactoryFor(this._store).peek(identifier);
      if (internalModel) {
        internalModel.notifyBelongsToChange(key);
      }
    }
    notifyStateChange(type, id, lid, key) {
      var resource = constructResource(type, id, lid);
      var identifier = identifierCacheFor(this._store).getOrCreateRecordIdentifier(resource);
      var internalModel = internalModelFactoryFor(this._store).peek(identifier);
      if (internalModel) {
        internalModel.notifyStateChange(key);
      }
    }
    recordDataFor(type, id, lid) {
      var identifier;
      var isCreate = false;
      if (!id && !lid) {
        isCreate = true;
        identifier = {
          type
        };
      } else {
        var resource = constructResource(type, id, lid);
        identifier = identifierCacheFor(this._store).getOrCreateRecordIdentifier(resource);
      }
      return this._store.recordDataFor(identifier, isCreate);
    }
    setRecordId(type, id, lid) {
      this._store.setRecordId(type, id, lid);
    }
    isRecordInUse(type, id, lid) {
      var resource = constructResource(type, id, lid);
      var identifier = identifierCacheFor(this._store).getOrCreateRecordIdentifier(resource);
      var internalModel = internalModelFactoryFor(this._store).peek(identifier);
      if (!internalModel) {
        return false;
      }
      return internalModel.isRecordInUse();
    }
    disconnectRecord(type, id, lid) {
      var resource = constructResource(type, id, lid);
      var identifier = identifierCacheFor(this._store).getOrCreateRecordIdentifier(resource);
      var internalModel = internalModelFactoryFor(this._store).peek(identifier);
      if (internalModel) {
        internalModel.destroyFromRecordData();
      }
    }
  }

  var _RecordData;
  var emberRun$2 = Ember.run.backburner;
  var {
    ENV
  } = Ember;
  var RECORD_REFERENCES = new WeakMap();
  function freeze$1(obj) {
    if (typeof Object.freeze === 'function') {
      return Object.freeze(obj);
    }
    return obj;
  }
  function deprecateTestRegistration(factoryType, factoryName) {
    ( Ember.deprecate(`You looked up the ${factoryType} "${factoryName}" but it was not found. Likely this means you are using a legacy ember-qunit moduleFor helper. Add "needs: ['${factoryType}:${factoryName}']", "integration: true", or refactor to modern syntax to resolve this deprecation.`, false, {
      id: 'ember-data:-legacy-test-registrations',
      until: '3.17'
    }));
  }
  /**
    The store contains all of the data for records loaded from the server.
    It is also responsible for creating instances of `Model` that wrap
    the individual data for a record, so that they can be bound to in your
    Handlebars templates.

    Define your application's store like this:

    ```app/services/store.js
    import Store from '@ember-data/store';

    export default class MyStore extends Store {}
    ```

    Most Ember.js applications will only have a single `Store` that is
    automatically created by their `Ember.Application`.

    You can retrieve models from the store in several ways. To retrieve a record
    for a specific id, use `Store`'s `findRecord()` method:

    ```javascript
    store.findRecord('person', 123).then(function (person) {
    });
    ```

    By default, the store will talk to your backend using a standard
    REST mechanism. You can customize how the store talks to your
    backend by specifying a custom adapter:

    ```app/adapters/application.js
    import Adapter from '@ember-data/adapter';

    export default class ApplicationAdapter extends Adapter {
    }
    ```

    You can learn more about writing a custom adapter by reading the `Adapter`
    documentation.

    ### Store createRecord() vs. push() vs. pushPayload()

    The store provides multiple ways to create new record objects. They have
    some subtle differences in their use which are detailed below:

    [createRecord](../classes/Store/methods/createRecord?anchor=createRecord) is used for creating new
    records on the client side. This will return a new record in the
    `created.uncommitted` state. In order to persist this record to the
    backend, you will need to call `record.save()`.

    [push](../classes/Store/methods/push?anchor=push) is used to notify Ember Data's store of new or
    updated records that exist in the backend. This will return a record
    in the `loaded.saved` state. The primary use-case for `store#push` is
    to notify Ember Data about record updates (full or partial) that happen
    outside of the normal adapter methods (for example
    [SSE](http://dev.w3.org/html5/eventsource/) or [Web
    Sockets](http://www.w3.org/TR/2009/WD-websockets-20091222/)).

    [pushPayload](../classes/Store/methods/pushPayload?anchor=pushPayload) is a convenience wrapper for
    `store#push` that will deserialize payloads if the
    Serializer implements a `pushPayload` method.

    Note: When creating a new record using any of the above methods
    Ember Data will update `RecordArray`s such as those returned by
    `store#peekAll()` or `store#findAll()`. This means any
    data bindings or computed properties that depend on the RecordArray
    will automatically be synced to include the new or updated record
    values.

    @class Store
    @extends Ember.Service
  */

  class CoreStore extends Ember.Service {
    /**
      The default adapter to use to communicate to a backend server or
      other persistence layer. This will be overridden by an application
      adapter if present.
       If you want to specify `app/adapters/custom.js` as a string, do:
       ```js
      import Store from '@ember-data/store';
       export default Store.extend({
        constructor() {
          super(...arguments);
          this.adapter = 'custom';
        }
      }
      ```
       @property adapter
      @default '-json-api'
      @type {String}
    */

    /**
    This property returns the adapter, after resolving a possible
    string key.
     If the supplied `adapter` was a class, or a String property
    path resolved to a class, this property will instantiate the
    class.
     This property is cacheable, so the same instance of a specified
    adapter class should be used for the lifetime of the store.
     @property defaultAdapter
    @private
    @return Adapter
    */
    /**
      @method init
      @private
    */
    constructor() {
      super(...arguments);
      /**
       * EmberData specific backburner instance
       */
      this._backburner = backburner;
      this.recordArrayManager = new RecordArrayManager({
        store: this
      });
      this._notificationManager = void 0;
      this._adapterCache = Object.create(null);
      this._serializerCache = Object.create(null);
      this._storeWrapper = new RecordDataStoreWrapper(this);
      /*
        Ember Data uses several specialized micro-queues for organizing
        and coalescing similar async work.
         These queues are currently controlled by a flush scheduled into
        ember-data's custom backburner instance.
        */
      // used for coalescing record save requests
      this._pendingSave = [];
      // used for coalescing relationship updates
      this._updatedRelationships = [];
      // used for coalescing internal model updates
      this._updatedInternalModels = [];
      // used to keep track of all the find requests that need to be coalesced
      this._pendingFetch = new Map();
      this._fetchManager = void 0;
      this._schemaDefinitionService = void 0;
      // DEBUG-only properties
      this._trackedAsyncRequests = void 0;
      this.shouldAssertMethodCallsOnDestroyedStore = false;
      this.shouldTrackAsyncRequests = false;
      this.generateStackTracesForTrackedRequests = false;
      this._trackAsyncRequestStart = void 0;
      this._trackAsyncRequestEnd = void 0;
      this.__asyncWaiter = void 0;
      {
        {
          // support for legacy moduleFor style unit tests
          // that did not include transforms in "needs"
          // or which were not set to integration:true
          // that were relying on ember-test-helpers
          // doing an auto-registration of the transform
          // or us doing one
          var Mapping = {
            date: 'DateTransform',
            boolean: 'BooleanTransform',
            number: 'NumberTransform',
            string: 'StringTransform'
          };
          var shouldWarn = false;
          var owner = Ember.getOwner(this);
          Object.keys(Mapping).forEach(attributeType => {
            var transformFactory = owner.factoryFor(`transform:${attributeType}`);
            if (!transformFactory) {
              // we don't deprecate this because the moduleFor style tests with the closed
              // resolver will be deprecated on their own. When that deprecation completes
              // we can drop this.
              var Transform = require$1(`@ember-data/serializer/-private`)[Mapping[attributeType]];
              owner.register(`transform:${attributeType}`, Transform);
              shouldWarn = true;
            }
          });
          if (shouldWarn) {
            ( Ember.deprecate(`You are relying on the automatic registration of the transforms "date", "number", "boolean", and "string". Likely this means you are using a legacy ember-qunit moduleFor helper. Add "needs: ['transform:date', 'transform:boolean', 'transform:number', 'transform:string']", "integration: true", or refactor to modern syntax to resolve this deprecation.`, false, {
              id: 'ember-data:-legacy-test-registrations',
              until: '3.17'
            }));
          }
        }
        this.shouldAssertMethodCallsOnDestroyedStore = this.shouldAssertMethodCallsOnDestroyedStore || false;
        if (this.shouldTrackAsyncRequests === undefined) {
          this.shouldTrackAsyncRequests = false;
        }
        if (this.generateStackTracesForTrackedRequests === undefined) {
          this.generateStackTracesForTrackedRequests = false;
        }
        this._trackedAsyncRequests = [];
        this._trackAsyncRequestStart = label => {
          var trace = 'set `store.generateStackTracesForTrackedRequests = true;` to get a detailed trace for where this request originated';
          if (this.generateStackTracesForTrackedRequests) {
            try {
              throw new Error(`EmberData TrackedRequest: ${label}`);
            } catch (e) {
              trace = e;
            }
          }
          var token = freeze$1({
            label,
            trace
          });
          this._trackedAsyncRequests.push(token);
          return token;
        };
        this._trackAsyncRequestEnd = token => {
          var index = this._trackedAsyncRequests.indexOf(token);
          if (index === -1) {
            throw new Error(`Attempted to end tracking for the following request but it was not being tracked:\n${token}`);
          }
          this._trackedAsyncRequests.splice(index, 1);
        };
        this.__asyncWaiter = () => {
          var shouldTrack = this.shouldTrackAsyncRequests;
          var tracked = this._trackedAsyncRequests;
          var isSettled = tracked.length === 0;
          return shouldTrack !== true || isSettled;
        };
        Ember.Test.registerWaiter(this.__asyncWaiter);
      }
    }
    getRequestStateService() {
      assertInDebug('RequestService is not available unless the feature flag is on and running on a canary build', false);
    }
    get identifierCache() {
      return identifierCacheFor(this);
    }
    _instantiateRecord(internalModel, modelName, recordData, identifier, properties) {
      assertInDebug('should not be here, custom model class ff error', false);
    }
    _internalDeleteRecord(internalModel) {
      internalModel.deleteRecord();
    }

    // FeatureFlagged in the DSModelStore claas
    _attributesDefinitionFor(modelName, identifier) {
      if (identifier) {
        return this.getSchemaDefinitionService().attributesDefinitionFor(identifier);
      } else {
        return this.getSchemaDefinitionService().attributesDefinitionFor(modelName);
      }
    }
    _relationshipsDefinitionFor(modelName, identifier) {
      if (identifier) {
        return this.getSchemaDefinitionService().relationshipsDefinitionFor(identifier);
      } else {
        return this.getSchemaDefinitionService().relationshipsDefinitionFor(modelName);
      }
    }
    registerSchemaDefinitionService(schema) {
      this._schemaDefinitionService = schema;
    }
    getSchemaDefinitionService() {
      assertInDebug('need to enable CUSTOM_MODEL_CLASS feature flag in order to access SchemaDefinitionService', false);
    }

    // TODO Double check this return value is correct
    _relationshipMetaFor(modelName, id, key) {
      return this._relationshipsDefinitionFor(modelName)[key];
    }
    modelFor(modelName) {
      {
        assertDestroyedStoreOnly(this, 'modelFor');
      }
      return getShimClass(this, modelName);
    }

    // Feature Flagged in DSModelStore
    /*
    Returns whether a ModelClass exists for a given modelName
    This exists for legacy support for the RESTSerializer,
    which due to how it must guess whether a key is a model
    must query for whether a match exists.
     We should investigate an RFC to make this public or removing
    this requirement.
     @private
    */
    _hasModelFor(modelName) {
      ( !(Ember.isPresent(modelName)) && Ember.assert(`You need to pass a model name to the store's hasModelFor method`, Ember.isPresent(modelName)));
      ( !(typeof modelName === 'string') && Ember.assert(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${modelName}`, typeof modelName === 'string'));
      return this.getSchemaDefinitionService().doesTypeExist(modelName);
    }

    // .....................
    // . CREATE NEW RECORD .
    // .....................

    /**
      Create a new record in the current store. The properties passed
      to this method are set on the newly created record.
       To create a new instance of a `Post`:
       ```js
      store.createRecord('post', {
        title: 'Ember is awesome!'
      });
      ```
       To create a new instance of a `Post` that has a relationship with a `User` record:
       ```js
      let user = this.store.peekRecord('user', 1);
      store.createRecord('post', {
        title: 'Ember is awesome!',
        user: user
      });
      ```
       @method createRecord
      @param {String} modelName
      @param {Object} inputProperties a hash of properties to set on the
        newly created record.
      @return {Model} record
    */
    createRecord(modelName, inputProperties) {
      {
        assertDestroyingStore(this, 'createRecord');
      }
      ( !(Ember.isPresent(modelName)) && Ember.assert(`You need to pass a model name to the store's createRecord method`, Ember.isPresent(modelName)));
      ( !(typeof modelName === 'string') && Ember.assert(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${modelName}`, typeof modelName === 'string')); // This is wrapped in a `run.join` so that in test environments users do not need to manually wrap
      //   calls to `createRecord`. The run loop usage here is because we batch the joining and updating
      //   of record-arrays via ember's run loop, not our own.
      //
      //   to remove this, we would need to move to a new `async` API.
      return emberRun$2.join(() => {
        return this._backburner.join(() => {
          var normalizedModelName = normalizeModelName(modelName);
          var properties = Ember.assign({}, inputProperties);

          // If the passed properties do not include a primary key,
          // give the adapter an opportunity to generate one. Typically,
          // client-side ID generators will use something like uuid.js
          // to avoid conflicts.

          if (Ember.isNone(properties.id)) {
            properties.id = this._generateId(normalizedModelName, properties);
          }

          // Coerce ID to a string
          properties.id = coerceId(properties.id);
          var factory = internalModelFactoryFor(this);
          var internalModel = factory.build({
            type: normalizedModelName,
            id: properties.id
          });
          internalModel.loadedData();
          // TODO this exists just to proxy `isNew` to RecordData which is weird
          internalModel.didCreateRecord();
          return internalModel.getRecord(properties);
        });
      });
    }

    /**
      If possible, this method asks the adapter to generate an ID for
      a newly created record.
       @method _generateId
      @private
      @param {String} modelName
      @param {Object} properties from the new record
      @return {String} if the adapter can generate one, an ID
    */
    _generateId(modelName, properties) {
      var adapter = this.adapterFor(modelName);
      if (adapter && adapter.generateIdForRecord) {
        return adapter.generateIdForRecord(this, modelName, properties);
      }
      return null;
    }

    // .................
    // . DELETE RECORD .
    // .................

    /**
      For symmetry, a record can be deleted via the store.
       Example
       ```javascript
      let post = store.createRecord('post', {
        title: 'Ember is awesome!'
      });
       store.deleteRecord(post);
      ```
       @method deleteRecord
      @param {Model} record
    */
    deleteRecord(record) {
      {
        assertDestroyingStore(this, 'deleteRecord');
      }
      {
        record.deleteRecord();
      }
    }

    /**
      For symmetry, a record can be unloaded via the store.
      This will cause the record to be destroyed and freed up for garbage collection.
       Example
       ```javascript
      store.findRecord('post', 1).then(function(post) {
        store.unloadRecord(post);
      });
      ```
       @method unloadRecord
      @param {Model} record
    */
    unloadRecord(record) {
      {
        assertDestroyingStore(this, 'unloadRecord');
      }
      {
        record.unloadRecord();
      }
    }

    // ................
    // . FIND RECORDS .
    // ................

    /**
      @method find
      @param {String} modelName
      @param {String|Integer} id
      @param {Object} options
      @return {Promise} promise
      @private
    */
    find(modelName, id, options) {
      {
        assertDestroyingStore(this, 'find');
      }
      // The default `model` hook in Route calls `find(modelName, id)`,
      // that's why we have to keep this method around even though `findRecord` is
      // the public way to get a record by modelName and id.
      ( !(arguments.length !== 1) && Ember.assert(`Using store.find(type) has been removed. Use store.findAll(modelName) to retrieve all records for a given type.`, arguments.length !== 1));
      ( !(!options) && Ember.assert(`Calling store.find(modelName, id, { preload: preload }) is no longer supported. Use store.findRecord(modelName, id, { preload: preload }) instead.`, !options));
      ( !(arguments.length === 2) && Ember.assert(`You need to pass the model name and id to the store's find method`, arguments.length === 2));
      ( !(typeof id === 'string' || typeof id === 'number') && Ember.assert(`You cannot pass '${id}' as id to the store's find method`, typeof id === 'string' || typeof id === 'number'));
      ( !(typeof id !== 'object') && Ember.assert(`Calling store.find() with a query object is no longer supported. Use store.query() instead.`, typeof id !== 'object'));
      ( !(typeof modelName === 'string') && Ember.assert(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${modelName}`, typeof modelName === 'string'));
      return this.findRecord(modelName, id);
    }

    /**
      This method returns a record for a given type and id combination.
       The `findRecord` method will always resolve its promise with the same
      object for a given type and `id`.
       The `findRecord` method will always return a **promise** that will be
      resolved with the record.
       Example
       ```app/routes/post.js
      import Route from '@ember/routing/route';
       export default class PostRoute extends Route {
        model(params) {
          return this.store.findRecord('post', params.post_id);
        }
      }
      ```
       If the record is not yet available, the store will ask the adapter's `find`
      method to find the necessary data. If the record is already present in the
      store, it depends on the reload behavior _when_ the returned promise
      resolves.
       ### Preloading
       You can optionally `preload` specific attributes and relationships that you know of
      by passing them via the passed `options`.
       For example, if your Ember route looks like `/posts/1/comments/2` and your API route
      for the comment also looks like `/posts/1/comments/2` if you want to fetch the comment
      without fetching the post you can pass in the post to the `findRecord` call:
       ```javascript
      store.findRecord('comment', 2, { preload: { post: 1 } });
      ```
       If you have access to the post model you can also pass the model itself:
       ```javascript
      store.findRecord('post', 1).then(function (myPostModel) {
        store.findRecord('comment', 2, { post: myPostModel });
      });
      ```
       ### Reloading
       The reload behavior is configured either via the passed `options` hash or
      the result of the adapter's `shouldReloadRecord`.
       If `{ reload: true }` is passed or `adapter.shouldReloadRecord` evaluates
      to `true`, then the returned promise resolves once the adapter returns
      data, regardless if the requested record is already in the store:
       ```js
      store.push({
        data: {
          id: 1,
          type: 'post',
          revision: 1
        }
      });
       // adapter#findRecord resolves with
      // [
      //   {
      //     id: 1,
      //     type: 'post',
      //     revision: 2
      //   }
      // ]
      store.findRecord('post', 1, { reload: true }).then(function(post) {
        post.get('revision'); // 2
      });
      ```
       If no reload is indicated via the above mentioned ways, then the promise
      immediately resolves with the cached version in the store.
       ### Background Reloading
       Optionally, if `adapter.shouldBackgroundReloadRecord` evaluates to `true`,
      then a background reload is started, which updates the records' data, once
      it is available:
       ```js
      // app/adapters/post.js
      import ApplicationAdapter from "./application";
       export default class PostAdapter extends ApplicationAdapter {
        shouldReloadRecord(store, snapshot) {
          return false;
        },
         shouldBackgroundReloadRecord(store, snapshot) {
          return true;
        }
      });
       // ...
       store.push({
        data: {
          id: 1,
          type: 'post',
          revision: 1
        }
      });
       let blogPost = store.findRecord('post', 1).then(function(post) {
        post.get('revision'); // 1
      });
       // later, once adapter#findRecord resolved with
      // [
      //   {
      //     id: 1,
      //     type: 'post',
      //     revision: 2
      //   }
      // ]
       blogPost.get('revision'); // 2
      ```
       If you would like to force or prevent background reloading, you can set a
      boolean value for `backgroundReload` in the options object for
      `findRecord`.
       ```app/routes/post/edit.js
      import Route from '@ember/routing/route';
       export default class PostEditRoute extends Route {
        model(params) {
          return this.store.findRecord('post', params.post_id, { backgroundReload: false });
        }
      }
      ```
       If you pass an object on the `adapterOptions` property of the options
      argument it will be passed to your adapter via the snapshot
       ```app/routes/post/edit.js
      import Route from '@ember/routing/route';
       export default class PostEditRoute extends Route {
        model(params) {
          return this.store.findRecord('post', params.post_id, {
            adapterOptions: { subscribe: false }
          });
        }
      }
      ```
       ```app/adapters/post.js
      import MyCustomAdapter from './custom-adapter';
       export default class PostAdapter extends MyCustomAdapter {
        findRecord(store, type, id, snapshot) {
          if (snapshot.adapterOptions.subscribe) {
            // ...
          }
          // ...
        }
      }
      ```
       See [peekRecord](../classes/Store/methods/peekRecord?anchor=peekRecord) to get the cached version of a record.
       ### Retrieving Related Model Records
       If you use an adapter such as Ember's default
      [`JSONAPIAdapter`](/ember-data/release/classes/JSONAPIAdapter)
      that supports the [JSON API specification](http://jsonapi.org/) and if your server
      endpoint supports the use of an
      ['include' query parameter](http://jsonapi.org/format/#fetching-includes),
      you can use `findRecord()` or `findAll()` to automatically retrieve additional records related to
      the one you request by supplying an `include` parameter in the `options` object.
       For example, given a `post` model that has a `hasMany` relationship with a `comment`
      model, when we retrieve a specific post we can have the server also return that post's
      comments in the same request:
       ```app/routes/post.js
      import Route from '@ember/routing/route';
       export default class PostRoute extends Route {
        model(params) {
          return this.store.findRecord('post', params.post_id, { include: 'comments' });
        }
      }
       ```
      In this case, the post's comments would then be available in your template as
      `model.comments`.
       Multiple relationships can be requested using an `include` parameter consisting of a
      comma-separated list (without white-space) while nested relationships can be specified
      using a dot-separated sequence of relationship names. So to request both the post's
      comments and the authors of those comments the request would look like this:
       ```app/routes/post.js
      import Route from '@ember/routing/route';
       export default class PostRoute extends Route {
        model(params) {
          return this.store.findRecord('post', params.post_id, { include: 'comments,comments.author' });
        }
      }
      ```
       ### Retrieving Specific Fields by Type
       If your server endpoint supports the use of a ['fields' query parameter](https://jsonapi.org/format/#fetching-sparse-fieldsets),
      you can use pass those fields through to your server.  At this point in time, this requires a few manual steps on your part.
       1. Implement `buildQuery` in your adapter.
       ```app/adapters/application.js
      buildQuery(snapshot) {
        let query = this._super(...arguments);
         let { fields } = snapshot.adapterOptions;
         if (fields) {
          query.fields = fields;
        }
         return query;
      }
      ```
       2. Then pass through the applicable fields to your `findRecord` request.
       Given a `post` model with attributes body, title, publishDate and meta, you can retrieve a filtered list of attributes.
       ```app/routes/post.js
      import Route from '@ember/routing/route';
      export default Route.extend({
        model(params) {
          return this.store.findRecord('post', params.post_id, { adapterOptions: { fields: { post: 'body,title' } });
        }
      });
      ```
       Moreover, you can filter attributes on related models as well. If a `post` has a `belongsTo` relationship to a user,
      just include the relationship key and attributes.
       ```app/routes/post.js
      import Route from '@ember/routing/route';
      export default Route.extend({
        model(params) {
          return this.store.findRecord('post', params.post_id, { adapterOptions: { fields: { post: 'body,title', user: 'name,email' } });
        }
      });
      ```
       @since 1.13.0
      @method findRecord
      @param {String} modelName
      @param {(String|Integer)} id
      @param {Object} [options]
      @param {Object} preload - optional set of attributes and relationships passed in either as IDs or as actual models
      @return {Promise} promise
    */
    findRecord(modelName, id, options) {
      {
        assertDestroyingStore(this, 'findRecord');
      }
      ( !(Ember.isPresent(modelName)) && Ember.assert(`You need to pass a model name to the store's findRecord method`, Ember.isPresent(modelName)));
      ( !(typeof modelName === 'string') && Ember.assert(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${modelName}`, typeof modelName === 'string'));
      var type = normalizeModelName(modelName);
      var normalizedId = ensureStringId(id);
      var resource = constructResource(type, normalizedId);
      var internalModel = internalModelFactoryFor(this).lookup(resource);
      options = options || {};
      if (!this.hasRecordForId(type, normalizedId)) {
        return this._findByInternalModel(internalModel, options);
      }
      var fetchedInternalModel = this._findRecord(internalModel, options);
      return promiseRecord(fetchedInternalModel, `DS: Store#findRecord ${type} with id: ${id}`);
    }
    _findRecord(internalModel, options) {
      // Refetch if the reload option is passed
      if (options.reload) {
        return this._scheduleFetch(internalModel, options);
      }
      var snapshot = internalModel.createSnapshot(options);
      var adapter = this.adapterFor(internalModel.modelName);

      // Refetch the record if the adapter thinks the record is stale
      if (typeof options.reload === 'undefined' && adapter.shouldReloadRecord && adapter.shouldReloadRecord(this, snapshot)) {
        return this._scheduleFetch(internalModel, options);
      }
      if (options.backgroundReload === false) {
        return Ember.RSVP.Promise.resolve(internalModel);
      }

      // Trigger the background refetch if backgroundReload option is passed
      if (options.backgroundReload || !adapter.shouldBackgroundReloadRecord || adapter.shouldBackgroundReloadRecord(this, snapshot)) {
        this._scheduleFetch(internalModel, options);
      }

      // Return the cached record
      return Ember.RSVP.Promise.resolve(internalModel);
    }
    _findByInternalModel(internalModel, options) {
      if (options === void 0) {
        options = {};
      }
      if (options.preload) {
        internalModel.preloadData(options.preload);
      }
      var fetchedInternalModel = this._findEmptyInternalModel(internalModel, options);
      return promiseRecord(fetchedInternalModel, `DS: Store#findRecord ${internalModel.modelName} with id: ${internalModel.id}`);
    }
    _findEmptyInternalModel(internalModel, options) {
      if (internalModel.isEmpty()) {
        return this._scheduleFetch(internalModel, options);
      }

      //TODO double check about reloading
      {
        if (internalModel.isLoading()) {
          return internalModel._promiseProxy;
        }
      }
      return Ember.RSVP.Promise.resolve(internalModel);
    }

    /**
      This method makes a series of requests to the adapter's `find` method
      and returns a promise that resolves once they are all loaded.
       @private
      @method findByIds
      @param {String} modelName
      @param {Array} ids
      @return {Promise} promise
    */
    findByIds(modelName, ids) {
      {
        assertDestroyingStore(this, 'findByIds');
      }
      ( !(Ember.isPresent(modelName)) && Ember.assert(`You need to pass a model name to the store's findByIds method`, Ember.isPresent(modelName)));
      ( !(typeof modelName === 'string') && Ember.assert(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${modelName}`, typeof modelName === 'string'));
      var promises = new Array(ids.length);
      var normalizedModelName = normalizeModelName(modelName);
      for (var i = 0; i < ids.length; i++) {
        promises[i] = this.findRecord(normalizedModelName, ids[i]);
      }
      return promiseArray(Ember.RSVP.all(promises).then(Ember.A, null, `DS: Store#findByIds of ${normalizedModelName} complete`));
    }

    /**
      This method is called by `findRecord` if it discovers that a particular
      type/id pair hasn't been loaded yet to kick off a request to the
      adapter.
       @method _fetchRecord
      @private
      @param {InternalModel} internalModel model
      @return {Promise} promise
     */
    _fetchRecord(internalModel, options) {
      var modelName = internalModel.modelName;
      var adapter = this.adapterFor(modelName);
      ( !(adapter) && Ember.assert(`You tried to find a record but you have no adapter (for ${modelName})`, adapter));
      ( !(typeof adapter.findRecord === 'function') && Ember.assert(`You tried to find a record but your adapter (for ${modelName}) does not implement 'findRecord'`, typeof adapter.findRecord === 'function'));
      return _find(adapter, this, internalModel.type, internalModel.id, internalModel, options);
    }
    _scheduleFetchMany(internalModels, options) {
      var fetches = new Array(internalModels.length);
      for (var i = 0; i < internalModels.length; i++) {
        fetches[i] = this._scheduleFetch(internalModels[i], options);
      }
      return Ember.RSVP.Promise.all(fetches);
    }
    _scheduleFetchThroughFetchManager(internalModel, options) {
      if (options === void 0) {
        options = {};
      }
      var generateStackTrace = this.generateStackTracesForTrackedRequests;
      // TODO  remove this once we don't rely on state machine
      internalModel.loadingData();
      var identifier = internalModel.identifier;
      assertIdentifierHasId(identifier);
      var promise = this._fetchManager.scheduleFetch(identifier, options, generateStackTrace);
      return promise.then(payload => {
        // ensure that regardless of id returned we assign to the correct record
        if (payload.data && !Array.isArray(payload.data)) {
          payload.data.lid = identifier.lid;
        }

        // Returning this._push here, breaks typing but not any tests, investigate potential missing tests
        var potentiallyNewIm = this._push(payload);
        if (potentiallyNewIm && !Array.isArray(potentiallyNewIm)) {
          return potentiallyNewIm;
        } else {
          return internalModel;
        }
      }, error => {
        // TODO  remove this once we don't rely on state machine
        internalModel.notFound();
        if (internalModel.isEmpty()) {
          internalModel.unloadRecord();
        }
        throw error;
      });
    }
    _scheduleFetch(internalModel, options) {
      {
        if (internalModel._promiseProxy) {
          return internalModel._promiseProxy;
        }
        var {
          id,
          modelName
        } = internalModel;
        var resolver = Ember.RSVP.defer(`Fetching ${modelName}' with id: ${id}`);
        var pendingFetchItem = {
          internalModel,
          resolver,
          options
        };
        {
          if (this.generateStackTracesForTrackedRequests === true) {
            var trace;
            try {
              throw new Error(`Trace Origin for scheduled fetch for ${modelName}:${id}.`);
            } catch (e) {
              trace = e;
            }

            // enable folks to discover the origin of this findRecord call when
            // debugging. Ideally we would have a tracked queue for requests with
            // labels or local IDs that could be used to merge this trace with
            // the trace made available when we detect an async leak
            pendingFetchItem.trace = trace;
          }
        }
        var promise = resolver.promise;
        internalModel.loadingData(promise);
        if (this._pendingFetch.size === 0) {
          emberRun$2.schedule('actions', this, this.flushAllPendingFetches);
        }
        var fetches = this._pendingFetch;
        var pending = fetches.get(modelName);
        if (pending === undefined) {
          pending = [];
          fetches.set(modelName, pending);
        }
        pending.push(pendingFetchItem);
        return promise;
      }
    }
    flushAllPendingFetches() {
      {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        this._pendingFetch.forEach(this._flushPendingFetchForType, this);
        this._pendingFetch.clear();
      }
    }
    _flushPendingFetchForType(pendingFetchItems, modelName) {
      var store = this;
      var adapter = store.adapterFor(modelName);
      var shouldCoalesce = !!adapter.findMany && adapter.coalesceFindRequests;
      var totalItems = pendingFetchItems.length;
      var internalModels = new Array(totalItems);
      var seeking = Object.create(null);
      var optionsMap = new WeakMap();
      for (var _i = 0; _i < totalItems; _i++) {
        var pendingItem = pendingFetchItems[_i];
        var _internalModel = pendingItem.internalModel;
        internalModels[_i] = _internalModel;
        optionsMap.set(_internalModel, pendingItem.options);
        // We can remove this "not null" cast once we have enough typing
        // to know we are only dealing with ExistingResourceIdentifierObjects
        seeking[_internalModel.id] = pendingItem;
      }
      function _fetchRecord(recordResolverPair) {
        var recordFetch = store._fetchRecord(recordResolverPair.internalModel, recordResolverPair.options);
        recordResolverPair.resolver.resolve(recordFetch);
      }
      function handleFoundRecords(foundInternalModels, expectedInternalModels) {
        // resolve found records
        var found = Object.create(null);
        for (var _i2 = 0, _l = foundInternalModels.length; _i2 < _l; _i2++) {
          var _internalModel2 = foundInternalModels[_i2];

          // We can remove this "not null" cast once we have enough typing
          // to know we are only dealing with ExistingResourceIdentifierObjects
          var _pair = seeking[_internalModel2.id];
          found[_internalModel2.id] = _internalModel2;
          if (_pair) {
            var resolver = _pair.resolver;
            resolver.resolve(_internalModel2);
          }
        }

        // reject missing records
        var missingInternalModels = [];
        for (var _i3 = 0, _l2 = expectedInternalModels.length; _i3 < _l2; _i3++) {
          var _internalModel3 = expectedInternalModels[_i3];

          // We can remove this "not null" cast once we have enough typing
          // to know we are only dealing with ExistingResourceIdentifierObjects
          if (!found[_internalModel3.id]) {
            missingInternalModels.push(_internalModel3);
          }
        }
        if (missingInternalModels.length) {
          ( Ember.warn('Ember Data expected to find records with the following ids in the adapter response but they were missing: [ "' + missingInternalModels.map(r => r.id).join('", "') + '" ]', false, {
            id: 'ds.store.missing-records-from-adapter'
          }));
          rejectInternalModels(missingInternalModels);
        }
      }
      function rejectInternalModels(internalModels, error) {
        for (var _i4 = 0, _l3 = internalModels.length; _i4 < _l3; _i4++) {
          var _internalModel4 = internalModels[_i4];

          // We can remove this "not null" cast once we have enough typing
          // to know we are only dealing with ExistingResourceIdentifierObjects
          var _pair2 = seeking[_internalModel4.id];
          if (_pair2) {
            _pair2.resolver.reject(error || new Error(`Expected: '${_internalModel4}' to be present in the adapter provided payload, but it was not found.`));
          }
        }
      }
      if (shouldCoalesce) {
        // TODO: Improve records => snapshots => records => snapshots
        //
        // We want to provide records to all store methods and snapshots to all
        // adapter methods. To make sure we're doing that we're providing an array
        // of snapshots to adapter.groupRecordsForFindMany(), which in turn will
        // return grouped snapshots instead of grouped records.
        //
        // But since the _findMany() finder is a store method we need to get the
        // records from the grouped snapshots even though the _findMany() finder
        // will once again convert the records to snapshots for adapter.findMany()
        var snapshots = new Array(totalItems);
        for (var _i5 = 0; _i5 < totalItems; _i5++) {
          snapshots[_i5] = internalModels[_i5].createSnapshot(optionsMap.get(internalModel));
        }
        var groups;
        if (adapter.groupRecordsForFindMany) {
          groups = adapter.groupRecordsForFindMany(this, snapshots);
        } else {
          groups = [snapshots];
        }
        for (var i = 0, l = groups.length; i < l; i++) {
          var group = groups[i];
          var totalInGroup = groups[i].length;
          var ids = new Array(totalInGroup);
          var groupedInternalModels = new Array(totalInGroup);
          for (var j = 0; j < totalInGroup; j++) {
            var internalModel = group[j]._internalModel;
            groupedInternalModels[j] = internalModel;
            ids[j] = internalModel.id;
          }
          if (totalInGroup > 1) {
            (function (groupedInternalModels) {
              _findMany(adapter, store, modelName, ids, groupedInternalModels, optionsMap).then(function (foundInternalModels) {
                handleFoundRecords(foundInternalModels, groupedInternalModels);
              }).catch(function (error) {
                rejectInternalModels(groupedInternalModels, error);
              });
            })(groupedInternalModels);
          } else if (ids.length === 1) {
            var pair = seeking[groupedInternalModels[0].id];
            _fetchRecord(pair);
          } else {
            ( Ember.assert("You cannot return an empty array from adapter's method groupRecordsForFindMany", false));
          }
        }
      } else {
        for (var _i6 = 0; _i6 < totalItems; _i6++) {
          _fetchRecord(pendingFetchItems[_i6]);
        }
      }
    }

    /**
      Get the reference for the specified record.
       Example
       ```javascript
      let userRef = store.getReference('user', 1);
       // check if the user is loaded
      let isLoaded = userRef.value() !== null;
       // get the record of the reference (null if not yet available)
      let user = userRef.value();
       // get the identifier of the reference
      if (userRef.remoteType() === 'id') {
      let id = userRef.id();
      }
       // load user (via store.find)
      userRef.load().then(...)
       // or trigger a reload
      userRef.reload().then(...)
       // provide data for reference
      userRef.push({ id: 1, username: '@user' }).then(function(user) {
        userRef.value() === user;
      });
      ```
       @method getReference
      @param {String} modelName
      @param {String|Integer} id
      @since 2.5.0
      @return {RecordReference}
    */
    getReference(modelName, id) {
      {
        assertDestroyingStore(this, 'getReference');
      }
      var type = normalizeModelName(modelName);
      var normalizedId = ensureStringId(id);
      var resource = constructResource(type, normalizedId);
      var identifier = identifierCacheFor(this).getOrCreateRecordIdentifier(resource);
      if (identifier) {
        if (RECORD_REFERENCES.has(identifier)) {
          return RECORD_REFERENCES.get(identifier);
        }
        var reference = new RecordReference(this, identifier);
        RECORD_REFERENCES.set(identifier, reference);
        return reference;
      }
    }

    /**
      Get a record by a given type and ID without triggering a fetch.
       This method will synchronously return the record if it is available in the store,
      otherwise it will return `null`. A record is available if it has been fetched earlier, or
      pushed manually into the store.
       See [findRecord](../classes/Store/methods/findRecord?anchor=findRecord) if you would like to request this record from the backend.
       _Note: This is a synchronous method and does not return a promise._
       ```js
      let post = store.peekRecord('post', 1);
       post.get('id'); // 1
      ```
       @since 1.13.0
      @method peekRecord
      @param {String} modelName
      @param {String|Integer} id
      @return {Model|null} record
    */
    peekRecord(modelName, id) {
      {
        assertDestroyingStore(this, 'peekRecord');
      }
      ( !(Ember.isPresent(modelName)) && Ember.assert(`You need to pass a model name to the store's peekRecord method`, Ember.isPresent(modelName)));
      ( !(typeof modelName === 'string') && Ember.assert(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${modelName}`, typeof modelName === 'string'));
      var type = normalizeModelName(modelName);
      var normalizedId = ensureStringId(id);
      if (this.hasRecordForId(type, normalizedId)) {
        var resource = constructResource(type, normalizedId);
        return internalModelFactoryFor(this).lookup(resource).getRecord();
      } else {
        return null;
      }
    }

    /**
      This method is called by the record's `reload` method.
       This method calls the adapter's `find` method, which returns a promise. When
      **that** promise resolves, `_reloadRecord` will resolve the promise returned
      by the record's `reload`.
       @method _reloadRecord
      @private
      @param {Model} internalModel
      @param options optional to include adapterOptions
      @return {Promise} promise
    */
    _reloadRecord(internalModel, options) {
      var {
        id,
        modelName
      } = internalModel;
      var adapter = this.adapterFor(modelName);
      ( !(id) && Ember.assert(`You cannot reload a record without an ID`, id));
      ( !(adapter) && Ember.assert(`You tried to reload a record but you have no adapter (for ${modelName})`, adapter));
      ( !(typeof adapter.findRecord === 'function' || typeof adapter.find === 'function') && Ember.assert(`You tried to reload a record but your adapter does not implement 'findRecord'`, typeof adapter.findRecord === 'function' || typeof adapter.find === 'function'));
      return this._scheduleFetch(internalModel, options);
    }

    /**
     This method returns true if a record for a given modelName and id is already
     loaded in the store. Use this function to know beforehand if a findRecord()
     will result in a request or that it will be a cache hit.
      Example
      ```javascript
     store.hasRecordForId('post', 1); // false
     store.findRecord('post', 1).then(function() {
       store.hasRecordForId('post', 1); // true
     });
     ```
       @method hasRecordForId
      @param {String} modelName
      @param {(String|Integer)} id
      @return {Boolean}
    */
    hasRecordForId(modelName, id) {
      {
        assertDestroyingStore(this, 'hasRecordForId');
      }
      ( !(Ember.isPresent(modelName)) && Ember.assert(`You need to pass a model name to the store's hasRecordForId method`, Ember.isPresent(modelName)));
      ( !(typeof modelName === 'string') && Ember.assert(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${modelName}`, typeof modelName === 'string'));
      var type = normalizeModelName(modelName);
      var trueId = ensureStringId(id);
      var resource = {
        type,
        id: trueId
      };
      var identifier = identifierCacheFor(this).peekRecordIdentifier(resource);
      var internalModel = identifier && internalModelFactoryFor(this).peek(identifier);
      return !!internalModel && internalModel.isLoaded();
    }

    /**
      Returns id record for a given type and ID. If one isn't already loaded,
      it builds a new record and leaves it in the `empty` state.
       @method recordForId
      @private
      @param {String} modelName
      @param {(String|Integer)} id
      @return {Model} record
    */
    recordForId(modelName, id) {
      {
        assertDestroyingStore(this, 'recordForId');
      }
      ( !(Ember.isPresent(modelName)) && Ember.assert(`You need to pass a model name to the store's recordForId method`, Ember.isPresent(modelName)));
      ( !(typeof modelName === 'string') && Ember.assert(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${modelName}`, typeof modelName === 'string'));
      var resource = constructResource(modelName, ensureStringId(id));
      return internalModelFactoryFor(this).lookup(resource).getRecord();
    }

    /**
      @method findMany
      @private
      @param {Array} internalModels
      @return {Promise} promise
    */
    findMany(internalModels, options) {
      {
        assertDestroyingStore(this, 'findMany');
      }
      var finds = new Array(internalModels.length);
      for (var i = 0; i < internalModels.length; i++) {
        finds[i] = this._findEmptyInternalModel(internalModels[i], options);
      }
      return Ember.RSVP.Promise.all(finds);
    }

    /**
      If a relationship was originally populated by the adapter as a link
      (as opposed to a list of IDs), this method is called when the
      relationship is fetched.
       The link (which is usually a URL) is passed through unchanged, so the
      adapter can make whatever request it wants.
       The usual use-case is for the server to register a URL as a link, and
      then use that URL in the future to make a request for the relationship.
       @method findHasMany
      @private
      @param {InternalModel} internalModel
      @param {any} link
      @param {(Relationship)} relationship
      @return {Promise} promise
    */
    findHasMany(internalModel, link, relationship, options) {
      {
        assertDestroyingStore(this, 'findHasMany');
      }
      var adapter = this.adapterFor(internalModel.modelName);
      ( !(adapter) && Ember.assert(`You tried to load a hasMany relationship but you have no adapter (for ${internalModel.modelName})`, adapter));
      ( !(typeof adapter.findHasMany === 'function') && Ember.assert(`You tried to load a hasMany relationship from a specified 'link' in the original payload but your adapter does not implement 'findHasMany'`, typeof adapter.findHasMany === 'function'));
      return _findHasMany(adapter, this, internalModel, link, relationship, options);
    }
    _findHasManyByJsonApiResource(resource, parentInternalModel, relationshipMeta, options) {
      if (!resource) {
        return Ember.RSVP.resolve([]);
      }
      var adapter = this.adapterFor(relationshipMeta.type);
      var {
        relationshipIsStale,
        hasDematerializedInverse,
        hasAnyRelationshipData,
        relationshipIsEmpty,
        shouldForceReload
      } = resource._relationship;
      var allInverseRecordsAreLoaded = areAllInverseRecordsLoaded(this, resource);
      var shouldFindViaLink = resource.links && resource.links.related && (typeof adapter.findHasMany === 'function' || typeof resource.data === 'undefined') && (shouldForceReload || hasDematerializedInverse || relationshipIsStale || !allInverseRecordsAreLoaded && !relationshipIsEmpty);

      // fetch via link
      if (shouldFindViaLink) {
        return this.findHasMany(parentInternalModel, resource.links.related, relationshipMeta, options);
      }
      var preferLocalCache = hasAnyRelationshipData && !relationshipIsEmpty;
      var hasLocalPartialData = hasDematerializedInverse || relationshipIsEmpty && Array.isArray(resource.data) && resource.data.length > 0;

      // fetch using data, pulling from local cache if possible
      if (!shouldForceReload && !relationshipIsStale && (preferLocalCache || hasLocalPartialData)) {
        var internalModels = resource.data.map(json => this._internalModelForResource(json));
        return this.findMany(internalModels, options);
      }
      var hasData = hasAnyRelationshipData && !relationshipIsEmpty;

      // fetch by data
      if (hasData || hasLocalPartialData) {
        var _internalModels = resource.data.map(json => this._internalModelForResource(json));
        return this._scheduleFetchMany(_internalModels, options);
      }

      // we were explicitly told we have no data and no links.
      //   TODO if the relationshipIsStale, should we hit the adapter anyway?
      return Ember.RSVP.resolve([]);
    }
    _getHasManyByJsonApiResource(resource) {
      var internalModels = [];
      if (resource && resource.data) {
        internalModels = resource.data.map(reference => this._internalModelForResource(reference));
      }
      return internalModels;
    }

    /**
      @method findBelongsTo
      @private
      @param {InternalModel} internalModel
      @param {any} link
      @param {Relationship} relationship
      @return {Promise} promise
    */
    findBelongsTo(internalModel, link, relationship, options) {
      {
        assertDestroyingStore(this, 'findBelongsTo');
      }
      var adapter = this.adapterFor(internalModel.modelName);
      ( !(adapter) && Ember.assert(`You tried to load a belongsTo relationship but you have no adapter (for ${internalModel.modelName})`, adapter));
      ( !(typeof adapter.findBelongsTo === 'function') && Ember.assert(`You tried to load a belongsTo relationship from a specified 'link' in the original payload but your adapter does not implement 'findBelongsTo'`, typeof adapter.findBelongsTo === 'function'));
      return _findBelongsTo(adapter, this, internalModel, link, relationship, options);
    }
    _fetchBelongsToLinkFromResource(resource, parentInternalModel, relationshipMeta, options) {
      if (!resource || !resource.links || !resource.links.related) {
        // should we warn here, not sure cause its an internal method
        return Ember.RSVP.resolve(null);
      }
      return this.findBelongsTo(parentInternalModel, resource.links.related, relationshipMeta, options).then(internalModel => {
        return internalModel ? internalModel.getRecord() : null;
      });
    }
    _findBelongsToByJsonApiResource(resource, parentInternalModel, relationshipMeta, options) {
      if (!resource) {
        return Ember.RSVP.resolve(null);
      }
      var internalModel = resource.data ? this._internalModelForResource(resource.data) : null;
      var {
        relationshipIsStale,
        hasDematerializedInverse,
        hasAnyRelationshipData,
        relationshipIsEmpty,
        shouldForceReload
      } = resource._relationship;
      var allInverseRecordsAreLoaded = areAllInverseRecordsLoaded(this, resource);
      var shouldFindViaLink = resource.links && resource.links.related && (shouldForceReload || hasDematerializedInverse || relationshipIsStale || !allInverseRecordsAreLoaded && !relationshipIsEmpty);
      if (internalModel) {
        // short circuit if we are already loading
        {
          if (internalModel.isLoading()) {
            return internalModel._promiseProxy.then(() => {
              return internalModel.getRecord();
            });
          }
        }
      }

      // fetch via link
      if (shouldFindViaLink) {
        return this._fetchBelongsToLinkFromResource(resource, parentInternalModel, relationshipMeta, options);
      }
      var preferLocalCache = hasAnyRelationshipData && allInverseRecordsAreLoaded && !relationshipIsEmpty;
      var hasLocalPartialData = hasDematerializedInverse || relationshipIsEmpty && resource.data;
      // null is explicit empty, undefined is "we don't know anything"
      var localDataIsEmpty = resource.data === undefined || resource.data === null;

      // fetch using data, pulling from local cache if possible
      if (!shouldForceReload && !relationshipIsStale && (preferLocalCache || hasLocalPartialData)) {
        /*
          We have canonical data, but our local state is empty
         */
        if (localDataIsEmpty) {
          return Ember.RSVP.resolve(null);
        }
        return this._findByInternalModel(internalModel, options);
      }
      var resourceIsLocal = !localDataIsEmpty && resource.data.id === null;
      if (internalModel && resourceIsLocal) {
        return Ember.RSVP.resolve(internalModel.getRecord());
      }

      // fetch by data
      if (internalModel && !localDataIsEmpty) {
        return this._scheduleFetch(internalModel, options).then(() => {
          return internalModel.getRecord();
        });
      }

      // we were explicitly told we have no data and no links.
      //   TODO if the relationshipIsStale, should we hit the adapter anyway?
      return Ember.RSVP.resolve(null);
    }

    /**
      This method delegates a query to the adapter. This is the one place where
      adapter-level semantics are exposed to the application.
       Each time this method is called a new request is made through the adapter.
       Exposing queries this way seems preferable to creating an abstract query
      language for all server-side queries, and then require all adapters to
      implement them.
       ---
       If you do something like this:
       ```javascript
      store.query('person', { page: 1 });
      ```
       The request made to the server will look something like this:
       ```
      GET "/api/v1/person?page=1"
      ```
       ---
       If you do something like this:
       ```javascript
      store.query('person', { ids: [1, 2, 3] });
      ```
       The request made to the server will look something like this:
       ```
      GET "/api/v1/person?ids%5B%5D=1&ids%5B%5D=2&ids%5B%5D=3"
      decoded: "/api/v1/person?ids[]=1&ids[]=2&ids[]=3"
      ```
       This method returns a promise, which is resolved with an
      [`AdapterPopulatedRecordArray`](/ember-data/release/classes/AdapterPopulatedRecordArray)
      once the server returns.
       @since 1.13.0
      @method query
      @param {String} modelName
      @param {any} query an opaque query to be used by the adapter
      @param {Object} options optional, may include `adapterOptions` hash which will be passed to adapter.query
      @return {Promise} promise
    */
    query(modelName, query, options) {
      {
        assertDestroyingStore(this, 'query');
      }
      ( !(Ember.isPresent(modelName)) && Ember.assert(`You need to pass a model name to the store's query method`, Ember.isPresent(modelName)));
      ( !(query) && Ember.assert(`You need to pass a query hash to the store's query method`, query));
      ( !(typeof modelName === 'string') && Ember.assert(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${modelName}`, typeof modelName === 'string'));
      var adapterOptionsWrapper = {};
      if (options && options.adapterOptions) {
        adapterOptionsWrapper.adapterOptions = options.adapterOptions;
      }
      var normalizedModelName = normalizeModelName(modelName);
      return this._query(normalizedModelName, query, null, adapterOptionsWrapper);
    }
    _query(modelName, query, array, options) {
      ( !(Ember.isPresent(modelName)) && Ember.assert(`You need to pass a model name to the store's query method`, Ember.isPresent(modelName)));
      ( !(query) && Ember.assert(`You need to pass a query hash to the store's query method`, query));
      ( !(typeof modelName === 'string') && Ember.assert(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${modelName}`, typeof modelName === 'string'));
      var adapter = this.adapterFor(modelName);
      ( !(adapter) && Ember.assert(`You tried to load a query but you have no adapter (for ${modelName})`, adapter));
      ( !(typeof adapter.query === 'function') && Ember.assert(`You tried to load a query but your adapter does not implement 'query'`, typeof adapter.query === 'function'));
      return promiseArray(_query(adapter, this, modelName, query, array, options));
    }

    /**
      This method makes a request for one record, where the `id` is not known
      beforehand (if the `id` is known, use [`findRecord`](../classes/Store/methods/findRecord?anchor=findRecord)
      instead).
       This method can be used when it is certain that the server will return a
      single object for the primary data.
       Each time this method is called a new request is made through the adapter.
       Let's assume our API provides an endpoint for the currently logged in user
      via:
       ```
      // GET /api/current_user
      {
        user: {
          id: 1234,
          username: 'admin'
        }
      }
      ```
       Since the specific `id` of the `user` is not known beforehand, we can use
      `queryRecord` to get the user:
       ```javascript
      store.queryRecord('user', {}).then(function(user) {
        let username = user.get('username');
        console.log(`Currently logged in as ${username}`);
      });
      ```
       The request is made through the adapters' `queryRecord`:
       ```app/adapters/user.js
      import Adapter from '@ember-data/adapter';
      import $ from 'jquery';
       export default class UserAdapter extends Adapter {
        queryRecord(modelName, query) {
          return $.getJSON('/api/current_user');
        }
      }
      ```
       Note: the primary use case for `store.queryRecord` is when a single record
      is queried and the `id` is not known beforehand. In all other cases
      `store.query` and using the first item of the array is likely the preferred
      way:
       ```
      // GET /users?username=unique
      {
        data: [{
          id: 1234,
          type: 'user',
          attributes: {
            username: "unique"
          }
        }]
      }
      ```
       ```javascript
      store.query('user', { username: 'unique' }).then(function(users) {
        return users.get('firstObject');
      }).then(function(user) {
        let id = user.get('id');
      });
      ```
       This method returns a promise, which resolves with the found record.
       If the adapter returns no data for the primary data of the payload, then
      `queryRecord` resolves with `null`:
       ```
      // GET /users?username=unique
      {
        data: null
      }
      ```
       ```javascript
      store.queryRecord('user', { username: 'unique' }).then(function(user) {
        console.log(user); // null
      });
      ```
       @since 1.13.0
      @method queryRecord
      @param {String} modelName
      @param {any} query an opaque query to be used by the adapter
      @param {Object} options optional, may include `adapterOptions` hash which will be passed to adapter.queryRecord
      @return {Promise} promise which resolves with the found record or `null`
    */
    queryRecord(modelName, query, options) {
      {
        assertDestroyingStore(this, 'queryRecord');
      }
      ( !(Ember.isPresent(modelName)) && Ember.assert(`You need to pass a model name to the store's queryRecord method`, Ember.isPresent(modelName)));
      ( !(query) && Ember.assert(`You need to pass a query hash to the store's queryRecord method`, query));
      ( !(typeof modelName === 'string') && Ember.assert(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${modelName}`, typeof modelName === 'string'));
      var normalizedModelName = normalizeModelName(modelName);
      var adapter = this.adapterFor(normalizedModelName);
      var adapterOptionsWrapper = {};
      if (options && options.adapterOptions) {
        adapterOptionsWrapper.adapterOptions = options.adapterOptions;
      }
      ( !(adapter) && Ember.assert(`You tried to make a query but you have no adapter (for ${normalizedModelName})`, adapter));
      ( !(typeof adapter.queryRecord === 'function') && Ember.assert(`You tried to make a query but your adapter does not implement 'queryRecord'`, typeof adapter.queryRecord === 'function'));
      return promiseObject(_queryRecord(adapter, this, normalizedModelName, query, adapterOptionsWrapper).then(internalModel => {
        // the promise returned by store.queryRecord is expected to resolve with
        // an instance of Model
        if (internalModel) {
          return internalModel.getRecord();
        }
        return null;
      }));
    }

    /**
      `findAll` asks the adapter's `findAll` method to find the records for the
      given type, and returns a promise which will resolve with all records of
      this type present in the store, even if the adapter only returns a subset
      of them.
       ```app/routes/authors.js
      import Route from '@ember/routing/route';
       export default class AuthorsRoute extends Route {
        model(params) {
          return this.store.findAll('author');
        }
      }
      ```
       _When_ the returned promise resolves depends on the reload behavior,
      configured via the passed `options` hash and the result of the adapter's
      `shouldReloadAll` method.
       ### Reloading
       If `{ reload: true }` is passed or `adapter.shouldReloadAll` evaluates to
      `true`, then the returned promise resolves once the adapter returns data,
      regardless if there are already records in the store:
       ```js
      store.push({
        data: {
          id: 'first',
          type: 'author'
        }
      });
       // adapter#findAll resolves with
      // [
      //   {
      //     id: 'second',
      //     type: 'author'
      //   }
      // ]
      store.findAll('author', { reload: true }).then(function(authors) {
        authors.getEach('id'); // ['first', 'second']
      });
      ```
       If no reload is indicated via the above mentioned ways, then the promise
      immediately resolves with all the records currently loaded in the store.
       ### Background Reloading
       Optionally, if `adapter.shouldBackgroundReloadAll` evaluates to `true`,
      then a background reload is started. Once this resolves, the array with
      which the promise resolves, is updated automatically so it contains all the
      records in the store:
       ```app/adapters/application.js
      import Adapter from '@ember-data/adapter';
       export default class ApplicationAdapter extends Adapter {
        shouldReloadAll(store, snapshotsArray) {
          return false;
        },
         shouldBackgroundReloadAll(store, snapshotsArray) {
          return true;
        }
      });
       // ...
       store.push({
        data: {
          id: 'first',
          type: 'author'
        }
      });
       let allAuthors;
      store.findAll('author').then(function(authors) {
        authors.getEach('id'); // ['first']
         allAuthors = authors;
      });
       // later, once adapter#findAll resolved with
      // [
      //   {
      //     id: 'second',
      //     type: 'author'
      //   }
      // ]
       allAuthors.getEach('id'); // ['first', 'second']
      ```
       If you would like to force or prevent background reloading, you can set a
      boolean value for `backgroundReload` in the options object for
      `findAll`.
       ```app/routes/post/edit.js
      import Route from '@ember/routing/route';
       export default class PostEditRoute extends Route {
        model() {
          return this.store.findAll('post', { backgroundReload: false });
        }
      }
      ```
       If you pass an object on the `adapterOptions` property of the options
      argument it will be passed to you adapter via the `snapshotRecordArray`
       ```app/routes/posts.js
      import Route from '@ember/routing/route';
       export default class PostsRoute extends Route {
        model(params) {
          return this.store.findAll('post', {
            adapterOptions: { subscribe: false }
          });
        }
      }
      ```
       ```app/adapters/post.js
      import MyCustomAdapter from './custom-adapter';
       export default class UserAdapter extends MyCustomAdapter {
        findAll(store, type, sinceToken, snapshotRecordArray) {
          if (snapshotRecordArray.adapterOptions.subscribe) {
            // ...
          }
          // ...
        }
      }
      ```
       See [peekAll](../classes/Store/methods/peekAll?anchor=peekAll) to get an array of current records in the
      store, without waiting until a reload is finished.
       ### Retrieving Related Model Records
       If you use an adapter such as Ember's default
      [`JSONAPIAdapter`](/ember-data/release/classes/JSONAPIAdapter)
      that supports the [JSON API specification](http://jsonapi.org/) and if your server
      endpoint supports the use of an
      ['include' query parameter](http://jsonapi.org/format/#fetching-includes),
      you can use `findAll()` to automatically retrieve additional records related to
      those requested by supplying an `include` parameter in the `options` object.
       For example, given a `post` model that has a `hasMany` relationship with a `comment`
      model, when we retrieve all of the post records we can have the server also return
      all of the posts' comments in the same request:
       ```app/routes/posts.js
      import Route from '@ember/routing/route';
       export default class PostsRoute extends Route {
        model() {
          return this.store.findAll('post', { include: 'comments' });
        }
      }
      ```
      Multiple relationships can be requested using an `include` parameter consisting of a
      comma-separated list (without white-space) while nested relationships can be specified
      using a dot-separated sequence of relationship names. So to request both the posts'
      comments and the authors of those comments the request would look like this:
       ```app/routes/posts.js
      import Route from '@ember/routing/route';
       export default class PostsRoute extends Route {
        model() {
          return this.store.findAll('post', { include: 'comments,comments.author' });
        }
      }
      ```
       See [query](../classes/Store/methods/query?anchor=query) to only get a subset of records from the server.
       @since 1.13.0
      @method findAll
      @param {String} modelName
      @param {Object} options
      @return {Promise} promise
    */
    findAll(modelName, options) {
      {
        assertDestroyingStore(this, 'findAll');
      }
      ( !(Ember.isPresent(modelName)) && Ember.assert(`You need to pass a model name to the store's findAll method`, Ember.isPresent(modelName)));
      ( !(typeof modelName === 'string') && Ember.assert(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${modelName}`, typeof modelName === 'string'));
      var normalizedModelName = normalizeModelName(modelName);
      var fetch = this._fetchAll(normalizedModelName, this.peekAll(normalizedModelName), options);
      return fetch;
    }

    /**
      @method _fetchAll
      @private
      @param {Model} modelName
      @param {RecordArray} array
      @return {Promise} promise
    */
    _fetchAll(modelName, array, options) {
      if (options === void 0) {
        options = {};
      }
      var adapter = this.adapterFor(modelName);
      ( !(adapter) && Ember.assert(`You tried to load all records but you have no adapter (for ${modelName})`, adapter));
      ( !(typeof adapter.findAll === 'function') && Ember.assert(`You tried to load all records but your adapter does not implement 'findAll'`, typeof adapter.findAll === 'function'));
      if (options.reload) {
        Ember.set(array, 'isUpdating', true);
        return promiseArray(_findAll(adapter, this, modelName, options));
      }
      var snapshotArray = array._createSnapshot(options);
      if (options.reload !== false) {
        if (adapter.shouldReloadAll && adapter.shouldReloadAll(this, snapshotArray) || !adapter.shouldReloadAll && snapshotArray.length === 0) {
          Ember.set(array, 'isUpdating', true);
          return promiseArray(_findAll(adapter, this, modelName, options));
        }
      }
      if (options.backgroundReload === false) {
        return promiseArray(Ember.RSVP.Promise.resolve(array));
      }
      if (options.backgroundReload || !adapter.shouldBackgroundReloadAll || adapter.shouldBackgroundReloadAll(this, snapshotArray)) {
        Ember.set(array, 'isUpdating', true);
        _findAll(adapter, this, modelName, options);
      }
      return promiseArray(Ember.RSVP.Promise.resolve(array));
    }

    /**
      @method _didUpdateAll
      @param {String} modelName
      @private
    */
    _didUpdateAll(modelName) {
      this.recordArrayManager._didUpdateAll(modelName);
    }

    /**
      This method returns a filtered array that contains all of the
      known records for a given type in the store.
       Note that because it's just a filter, the result will contain any
      locally created records of the type, however, it will not make a
      request to the backend to retrieve additional records. If you
      would like to request all the records from the backend please use
      [store.findAll](../classes/Store/methods/findAll?anchor=findAll).
       Also note that multiple calls to `peekAll` for a given type will always
      return the same `RecordArray`.
       Example
       ```javascript
      let localPosts = store.peekAll('post');
      ```
       @since 1.13.0
      @method peekAll
      @param {String} modelName
      @return {RecordArray}
    */
    peekAll(modelName) {
      {
        assertDestroyingStore(this, 'peekAll');
      }
      ( !(Ember.isPresent(modelName)) && Ember.assert(`You need to pass a model name to the store's peekAll method`, Ember.isPresent(modelName)));
      ( !(typeof modelName === 'string') && Ember.assert(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${modelName}`, typeof modelName === 'string'));
      var normalizedModelName = normalizeModelName(modelName);
      return this.recordArrayManager.liveRecordArrayFor(normalizedModelName);
    }

    /**
      This method unloads all records in the store.
      It schedules unloading to happen during the next run loop.
       Optionally you can pass a type which unload all records for a given type.
       ```javascript
      store.unloadAll();
      store.unloadAll('post');
      ```
       @method unloadAll
      @param {String} modelName
    */
    unloadAll(modelName) {
      {
        assertDestroyedStoreOnly(this, 'unloadAll');
      }
      ( !(!modelName || typeof modelName === 'string') && Ember.assert(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${modelName}`, !modelName || typeof modelName === 'string'));
      var factory = internalModelFactoryFor(this);
      if (modelName === undefined) {
        factory.clear();
      } else {
        var normalizedModelName = normalizeModelName(modelName);
        factory.clear(normalizedModelName);
      }
    }
    filter() {
      ( Ember.assert('The filter API has been moved to a plugin. To enable store.filter using an environment flag, or to use an alternative, you can visit the ember-data-filter addon page. https://github.com/ember-data/ember-data-filter', false));
    }

    // ..............
    // . PERSISTING .
    // ..............

    /**
      This method is called by `record.save`, and gets passed a
      resolver for the promise that `record.save` returns.
       It schedules saving to happen at the end of the run loop.
       @method scheduleSave
      @private
      @param {InternalModel} internalModel
      @param {Resolver} resolver
      @param {Object} options
    */
    scheduleSave(internalModel, resolver, options) {
      var snapshot = internalModel.createSnapshot(options);
      if (internalModel._isRecordFullyDeleted()) {
        resolver.resolve();
        return resolver.promise;
      }
      internalModel.adapterWillCommit();
      this._pendingSave.push({
        snapshot: snapshot,
        resolver: resolver
      });
      emberRun$2.scheduleOnce('actions', this, this.flushPendingSave);
    }

    /**
      This method is called at the end of the run loop, and
      flushes any records passed into `scheduleSave`
       @method flushPendingSave
      @private
    */
    flushPendingSave() {
      var pending = this._pendingSave.slice();
      this._pendingSave = [];
      for (var i = 0, j = pending.length; i < j; i++) {
        var pendingItem = pending[i];
        var snapshot = pendingItem.snapshot;
        var resolver = pendingItem.resolver;
        // TODO We have to cast due to our reliance on this private property
        // this will be refactored away once we change our pending API to be identifier based
        var internalModel = snapshot._internalModel;
        var adapter = this.adapterFor(internalModel.modelName);
        var operation = void 0;
        {
          if (internalModel.currentState.stateName === 'root.deleted.saved') {
            resolver.resolve();
            continue;
          } else if (internalModel.isNew()) {
            operation = 'createRecord';
          } else if (internalModel.isDeleted()) {
            operation = 'deleteRecord';
          } else {
            operation = 'updateRecord';
          }
        }
        resolver.resolve(_commit(adapter, this, operation, snapshot));
      }
    }

    /**
      This method is called once the promise returned by an
      adapter's `createRecord`, `updateRecord` or `deleteRecord`
      is resolved.
       If the data provides a server-generated ID, it will
      update the record and the store's indexes.
       @method didSaveRecord
      @private
      @param {InternalModel} internalModel the in-flight internal model
      @param {Object} data optional data (see above)
      @param {string} op the adapter operation that was committed
    */
    didSaveRecord(internalModel, dataArg, op) {
      {
        assertDestroyingStore(this, 'didSaveRecord');
      }
      var data;
      if (dataArg) {
        data = dataArg.data;
      }
      if (!data) {
        ( !(internalModel.id) && Ember.assert(`Your ${internalModel.modelName} record was saved to the server, but the response does not have an id and no id has been set client side. Records must have ids. Please update the server response to provide an id in the response or generate the id on the client side either before saving the record or while normalizing the response.`, internalModel.id));
      }
      var cache = identifierCacheFor(this);
      var identifier = internalModel.identifier;
      if (op !== 'deleteRecord' && data) {
        cache.updateRecordIdentifier(identifier, data);
      }

      //We first make sure the primary data has been updated
      //TODO try to move notification to the user to the end of the runloop
      internalModel.adapterDidCommit(data);
    }

    /**
      This method is called once the promise returned by an
      adapter's `createRecord`, `updateRecord` or `deleteRecord`
      is rejected with a `InvalidError`.
       @method recordWasInvalid
      @private
      @param {InternalModel} internalModel
      @param {Object} errors
    */
    recordWasInvalid(internalModel, parsedErrors, error) {
      {
        assertDestroyingStore(this, 'recordWasInvalid');
      }
      {
        internalModel.adapterDidInvalidate(parsedErrors);
      }
    }

    /**
      This method is called once the promise returned by an
      adapter's `createRecord`, `updateRecord` or `deleteRecord`
      is rejected (with anything other than a `InvalidError`).
       @method recordWasError
      @private
      @param {InternalModel} internalModel
      @param {Error} error
    */
    recordWasError(internalModel, error) {
      {
        assertDestroyingStore(this, 'recordWasError');
      }
      internalModel.adapterDidError(error);
    }

    /**
      Sets newly received ID from the adapter's `createRecord`, `updateRecord`
      or `deleteRecord`.
       @method setRecordId
      @private
      @param {String} modelName
      @param {string} newId
      @param {string} clientId
     */
    setRecordId(modelName, newId, clientId) {
      {
        assertDestroyingStore(this, 'setRecordId');
      }
      internalModelFactoryFor(this).setRecordId(modelName, newId, clientId);
    }

    // ................
    // . LOADING DATA .
    // ................

    /**
      This internal method is used by `push`.
       @method _load
      @private
      @param {Object} data
    */
    _load(data) {
      var resource = constructResource(normalizeModelName(data.type), ensureStringId(data.id), coerceId(data.lid));
      var internalModel = internalModelFactoryFor(this).lookup(resource, data);

      // store.push will be from empty
      // findRecord will be from root.loading
      // all else will be updates
      var isLoading = internalModel.currentState.stateName === 'root.loading';
      var isUpdate = internalModel.currentState.isEmpty === false && !isLoading;

      // exclude store.push (root.empty) case
      var identifier = internalModel.identifier;
      if (isUpdate || isLoading) {
        var updatedIdentifier = identifierCacheFor(this).updateRecordIdentifier(identifier, data);
        if (updatedIdentifier !== identifier) {
          // we encountered a merge of identifiers in which
          // two identifiers (and likely two internalModels)
          // existed for the same resource. Now that we have
          // determined the correct identifier to use, make sure
          // that we also use the correct internalModel.
          identifier = updatedIdentifier;
          internalModel = internalModelFactoryFor(this).lookup(identifier);
        }
      }
      internalModel.setupData(data);
      if (!isUpdate) {
        this.recordArrayManager.recordDidChange(identifier);
      }
      return internalModel;
    }

    /**
      Push some data for a given type into the store.
       This method expects normalized [JSON API](http://jsonapi.org/) document. This means you have to follow [JSON API specification](http://jsonapi.org/format/) with few minor adjustments:
      - record's `type` should always be in singular, dasherized form
      - members (properties) should be camelCased
       [Your primary data should be wrapped inside `data` property](http://jsonapi.org/format/#document-top-level):
       ```js
      store.push({
        data: {
          // primary data for single record of type `Person`
          id: '1',
          type: 'person',
          attributes: {
            firstName: 'Daniel',
            lastName: 'Kmak'
          }
        }
      });
      ```
       [Demo.](http://ember-twiddle.com/fb99f18cd3b4d3e2a4c7)
       `data` property can also hold an array (of records):
       ```js
      store.push({
        data: [
          // an array of records
          {
            id: '1',
            type: 'person',
            attributes: {
              firstName: 'Daniel',
              lastName: 'Kmak'
            }
          },
          {
            id: '2',
            type: 'person',
            attributes: {
              firstName: 'Tom',
              lastName: 'Dale'
            }
          }
        ]
      });
      ```
       [Demo.](http://ember-twiddle.com/69cdbeaa3702159dc355)
       There are some typical properties for `JSONAPI` payload:
      * `id` - mandatory, unique record's key
      * `type` - mandatory string which matches `model`'s dasherized name in singular form
      * `attributes` - object which holds data for record attributes - `attr`'s declared in model
      * `relationships` - object which must contain any of the following properties under each relationships' respective key (example path is `relationships.achievements.data`):
        - [`links`](http://jsonapi.org/format/#document-links)
        - [`data`](http://jsonapi.org/format/#document-resource-object-linkage) - place for primary data
        - [`meta`](http://jsonapi.org/format/#document-meta) - object which contains meta-information about relationship
       For this model:
       ```app/models/person.js
      import Model, { attr, hasMany } from '@ember-data/model';
       export default class PersonRoute extends Route {
        @attr('string') firstName;
        @attr('string') lastName;
         @hasMany('person') children;
      }
      ```
       To represent the children as IDs:
       ```js
      {
        data: {
          id: '1',
          type: 'person',
          attributes: {
            firstName: 'Tom',
            lastName: 'Dale'
          },
          relationships: {
            children: {
              data: [
                {
                  id: '2',
                  type: 'person'
                },
                {
                  id: '3',
                  type: 'person'
                },
                {
                  id: '4',
                  type: 'person'
                }
              ]
            }
          }
        }
      }
      ```
       [Demo.](http://ember-twiddle.com/343e1735e034091f5bde)
       To represent the children relationship as a URL:
       ```js
      {
        data: {
          id: '1',
          type: 'person',
          attributes: {
            firstName: 'Tom',
            lastName: 'Dale'
          },
          relationships: {
            children: {
              links: {
                related: '/people/1/children'
              }
            }
          }
        }
      }
      ```
       If you're streaming data or implementing an adapter, make sure
      that you have converted the incoming data into this form. The
      store's [normalize](../classes/Store/methods/normalize?anchor=normalize) method is a convenience
      helper for converting a json payload into the form Ember Data
      expects.
       ```js
      store.push(store.normalize('person', data));
      ```
       This method can be used both to push in brand new
      records, as well as to update existing records.
       @method push
      @param {Object} data
      @return the record(s) that was created or
        updated.
    */

    push(data) {
      {
        assertDestroyingStore(this, 'push');
      }
      var pushed = this._push(data);
      if (Array.isArray(pushed)) {
        var records = pushed.map(internalModel => internalModel.getRecord());
        return records;
      }
      if (pushed === null) {
        return null;
      }
      var record = pushed.getRecord();
      return record;
    }

    /*
      Push some data in the form of a json-api document into the store,
      without creating materialized records.
       @method _push
      @private
      @param {Object} jsonApiDoc
      @return {InternalModel|Array<InternalModel>} pushed InternalModel(s)
    */
    _push(jsonApiDoc) {
      {
        assertDestroyingStore(this, '_push');
      }
      var internalModelOrModels = this._backburner.join(() => {
        var included = jsonApiDoc.included;
        var i, length;
        if (included) {
          for (i = 0, length = included.length; i < length; i++) {
            this._pushInternalModel(included[i]);
          }
        }
        if (Array.isArray(jsonApiDoc.data)) {
          length = jsonApiDoc.data.length;
          var internalModels = new Array(length);
          for (i = 0; i < length; i++) {
            internalModels[i] = this._pushInternalModel(jsonApiDoc.data[i]);
          }
          return internalModels;
        }
        if (jsonApiDoc.data === null) {
          return null;
        }
        ( !(Ember.typeOf(jsonApiDoc.data) === 'object') && Ember.assert(`Expected an object in the 'data' property in a call to 'push' for ${jsonApiDoc.type}, but was ${Ember.typeOf(jsonApiDoc.data)}`, Ember.typeOf(jsonApiDoc.data) === 'object'));
        return this._pushInternalModel(jsonApiDoc.data);
      });

      // this typecast is necessary because `backburner.join` is mistyped to return void
      return internalModelOrModels;
    }
    _pushInternalModel(data) {
      var modelName = data.type;
      ( !(data.id !== null && data.id !== undefined && data.id !== '') && Ember.assert(`You must include an 'id' for ${modelName} in an object passed to 'push'`, data.id !== null && data.id !== undefined && data.id !== ''));
      ( !(this._hasModelFor(modelName)) && Ember.assert(`You tried to push data with a type '${modelName}' but no model could be found with that name.`, this._hasModelFor(modelName)));
      {
        // If ENV.DS_WARN_ON_UNKNOWN_KEYS is set to true and the payload
        // contains unknown attributes or relationships, log a warning.

        if (ENV.DS_WARN_ON_UNKNOWN_KEYS) {
          var unknownAttributes, unknownRelationships;
          {
            var modelClass = this.modelFor(modelName);
            // Check unknown attributes
            unknownAttributes = Object.keys(data.attributes || {}).filter(key => {
              return !Ember.get(modelClass, 'fields').has(key);
            });

            // Check unknown relationships
            unknownRelationships = Object.keys(data.relationships || {}).filter(key => {
              return !Ember.get(modelClass, 'fields').has(key);
            });
          }
          var unknownAttributesMessage = `The payload for '${modelName}' contains these unknown attributes: ${unknownAttributes}. Make sure they've been defined in your model.`;
          ( Ember.warn(unknownAttributesMessage, unknownAttributes.length === 0, {
            id: 'ds.store.unknown-keys-in-payload'
          }));
          var unknownRelationshipsMessage = `The payload for '${modelName}' contains these unknown relationships: ${unknownRelationships}. Make sure they've been defined in your model.`;
          ( Ember.warn(unknownRelationshipsMessage, unknownRelationships.length === 0, {
            id: 'ds.store.unknown-keys-in-payload'
          }));
        }
      }

      // Actually load the record into the store.
      var internalModel = this._load(data);

      //    this._setupRelationshipsForModel(internalModel, data);

      return internalModel;
    }

    /**
      Push some raw data into the store.
       This method can be used both to push in brand new
      records, as well as to update existing records. You
      can push in more than one type of object at once.
      All objects should be in the format expected by the
      serializer.
       ```app/serializers/application.js
      import RESTSerializer from '@ember-data/serializer/rest';
       export default class ApplicationSerializer extends RESTSerializer;
      ```
       ```js
      let pushData = {
        posts: [
          { id: 1, postTitle: "Great post", commentIds: [2] }
        ],
        comments: [
          { id: 2, commentBody: "Insightful comment" }
        ]
      }
       store.pushPayload(pushData);
      ```
       By default, the data will be deserialized using a default
      serializer (the application serializer if it exists).
       Alternatively, `pushPayload` will accept a model type which
      will determine which serializer will process the payload.
       ```app/serializers/application.js
      import RESTSerializer from '@ember-data/serializer/rest';
        export default class ApplicationSerializer extends RESTSerializer;
      ```
       ```app/serializers/post.js
      import JSONSerializer from '@ember-data/serializer/json';
       export default JSONSerializer;
      ```
       ```js
      store.pushPayload(pushData); // Will use the application serializer
      store.pushPayload('post', pushData); // Will use the post serializer
      ```
       @method pushPayload
      @param {String} modelName Optionally, a model type used to determine which serializer will be used
      @param {Object} inputPayload
    */
    pushPayload(modelName, inputPayload) {
      {
        assertDestroyingStore(this, 'pushPayload');
      }
      var serializer;
      var payload;
      if (!inputPayload) {
        payload = modelName;
        serializer = this.serializerFor('application');
        ( !(typeof serializer.pushPayload === 'function') && Ember.assert(`You cannot use 'store#pushPayload' without a modelName unless your default serializer defines 'pushPayload'`, typeof serializer.pushPayload === 'function'));
      } else {
        payload = inputPayload;
        ( !(typeof modelName === 'string') && Ember.assert(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${modelName}`, typeof modelName === 'string'));
        var normalizedModelName = normalizeModelName(modelName);
        serializer = this.serializerFor(normalizedModelName);
      }
      ( !(serializer.pushPayload) && Ember.assert(`You must define a pushPayload method in your serializer in order to call store.pushPayload`, serializer.pushPayload));
      serializer.pushPayload(this, payload);
    }
    reloadManyArray(manyArray, internalModel, key, options) {
      return internalModel.reloadHasMany(key, options);
    }
    reloadBelongsTo(belongsToProxy, internalModel, key, options) {
      return internalModel.reloadBelongsTo(key, options);
    }
    _internalModelForResource(resource) {
      return internalModelFactoryFor(this).getByResource(resource);
    }

    /**
     * TODO Only needed temporarily for test support
     *
     * @internal
     */
    _internalModelForId(type, id, lid) {
      var resource = constructResource(type, id, lid);
      return internalModelFactoryFor(this).lookup(resource);
    }
    serializeRecord(record, options) {
      assertInDebug('serializeRecord is only available when CUSTOM_MODEL_CLASS ff is on', false);
    }
    saveRecord(record, options) {
      assertInDebug('saveRecord is only available when CUSTOM_MODEL_CLASS ff is on', false);
    }
    relationshipReferenceFor(identifier, key) {
      assertInDebug('relationshipReferenceFor is only available when CUSTOM_MODEL_CLASS ff is on', false);
    }

    /**
     * @internal
     */
    _createRecordData(identifier) {
      return this.createRecordDataFor(identifier.type, identifier.id, identifier.lid, this._storeWrapper);
    }

    /**
     * Instantiation hook allowing applications or addons to configure the store
     * to utilize a custom RecordData implementation.
     *
     * @param modelName
     * @param id
     * @param clientId
     * @param storeWrapper
     */
    createRecordDataFor(modelName, id, clientId, storeWrapper) {
      {
        // we can't greedily use require as this causes
        // a cycle we can't easily fix (or clearly pin point) at present.
        //
        // it can be reproduced in partner tests by running
        // node ./bin/packages-for-commit.js && yarn test-external:ember-observer
        if (_RecordData === undefined) {
          _RecordData = require$1('@ember-data/record-data/-private').RecordData;
        }
        var _identifier5 = identifierCacheFor(this).getOrCreateRecordIdentifier({
          type: modelName,
          id,
          lid: clientId
        });
        return new _RecordData(_identifier5, storeWrapper);
      }
    }

    /**
     * @internal
     */
    __recordDataFor(resource) {
      var identifier = identifierCacheFor(this).getOrCreateRecordIdentifier(resource);
      return this.recordDataFor(identifier, false);
    }

    /**
     * @internal
     */
    recordDataFor(identifier, isCreate) {
      var internalModel;
      if (isCreate === true) {
        internalModel = internalModelFactoryFor(this).build({
          type: identifier.type,
          id: null
        });
        internalModel.loadedData();
        internalModel.didCreateRecord();
      } else {
        internalModel = internalModelFactoryFor(this).lookup(identifier);
      }
      return recordDataFor(internalModel);
    }

    /**
      `normalize` converts a json payload into the normalized form that
      [push](../classes/Store/methods/push?anchor=push) expects.
       Example
       ```js
      socket.on('message', function(message) {
        let modelName = message.model;
        let data = message.data;
        store.push(store.normalize(modelName, data));
      });
      ```
       @method normalize
      @param {String} modelName The name of the model type for this payload
      @param {Object} payload
      @return {Object} The normalized payload
    */
    normalize(modelName, payload) {
      {
        assertDestroyingStore(this, 'normalize');
      }
      ( !(Ember.isPresent(modelName)) && Ember.assert(`You need to pass a model name to the store's normalize method`, Ember.isPresent(modelName)));
      ( !(typeof modelName === 'string') && Ember.assert(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${Ember.inspect(modelName)}`, typeof modelName === 'string'));
      var normalizedModelName = normalizeModelName(modelName);
      var serializer = this.serializerFor(normalizedModelName);
      var model = this.modelFor(normalizedModelName);
      ( !(serializer.normalize) && Ember.assert(`You must define a normalize method in your serializer in order to call store.normalize`, serializer.normalize));
      return serializer.normalize(model, payload);
    }
    newClientId() {
      assertInDebug(`Private API Removed`, false);
    }

    // ...............
    // . DESTRUCTION .
    // ...............

    /**
     * TODO remove test usage
     *
     * @internal
     */
    _internalModelsFor(modelName) {
      return internalModelFactoryFor(this).modelMapFor(modelName);
    }

    // ......................
    // . PER-TYPE ADAPTERS
    // ......................

    /**
      Returns an instance of the adapter for a given type. For
      example, `adapterFor('person')` will return an instance of
      `App.PersonAdapter`.
       If no `App.PersonAdapter` is found, this method will look
      for an `App.ApplicationAdapter` (the default adapter for
      your entire application).
       If no `App.ApplicationAdapter` is found, it will return
      the value of the `defaultAdapter`.
       @method adapterFor
      @public
      @param {String} modelName
      @return Adapter
    */
    adapterFor(modelName) {
      {
        assertDestroyingStore(this, 'adapterFor');
      }
      ( !(Ember.isPresent(modelName)) && Ember.assert(`You need to pass a model name to the store's adapterFor method`, Ember.isPresent(modelName)));
      ( !(typeof modelName === 'string') && Ember.assert(`Passing classes to store.adapterFor has been removed. Please pass a dasherized string instead of ${modelName}`, typeof modelName === 'string'));
      var normalizedModelName = normalizeModelName(modelName);
      var {
        _adapterCache
      } = this;
      var adapter = _adapterCache[normalizedModelName];
      if (adapter) {
        return adapter;
      }
      var owner = Ember.getOwner(this);
      adapter = owner.lookup(`adapter:${normalizedModelName}`);

      // in production this is handled by the re-export
      if ( adapter === undefined) {
        if (normalizedModelName === '-json-api') {
          var Adapter = require$1('@ember-data/adapter/json-api').default;
          owner.register(`adapter:-json-api`, Adapter);
          adapter = owner.lookup(`adapter:-json-api`);
          deprecateTestRegistration('adapter', '-json-api');
        }
      }
      if (adapter !== undefined) {
        Ember.set(adapter, 'store', this);
        _adapterCache[normalizedModelName] = adapter;
        return adapter;
      }

      // no adapter found for the specific model, fallback and check for application adapter
      adapter = _adapterCache.application || owner.lookup('adapter:application');
      if (adapter !== undefined) {
        Ember.set(adapter, 'store', this);
        _adapterCache[normalizedModelName] = adapter;
        _adapterCache.application = adapter;
        return adapter;
      }

      // no model specific adapter or application adapter, check for an `adapter`
      // property defined on the store
      var adapterName = this.adapter || '-json-api';
      adapter = adapterName ? _adapterCache[adapterName] || owner.lookup(`adapter:${adapterName}`) : undefined;

      // in production this is handled by the re-export
      if ( adapter === undefined) {
        if (adapterName === '-json-api') {
          var _Adapter = require$1('@ember-data/adapter/json-api').default;
          owner.register(`adapter:-json-api`, _Adapter);
          adapter = owner.lookup(`adapter:-json-api`);
          deprecateTestRegistration('adapter', '-json-api');
        }
      }
      if (adapter !== undefined) {
        Ember.set(adapter, 'store', this);
        _adapterCache[normalizedModelName] = adapter;
        _adapterCache[adapterName] = adapter;
        return adapter;
      }

      // final fallback, no model specific adapter, no application adapter, no
      // `adapter` property on store: use json-api adapter
      adapter = _adapterCache['-json-api'] || owner.lookup('adapter:-json-api');
      ( !(adapter !== undefined) && Ember.assert(`No adapter was found for '${modelName}' and no 'application' adapter was found as a fallback.`, adapter !== undefined));
      Ember.set(adapter, 'store', this);
      _adapterCache[normalizedModelName] = adapter;
      _adapterCache['-json-api'] = adapter;
      return adapter;
    }

    // ..............................
    // . RECORD CHANGE NOTIFICATION .
    // ..............................

    /**
      Returns an instance of the serializer for a given type. For
      example, `serializerFor('person')` will return an instance of
      `App.PersonSerializer`.
       If no `App.PersonSerializer` is found, this method will look
      for an `App.ApplicationSerializer` (the default serializer for
      your entire application).
       if no `App.ApplicationSerializer` is found, it will attempt
      to get the `defaultSerializer` from the `PersonAdapter`
      (`adapterFor('person')`).
       If a serializer cannot be found on the adapter, it will fall back
      to an instance of `JSONSerializer`.
       @method serializerFor
      @public
      @param {String} modelName the record to serialize
      @return {Serializer}
    */
    serializerFor(modelName) {
      {
        assertDestroyingStore(this, 'serializerFor');
      }
      ( !(Ember.isPresent(modelName)) && Ember.assert(`You need to pass a model name to the store's serializerFor method`, Ember.isPresent(modelName)));
      ( !(typeof modelName === 'string') && Ember.assert(`Passing classes to store.serializerFor has been removed. Please pass a dasherized string instead of ${modelName}`, typeof modelName === 'string'));
      var normalizedModelName = normalizeModelName(modelName);
      var {
        _serializerCache
      } = this;
      var serializer = _serializerCache[normalizedModelName];
      if (serializer) {
        return serializer;
      }
      var owner = Ember.getOwner(this);
      serializer = owner.lookup(`serializer:${normalizedModelName}`);
      {
        // in production this is handled by the re-export
        if ( serializer === undefined) {
          if (normalizedModelName === '-json-api') {
            var Serializer = require$1('@ember-data/serializer/json-api').default;
            owner.register(`serializer:-json-api`, Serializer);
            serializer = owner.lookup(`serializer:-json-api`);
            deprecateTestRegistration('serializer', '-json-api');
          } else if (normalizedModelName === '-rest') {
            var _Serializer = require$1('@ember-data/serializer/rest').default;
            owner.register(`serializer:-rest`, _Serializer);
            serializer = owner.lookup(`serializer:-rest`);
            deprecateTestRegistration('serializer', '-rest');
          } else if (normalizedModelName === '-default') {
            var _Serializer2 = require$1('@ember-data/serializer/json').default;
            owner.register(`serializer:-default`, _Serializer2);
            serializer = owner.lookup(`serializer:-default`);
            serializer && deprecateTestRegistration('serializer', '-default');
          }
        }
      }
      if (serializer !== undefined) {
        Ember.set(serializer, 'store', this);
        _serializerCache[normalizedModelName] = serializer;
        return serializer;
      }

      // no serializer found for the specific model, fallback and check for application serializer
      serializer = _serializerCache.application || owner.lookup('serializer:application');
      if (serializer !== undefined) {
        Ember.set(serializer, 'store', this);
        _serializerCache[normalizedModelName] = serializer;
        _serializerCache.application = serializer;
        return serializer;
      }
      var serializerName;
      {
        // no model specific serializer or application serializer, check for the `defaultSerializer`
        // property defined on the adapter
        var adapter = this.adapterFor(modelName);
        serializerName = Ember.get(adapter, 'defaultSerializer');
        ( !(!serializerName) && Ember.deprecate(`store.serializerFor("${modelName}") resolved the "${serializerName}" serializer via the deprecated \`adapter.defaultSerializer\` property.\n\n\tPreviously, if no application or type-specific serializer was specified, the store would attempt to lookup a serializer via the \`defaultSerializer\` property on the type's adapter. This behavior is deprecated in favor of explicitly defining a type-specific serializer or application serializer`, !serializerName, {
          id: 'ember-data:default-serializer',
          until: '4.0',
          url: 'https://deprecations.emberjs.com/ember-data/v3.x/#toc_ember-data-default-serializers'
        }));
        serializer = serializerName ? _serializerCache[serializerName] || owner.lookup(`serializer:${serializerName}`) : undefined;
      }
      {
        // in production this is handled by the re-export
        if ( serializer === undefined) {
          if (serializerName === '-json-api') {
            var _Serializer3 = require$1('@ember-data/serializer/json-api').default;
            owner.register(`serializer:-json-api`, _Serializer3);
            serializer = owner.lookup(`serializer:-json-api`);
            deprecateTestRegistration('serializer', '-json-api');
          } else if (serializerName === '-rest') {
            var _Serializer4 = require$1('@ember-data/serializer/rest').default;
            owner.register(`serializer:-rest`, _Serializer4);
            serializer = owner.lookup(`serializer:-rest`);
            deprecateTestRegistration('serializer', '-rest');
          } else if (serializerName === '-default') {
            var _Serializer5 = require$1('@ember-data/serializer/json').default;
            owner.register(`serializer:-default`, _Serializer5);
            serializer = owner.lookup(`serializer:-default`);
            serializer && deprecateTestRegistration('serializer', '-default');
          }
        }
        if (serializer !== undefined) {
          Ember.set(serializer, 'store', this);
          _serializerCache[normalizedModelName] = serializer;
          _serializerCache[serializerName] = serializer;
          return serializer;
        }
      }
      {
        // final fallback, no model specific serializer, no application serializer, no
        // `serializer` property on store: use the convenience JSONSerializer
        serializer = _serializerCache['-default'] || owner.lookup('serializer:-default');
        if ( serializer === undefined) {
          var JSONSerializer = require$1('@ember-data/serializer/json').default;
          owner.register('serializer:-default', JSONSerializer);
          serializer = owner.lookup('serializer:-default');
          serializer && deprecateTestRegistration('serializer', '-default');
        }
        ( !(!serializer) && Ember.deprecate(`store.serializerFor("${modelName}") resolved the "-default" serializer via the deprecated "-default" lookup fallback.\n\n\tPreviously, when no type-specific serializer, application serializer, or adapter.defaultSerializer had been defined by the app, the "-default" serializer would be used which defaulted to the \`JSONSerializer\`. This behavior is deprecated in favor of explicitly defining an application or type-specific serializer`, !serializer, {
          id: 'ember-data:default-serializer',
          until: '4.0',
          url: 'https://deprecations.emberjs.com/ember-data/v3.x/#toc_ember-data-default-serializers'
        }));
        ( !(serializer !== undefined) && Ember.assert(`No serializer was found for '${modelName}' and no 'application' serializer was found as a fallback`, serializer !== undefined));
        Ember.set(serializer, 'store', this);
        _serializerCache[normalizedModelName] = serializer;
        _serializerCache['-default'] = serializer;
        return serializer;
      }
    }
    destroy() {
      // enqueue destruction of any adapters/serializers we have created
      for (var adapterName in this._adapterCache) {
        var adapter = this._adapterCache[adapterName];
        if (typeof adapter.destroy === 'function') {
          adapter.destroy();
        }
      }
      for (var serializerName in this._serializerCache) {
        var serializer = this._serializerCache[serializerName];
        if (typeof serializer.destroy === 'function') {
          serializer.destroy();
        }
      }
      return super.destroy();
    }
    willDestroy() {
      super.willDestroy();
      this.recordArrayManager.destroy();
      identifierCacheFor(this).destroy();
      this.unloadAll();
      {
        Ember.Test.unregisterWaiter(this.__asyncWaiter);
        var shouldTrack = this.shouldTrackAsyncRequests;
        var tracked = this._trackedAsyncRequests;
        var isSettled = tracked.length === 0;
        if (!isSettled) {
          if (shouldTrack) {
            throw new Error('Async Request leaks detected. Add a breakpoint here and set `store.generateStackTracesForTrackedRequests = true;`to inspect traces for leak origins:\n\t - ' + tracked.map(o => o.label).join('\n\t - '));
          } else {
            ( Ember.warn('Async Request leaks detected. Add a breakpoint here and set `store.generateStackTracesForTrackedRequests = true;`to inspect traces for leak origins:\n\t - ' + tracked.map(o => o.label).join('\n\t - '), false, {
              id: 'ds.async.leak.detected'
            }));
          }
        }
      }
    }
    _updateRelationshipState(relationship) {
      if (this._updatedRelationships.push(relationship) !== 1) {
        return;
      }
      this._backburner.join(() => {
        this._backburner.schedule('syncRelationships', this, this._flushUpdatedRelationships);
      });
    }
    _flushUpdatedRelationships() {
      var updated = this._updatedRelationships;
      for (var i = 0, l = updated.length; i < l; i++) {
        updated[i].flushCanonical();
      }
      updated.length = 0;
    }
    _updateInternalModel(internalModel) {
      if (this._updatedInternalModels.push(internalModel) !== 1) {
        return;
      }
      emberRun$2.schedule('actions', this, this._flushUpdatedInternalModels);
    }
    _flushUpdatedInternalModels() {
      var updated = this._updatedInternalModels;
      for (var i = 0, l = updated.length; i < l; i++) {
        updated[i]._triggerDeferredTriggers();
      }
      updated.length = 0;
    }
  }
  {
    Ember.defineProperty(CoreStore.prototype, 'defaultAdapter', Ember.computed('adapter', function () {
      ( Ember.deprecate(`store.adapterFor(modelName) resolved the ("${this.adapter || '-json-api'}") adapter via the deprecated \`store.defaultAdapter\` property.\n\n\tPreviously, applications could define the store's \`adapter\` property which would be used by \`defaultAdapter\` and \`adapterFor\` as a fallback for when an adapter was not found by an exact name match. This behavior is deprecated in favor of explicitly defining an application or type-specific adapter.`, false, {
        id: 'ember-data:default-adapter',
        until: '4.0',
        url: 'https://deprecations.emberjs.com/ember-data/v3.x/#toc_ember-data-default-adapter'
      }));
      var adapter = this.adapter || '-json-api';
      ( !(typeof adapter === 'string') && Ember.assert('You tried to set `adapter` property to an instance of `Adapter`, where it should be a name', typeof adapter === 'string'));
      return this.adapterFor(adapter);
    }));
  }
  function _commit(adapter, store, operation, snapshot) {
    var internalModel = snapshot._internalModel;
    var modelName = snapshot.modelName;
    var modelClass = store.modelFor(modelName);
    ( !(adapter) && Ember.assert(`You tried to update a record but you have no adapter (for ${modelName})`, adapter));
    ( !(typeof adapter[operation] === 'function') && Ember.assert(`You tried to update a record but your adapter (for ${modelName}) does not implement '${operation}'`, typeof adapter[operation] === 'function'));
    var promise = Ember.RSVP.Promise.resolve().then(() => adapter[operation](store, modelClass, snapshot));
    var serializer = store.serializerFor(modelName);
    var label = `DS: Extract and notify about ${operation} completion of ${internalModel}`;
    ( !(promise !== undefined) && Ember.assert(`Your adapter's '${operation}' method must return a value, but it returned 'undefined'`, promise !== undefined));
    promise = guardDestroyedStore(promise, store, label);
    promise = _guard(promise, _bind(_objectIsAlive, internalModel));
    return promise.then(adapterPayload => {
      /*
      Note to future spelunkers hoping to optimize.
      We rely on this `run` to create a run loop if needed
      that `store._push` and `store.didSaveRecord` will both share.
       We use `join` because it is often the case that we
      have an outer run loop available still from the first
      call to `store._push`;
      */
      store._backburner.join(() => {
        var payload, data, sideloaded;
        if (adapterPayload) {
          payload = normalizeResponseHelper(serializer, store, modelClass, adapterPayload, snapshot.id, operation);
          if (payload.included) {
            sideloaded = payload.included;
          }
          data = payload.data;
        }
        store.didSaveRecord(internalModel, {
          data
        }, operation);
        // seems risky, but if the tests pass might be fine?
        if (sideloaded) {
          store._push({
            data: null,
            included: sideloaded
          });
        }
      });
      return internalModel;
    }, function (error) {
      if (error && error.isAdapterError === true && error.code === 'InvalidError') {
        var parsedErrors;
        if (typeof serializer.extractErrors === 'function') {
          parsedErrors = serializer.extractErrors(store, modelClass, error, snapshot.id);
        } else {
          parsedErrors = errorsArrayToHash(error.errors);
        }
        store.recordWasInvalid(internalModel, parsedErrors, error);
      } else {
        store.recordWasError(internalModel, error);
      }
      throw error;
    }, label);
  }
  var assertDestroyingStore;
  var assertDestroyedStoreOnly;
  {
    assertDestroyingStore = function assertDestroyedStore(store, method) {
      if (!store.shouldAssertMethodCallsOnDestroyedStore) {
        ( !(!(store.isDestroying || store.isDestroyed)) && Ember.deprecate(`Attempted to call store.${method}(), but the store instance has already been destroyed.`, !(store.isDestroying || store.isDestroyed), {
          id: 'ember-data:method-calls-on-destroyed-store',
          until: '3.8'
        }));
      } else {
        ( !(!(store.isDestroying || store.isDestroyed)) && Ember.assert(`Attempted to call store.${method}(), but the store instance has already been destroyed.`, !(store.isDestroying || store.isDestroyed)));
      }
    };
    assertDestroyedStoreOnly = function assertDestroyedStoreOnly(store, method) {
      if (!store.shouldAssertMethodCallsOnDestroyedStore) {
        ( !(!store.isDestroyed) && Ember.deprecate(`Attempted to call store.${method}(), but the store instance has already been destroyed.`, !store.isDestroyed, {
          id: 'ember-data:method-calls-on-destroyed-store',
          until: '3.8'
        }));
      } else {
        ( !(!store.isDestroyed) && Ember.assert(`Attempted to call store.${method}(), but the store instance has already been destroyed.`, !store.isDestroyed));
      }
    };
  }

  /**
   * Flag indicating whether all inverse records are available
   *
   * true if the inverse exists and is loaded (not empty)
   * true if there is no inverse
   * false if the inverse exists and is not loaded (empty)
   *
   * @return {boolean}
   */
  function areAllInverseRecordsLoaded(store, resource) {
    var cache = identifierCacheFor(store);
    if (Array.isArray(resource.data)) {
      // treat as collection
      // check for unloaded records
      var hasEmptyRecords = resource.data.reduce((hasEmptyModel, resourceIdentifier) => {
        return hasEmptyModel || internalModelForRelatedResource(store, cache, resourceIdentifier).isEmpty();
      }, false);
      return !hasEmptyRecords;
    } else {
      // treat as single resource
      if (!resource.data) {
        return true;
      } else {
        var internalModel = internalModelForRelatedResource(store, cache, resource.data);
        return !internalModel.isEmpty();
      }
    }
  }
  function internalModelForRelatedResource(store, cache, resource) {
    var identifier = cache.getOrCreateRecordIdentifier(resource);
    return store._internalModelForResource(identifier);
  }
  function assertInDebug(msg, cond) {
    if (cond === void 0) {
      cond = false;
    }
    if ( cond) {
      throw new Error(msg);
    }
  }
  function assertIdentifierHasId(identifier) {
    assertInDebug(`Attempted to schedule a fetch for a record without an id.`, identifier.id === null);
  }

  function notifyChanges(identifier, value, record, store) {
    if (value === 'attributes') {
      record.eachAttribute(key => {
        var currentValue = Ember.cacheFor(record, key);
        var internalModel = store._internalModelForResource(identifier);
        if (currentValue !== internalModel._recordData.getAttr(key)) {
          record.notifyPropertyChange(key);
        }
      });
    } else if (value === 'relationships') {
      record.eachRelationship((key, meta) => {
        var internalModel = store._internalModelForResource(identifier);
        if (meta.kind === 'belongsTo') {
          record.notifyPropertyChange(key);
        } else if (meta.kind === 'hasMany') {
          if (meta.options.async) {
            record.notifyPropertyChange(key);
            internalModel.hasManyRemovalCheck(key);
          }
          if (internalModel._manyArrayCache[key]) {
            internalModel._manyArrayCache[key].retrieveLatest();
          }
        }
      });
    } else if (value === 'errors') {
      var internalModel = store._internalModelForResource(identifier);
      //TODO guard
      var errors = internalModel._recordData.getErrors(identifier);
      record.invalidErrorsChanged(errors);
    } else if (value === 'state') {
      record.notifyPropertyChange('isNew');
      record.notifyPropertyChange('isDeleted');
    } else if (value === 'identity') {
      record.notifyPropertyChange('id');
    }
  }

  var _modelForMixin;
  {
    var _found$1;
    _modelForMixin = function () {
      if (!_found$1) {
        _found$1 = require$1('@ember-data/model/-private')._modelForMixin;
      }
      return _found$1(...arguments);
    };
  }

  /**
   *
   * @param store
   * @param cache modelFactoryCache
   * @param normalizedModelName already normalized modelName
   * @return {*}
   */
  function getModelFactory(store, cache, normalizedModelName) {
    var factory = cache[normalizedModelName];
    if (!factory) {
      factory = _lookupModelFactory(store, normalizedModelName);
      if (!factory && true /* HAS_MODEL_PACKAGE */) {
        //Support looking up mixins as base types for polymorphic relationships
        factory = _modelForMixin(store, normalizedModelName);
      }
      if (!factory) {
        // we don't cache misses in case someone wants to register a missing model
        return null;
      }
      var klass = factory.class;
      if (klass.isModel) {
        var hasOwnModelNameSet = klass.modelName && Object.prototype.hasOwnProperty.call(klass, 'modelName');
        if (!hasOwnModelNameSet) {
          Object.defineProperty(klass, 'modelName', {
            value: normalizedModelName
          });
        }
      }
      cache[normalizedModelName] = factory;
    }
    return factory;
  }
  function _lookupModelFactory(store, normalizedModelName) {
    var owner = Ember.getOwner(store);
    return owner.factoryFor(`model:${normalizedModelName}`);
  }

  /**
    The store service contains all of the data for records loaded from the server.
    It is also responsible for creating instances of `Model` that wrap
    the individual data for a record, so that they can be bound to in your
    Handlebars templates.

    By default, applications will have a single `Store` service that is
    automatically created.

    The store can be customized by extending the service in the following manner:

    ```app/services/store.js
    import Store from '@ember-data/store';

    export default class MyStore extends Store {}
    ```

    You can retrieve models from the store in several ways. To retrieve a record
    for a specific id, use the `Store`'s `findRecord()` method:

    ```javascript
    store.findRecord('person', 123).then(function (person) {
    });
    ```

    By default, the store will talk to your backend using a standard
    REST mechanism. You can customize how the store talks to your
    backend by specifying a custom adapter:

    ```app/adapters/application.js
    import DS from 'ember-data';

    export default Adapter.extend({
    });
    ```

    You can learn more about writing a custom adapter by reading the `Adapter`
    documentation.

    ### Store createRecord() vs. push() vs. pushPayload()

    The store provides multiple ways to create new record objects. They have
    some subtle differences in their use which are detailed below:

    [createRecord](../classes/Store/methods/createRecord?anchor=createRecord) is used for creating new
    records on the client side. This will return a new record in the
    `created.uncommitted` state. In order to persist this record to the
    backend, you will need to call `record.save()`.

    [push](../classes/Store/methods/push?anchor=push) is used to notify Ember Data's store of new or
    updated records that exist in the backend. This will return a record
    in the `loaded.saved` state. The primary use-case for `store#push` is
    to notify Ember Data about record updates (full or partial) that happen
    outside of the normal adapter methods (for example
    [SSE](http://dev.w3.org/html5/eventsource/) or [Web
    Sockets](http://www.w3.org/TR/2009/WD-websockets-20091222/)).

    [pushPayload](../classes/Store/methods/pushPayload?anchor=pushPayload) is a convenience wrapper for
    `store#push` that will deserialize payloads if the
    Serializer implements a `pushPayload` method.

    Note: When creating a new record using any of the above methods
    Ember Data will update `RecordArray`s such as those returned by
    `store#peekAll()` or `store#findAll()`. This means any
    data bindings or computed properties that depend on the RecordArray
    will automatically be synced to include the new or updated record
    values.

    @class Store
    @main @ember-data/store
    @extends Ember.Service
  */

  class Store extends CoreStore {
    constructor() {
      super(...arguments);
      this._modelFactoryCache = Object.create(null);
      this._relationshipsDefCache = Object.create(null);
      this._attributesDefCache = Object.create(null);
    }
    instantiateRecord(identifier, createRecordArgs, recordDataFor, notificationManager) {
      var modelName = identifier.type;
      var internalModel = this._internalModelForResource(identifier);
      var createOptions = {
        store: this,
        _internalModel: internalModel,
        currentState: internalModel.currentState,
        container: null
      };
      Ember.assign(createOptions, createRecordArgs);

      // ensure that `getOwner(this)` works inside a model instance
      Ember.setOwner(createOptions, Ember.getOwner(this));
      delete createOptions.container;
      var record = this._modelFactoryFor(modelName).create(createOptions);
      //todo optimize
      notificationManager.subscribe(identifier, (identifier, value) => notifyChanges(identifier, value, record, this));
      return record;
    }
    teardownRecord(record) {
      record.destroy();
    }

    /**
    Returns the model class for the particular `modelName`.
     The class of a model might be useful if you want to get a list of all the
    relationship names of the model, see
    [`relationshipNames`](/ember-data/release/classes/Model?anchor=relationshipNames)
    for example.
     @method modelFor
    @param {String} modelName
    @return {Model}
      */
    modelFor(modelName) {
      {
        assertDestroyedStoreOnly$1(this, 'modelFor');
      }
      ( !(Ember.isPresent(modelName)) && Ember.assert(`You need to pass a model name to the store's modelFor method`, Ember.isPresent(modelName)));
      ( !(typeof modelName === 'string') && Ember.assert(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${modelName}`, typeof modelName === 'string'));
      var maybeFactory = this._modelFactoryFor(modelName);

      // for factorFor factory/class split
      var klass = maybeFactory && maybeFactory.class ? maybeFactory.class : maybeFactory;
      if (!klass || !klass.isModel) {
        {
          throw new Ember.Error(`No model was found for '${modelName}' and no schema handles the type`);
        }
      } else {
        return klass;
      }
    }
    _modelFactoryFor(modelName) {
      {
        assertDestroyedStoreOnly$1(this, '_modelFactoryFor');
      }
      ( !(Ember.isPresent(modelName)) && Ember.assert(`You need to pass a model name to the store's _modelFactoryFor method`, Ember.isPresent(modelName)));
      ( !(typeof modelName === 'string') && Ember.assert(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${modelName}`, typeof modelName === 'string'));
      var normalizedModelName = normalizeModelName(modelName);
      var factory = getModelFactory(this, this._modelFactoryCache, normalizedModelName);
      return factory;
    }

    /*
    Returns whether a ModelClass exists for a given modelName
    This exists for legacy support for the RESTSerializer,
    which due to how it must guess whether a key is a model
    must query for whether a match exists.
     We should investigate an RFC to make this public or removing
    this requirement.
     @private
    */
    _hasModelFor(modelName) {
      {
        assertDestroyingStore$1(this, '_hasModelFor');
      }
      ( !(Ember.isPresent(modelName)) && Ember.assert(`You need to pass a model name to the store's hasModelFor method`, Ember.isPresent(modelName)));
      ( !(typeof modelName === 'string') && Ember.assert(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${modelName}`, typeof modelName === 'string'));
      {
        ( !(Ember.isPresent(modelName)) && Ember.assert(`You need to pass a model name to the store's hasModelFor method`, Ember.isPresent(modelName)));
        ( !(typeof modelName === 'string') && Ember.assert(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${modelName}`, typeof modelName === 'string'));
        var normalizedModelName = normalizeModelName(modelName);
        var factory = getModelFactory(this, this._modelFactoryCache, normalizedModelName);
        return factory !== null;
      }
    }
    _relationshipMetaFor(modelName, id, key) {
      {
        var modelClass = this.modelFor(modelName);
        var relationshipsByName = Ember.get(modelClass, 'relationshipsByName');
        return relationshipsByName.get(key);
      }
    }
    _attributesDefinitionFor(modelName, identifier) {
      {
        var attributes = this._attributesDefCache[modelName];
        if (attributes === undefined) {
          var modelClass = this.modelFor(modelName);
          var attributeMap = Ember.get(modelClass, 'attributes');
          attributes = Object.create(null);
          attributeMap.forEach((meta, name) => attributes[name] = meta);
          this._attributesDefCache[modelName] = attributes;
        }
        return attributes;
      }
    }
    _relationshipsDefinitionFor(modelName, identifier) {
      {
        var relationships = this._relationshipsDefCache[modelName];
        if (relationships === undefined) {
          var modelClass = this.modelFor(modelName);
          relationships = Ember.get(modelClass, 'relationshipsObject') || null;
          this._relationshipsDefCache[modelName] = relationships;
        }
        return relationships;
      }
    }
    getSchemaDefinitionService() {
      {
        throw 'schema service is only available when custom model class feature flag is on';
      }
    }
  }
  var assertDestroyingStore$1;
  var assertDestroyedStoreOnly$1;
  {
    assertDestroyingStore$1 = function assertDestroyedStore(store, method) {
      if (!store.shouldAssertMethodCallsOnDestroyedStore) {
        ( !(!(store.isDestroying || store.isDestroyed)) && Ember.deprecate(`Attempted to call store.${method}(), but the store instance has already been destroyed.`, !(store.isDestroying || store.isDestroyed), {
          id: 'ember-data:method-calls-on-destroyed-store',
          until: '3.8'
        }));
      } else {
        ( !(!(store.isDestroying || store.isDestroyed)) && Ember.assert(`Attempted to call store.${method}(), but the store instance has already been destroyed.`, !(store.isDestroying || store.isDestroyed)));
      }
    };
    assertDestroyedStoreOnly$1 = function assertDestroyedStoreOnly(store, method) {
      if (!store.shouldAssertMethodCallsOnDestroyedStore) {
        ( !(!store.isDestroyed) && Ember.deprecate(`Attempted to call store.${method}(), but the store instance has already been destroyed.`, !store.isDestroyed, {
          id: 'ember-data:method-calls-on-destroyed-store',
          until: '3.8'
        }));
      } else {
        ( !(!store.isDestroyed) && Ember.assert(`Attempted to call store.${method}(), but the store instance has already been destroyed.`, !store.isDestroyed));
      }
    };
  }

  /**
    @module @ember-data/store
  */

  /**
    @method diffArray
    @private
    @param {Array} oldArray the old array
    @param {Array} newArray the new array
    @return {hash} {
        firstChangeIndex: <integer>,  // null if no change
        addedCount: <integer>,        // 0 if no change
        removedCount: <integer>       // 0 if no change
      }
  */
  function diffArray(oldArray, newArray) {
    var oldLength = oldArray.length;
    var newLength = newArray.length;
    var shortestLength = Math.min(oldLength, newLength);
    var firstChangeIndex = null; // null signifies no changes

    // find the first change
    for (var i = 0; i < shortestLength; i++) {
      // compare each item in the array
      if (oldArray[i] !== newArray[i]) {
        firstChangeIndex = i;
        break;
      }
    }
    if (firstChangeIndex === null && newLength !== oldLength) {
      // no change found in the overlapping block
      // and array lengths differ,
      // so change starts at end of overlap
      firstChangeIndex = shortestLength;
    }
    var addedCount = 0;
    var removedCount = 0;
    if (firstChangeIndex !== null) {
      // we found a change, find the end of the change
      var unchangedEndBlockLength = shortestLength - firstChangeIndex;
      // walk back from the end of both arrays until we find a change
      for (var _i = 1; _i <= shortestLength; _i++) {
        // compare each item in the array
        if (oldArray[oldLength - _i] !== newArray[newLength - _i]) {
          unchangedEndBlockLength = _i - 1;
          break;
        }
      }
      addedCount = newLength - unchangedEndBlockLength - firstChangeIndex;
      removedCount = oldLength - unchangedEndBlockLength - firstChangeIndex;
    }
    return {
      firstChangeIndex,
      addedCount,
      removedCount
    };
  }

  /**
    @module @ember-data/store
  */

  function typeForRelationshipMeta(meta) {
    var modelName;
    modelName = meta.type || meta.key;
    modelName = normalizeModelName(modelName);
    if (meta.kind === 'hasMany') {
      modelName = emberInflector.singularize(modelName);
    }
    return modelName;
  }
  function shouldFindInverse(relationshipMeta) {
    var options = relationshipMeta.options;
    return !(options && options.inverse === null);
  }
  class RelationshipDefinition {
    constructor(meta) {
      this[BRAND_SYMBOL] = void 0;
      this._type = '';
      this.__inverseKey = '';
      this.__inverseIsAsync = true;
      this.__hasCalculatedInverse = false;
      this.parentModelName = void 0;
      this.inverse = void 0;
      this.inverseIsAsync = void 0;
      this.meta = meta;
      this.parentModelName = meta.parentModelName;
    }
    get key() {
      return this.meta.key;
    }
    get kind() {
      return this.meta.kind;
    }
    get type() {
      if (this._type) {
        return this._type;
      }
      this._type = typeForRelationshipMeta(this.meta);
      return this._type;
    }
    get options() {
      return this.meta.options;
    }
    get name() {
      return this.meta.name;
    }
    _inverseKey(store, modelClass) {
      if (this.__hasCalculatedInverse === false) {
        this._calculateInverse(store, modelClass);
      }
      return this.__inverseKey;
    }
    _inverseIsAsync(store, modelClass) {
      if (this.__hasCalculatedInverse === false) {
        this._calculateInverse(store, modelClass);
      }
      return this.__inverseIsAsync;
    }
    _calculateInverse(store, modelClass) {
      this.__hasCalculatedInverse = true;
      var inverseKey, inverseIsAsync;
      var inverse = null;
      if (shouldFindInverse(this.meta)) {
        inverse = modelClass.inverseFor(this.key, store);
      } else {
        modelClass.typeForRelationship(this.key, store);
      }
      if (inverse) {
        inverseKey = inverse.name;
        inverseIsAsync = isInverseAsync(inverse);
      } else {
        inverseKey = null;
        inverseIsAsync = false;
      }
      this.__inverseKey = inverseKey;
      this.__inverseIsAsync = inverseIsAsync;
    }
  }
  function isInverseAsync(meta) {
    var inverseAsync = meta.options && meta.options.async;
    return typeof inverseAsync === 'undefined' ? true : inverseAsync;
  }
  function relationshipFromMeta(meta) {
    return new RelationshipDefinition(meta);
  }

  exports.AdapterPopulatedRecordArray = AdapterPopulatedRecordArray;
  exports.DeprecatedEvented = DeprecatedEvented$1;
  exports.InternalModel = InternalModel;
  exports.PromiseArray = PromiseArray;
  exports.PromiseObject = PromiseObject;
  exports.RecordArray = RecordArray;
  exports.RecordArrayManager = RecordArrayManager;
  exports.RecordDataStoreWrapper = RecordDataStoreWrapper;
  exports.RootState = RootState$1;
  exports.Snapshot = Snapshot;
  exports.SnapshotRecordArray = SnapshotRecordArray;
  exports.Store = Store;
  exports._bind = _bind;
  exports._guard = _guard;
  exports._objectIsAlive = _objectIsAlive;
  exports.addSymbol = addSymbol;
  exports.coerceId = coerceId;
  exports.diffArray = diffArray;
  exports.errorsArrayToHash = errorsArrayToHash;
  exports.errorsHashToArray = errorsHashToArray;
  exports.guardDestroyedStore = guardDestroyedStore;
  exports.identifierCacheFor = identifierCacheFor;
  exports.normalizeModelName = normalizeModelName;
  exports.recordDataFor = recordDataFor;
  exports.recordIdentifierFor = recordIdentifierFor;
  exports.relationshipFromMeta = relationshipFromMeta;
  exports.setIdentifierForgetMethod = setIdentifierForgetMethod;
  exports.setIdentifierGenerationMethod = setIdentifierGenerationMethod;
  exports.setIdentifierResetMethod = setIdentifierResetMethod;
  exports.setIdentifierUpdateMethod = setIdentifierUpdateMethod;
  exports.symbol = symbol;
  exports.typeForRelationshipMeta = typeForRelationshipMeta;
  exports.upgradeForInternal = upgradeForInternal;

  Object.defineProperty(exports, '__esModule', { value: true });

});
