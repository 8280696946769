define('ember-i18n/config/zh', ['exports', 'ember-i18n/config/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    rtl: false,

    pluralForm() /* n */{
      return _constants.OTHER;
    }
  };
});