define("ember-intl/helpers/format-relative", ["exports", "ember-intl/helpers/-format-base"], function (_exports, _formatBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const runBind = Ember.run.bind;
  var _default = _formatBase.default.extend({
    format(params, hash) {
      return this.intl.formatRelative(params, hash);
    },
    compute(params, hash) {
      this.clearTimer();
      if (hash && typeof hash.interval !== 'undefined') {
        /* setTimeout versus Ember.run.later so tests will not wait infinitely */
        this.timer = setTimeout(runBind(this, this.recompute), parseInt(hash.interval, 10));
      }
      return this._super(params, hash);
    },
    clearTimer() {
      clearTimeout(this.timer);
    },
    willDestroy() {
      this._super();
      this.clearTimer();
    }
  });
  _exports.default = _default;
});